/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  credentialData: {
    cic: "",
    electorKey: "",
    ocr: "",
    registerYear: "",
    emissionYear: "",
  },
  curp: "",
  dateBirth: "",
  email: "",
  gender: "",
  lastName1: "",
  lastName2: "",
  name1: "",
  name2: "",
  nationality: "",
  phone: "",
  placeBirth: "",
  federalEntity: {
    key: "",
    value: "",
  },
  principalAddress: {
    zipCode: "",
    state: "",
    stateId: "",
    stateCRM: "",
    suburbs: "",
    street: "",
    externalNumber: "",
    internalNumber: "",
    townshipId: "",
    township: "",
    country: "",
  },
  processId: "",
};

export const confirmData = createSlice({
  name: "confirmData",
  initialState,
  reducers: {
    addConfirmData: (state, action) => {
      state.credentialData = { ...action.payload.credentialData };
      state.curp = action.payload.curp;
      state.dateBirth = action.payload.dateBirth;
      state.email = action.payload.email;
      state.gender = action.payload.gender;
      state.lastName1 = action.payload.lastName1;
      state.lastName2 = action.payload.lastName2;
      state.name1 = action.payload.name1;
      state.name2 = action.payload.name2;
      state.nationality = action.payload.nationality;
      state.phone = action.payload.phone;
      state.placeBirth = action.payload.placeBirth;
      state.federalEntity = action.payload.federalEntity;
      state.principalAddress = { ...action.payload.principalAddress };
      state.processId = action.payload.processId;
    },
    changeConfirmData: (state, action) => {
      state.curp = action.payload.curp;
      state.dateBirth = action.payload.dateBirth;
      state.email = action.payload.email;
      state.gender = action.payload.gender;
      state.lastName1 = action.payload.lastName1;
      state.lastName2 = action.payload.lastName2;
      state.name1 = action.payload.name1;
      state.name2 = action.payload.name2;
      state.nationality = action.payload.nationality;
      state.phone = action.payload.phone;
      state.placeBirth = action.payload.placeBirth;
      state.federalEntity = action.payload.federalEntity;
      state.processId = action.payload.processId;
    },
    credentialData: (state, action) => {
      state.credentialData = { ...action.payload };
    },
    principalAddress: (state, action) => {
      state.principalAddress = { ...action.payload };
    },
    federalEntity: (state, action) => {
      state.federalEntity = { ...action.payload };
    },
    returnDataSteps: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
      (state.name1 = action.payload.name1),
        (state.name2 = action.payload.name2),
        (state.lastName1 = action.payload.lastName1),
        (state.lastName2 = action.payload.lastName2),
        (state.dateBirth = action.payload.dateBirth),
        (state.gender = action.payload.gender),
        (state.nationality = action.payload.nationality),
        (state.phone = action.payload.phone),
        (state.email = action.payload.email),
        (state.placeBirth = action.payload.placeBirth),
        (state.federalEntity = {
          key: action.payload.federalEntity?.key,
          value: action.payload.federalEntity?.value,
        }),
        (state.principalAddress = {
          zipCode: action.payload.zipCode,
          state: action.payload.state,
          stateId: action.payload.stateId,
          stateCRM: action.payload.stateCRM,
          suburbs: action.payload.suburbs,
          street: action.payload.street,
          externalNumber: action.payload.externalNumber,
          internalNumber: action.payload.internalNumber,
          townshipId: action.payload.townshipId,
          township: action.payload.township,
          country: action.payload.country,
        }),
        (state.curp = action.payload.curp),
        (state.credentialData = {
          cic: action.payload.cic,
          electorKey: action.payload.electorKey,
          ocr: action.payload.ocr,
          registerYear: action.payload.registerYear,
          emissionYear: action.payload.emissionYear,
        });
    },
  },
});

export const {
  addConfirmData,
  changeConfirmData,
  credentialData,
  principalAddress,
  returnDataSteps,
  federalEntity,
} = confirmData.actions;
export default confirmData.reducer;
