import { useEffect } from "react";
import { IPersonalData } from "../interfaces/api/IUtilsCommons";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import UtilsCommonsService from "../services/utilsCommons.service";

const { getRfcByPerson } = new UtilsCommonsService();

export function useRFC() {
  const {
    data: {
      clientPersist: { uuid },
      confirmData: { name1, name2, lastName1, lastName2, dateBirth },
    },
  } = useRedux();
  const {
    data: dataRfcByPerson = { homoclave: "", rfc: "" },
    error: errorRfcByPerson,
    execute: execGetRfcByPerson,
    isLoading: isLoadingRfcByPerson,
  } = useApi<{ rfc: string; homoclave: string }, { dataPerson: IPersonalData; processId: string }>(
    getRfcByPerson,
  );

  useEffect(() => {
    execGetRfcByPerson({
      dataPerson: {
        name1: name1,
        name2: name2,
        lastName1: lastName1,
        lastName2: lastName2,
        dateBirth: dateBirth,
      },
      processId: uuid,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataRfcByPerson,
    isLoadingRfcByPerson,
    errorRfcByPerson,
  };
}
