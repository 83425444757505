import { IBeneficiarie } from "../../interfaces/api/IBeneficiaries";

export function map2Obj(key: any): IBeneficiarie {
  const { confirmAddress, date } = key;

  return {
    name: key.name,
    fatherLastName: key.fatherLastName,
    motherLastName: key.motherLastName,
    relationship: key.relationship,
    relationshipKey: key.relationshipKey,
    date,
    dateBirth: `${date.year}-${date.month}-${date.day}`,
    profitPercentage: key.profitPercentage,
    sameAddress: key.sameAddress === "yes" ? true : false,
    confirmAddress: {
      zipCode: confirmAddress.postalCode,
      state: confirmAddress.state,
      stateId: confirmAddress.stateId,
      stateCRM: confirmAddress.stateCRM,
      suburbs: confirmAddress.suburbs,
      street: confirmAddress.street,
      externalNumber: confirmAddress.outsideNumber,
      internalNumber: confirmAddress.internalNumber,
      township: confirmAddress.township,
      townshipId: confirmAddress.townshipId,
    },
  };
}
