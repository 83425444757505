import { useEffect, useState } from "react";
import { Footer } from "../../../components/templates";
import Text from "../../../utils/dictionaries/dictionary-confirm-address";
import Select from "../../../components/molecules/select";
import Title from "../../../components/atoms/titleV2";
import Input from "../../../components/molecules/inputV2";
import { useApi } from "../../../hooks/useApi";
import { useRedux } from "../../../hooks/useRedux";
import UtilsCommonsService from "../../../services/utilsCommons.service";
import { IZipCode } from "../../../interfaces/api/IUtilsCommons";
import { useZipCode } from "../../../hooks/useZipCode";
import { allownumber } from "../../../utils/patterns";

interface IAlertData {
  formik: any;
  setView: () => void;
  isLoadingFiscalInformation: boolean;
}

const { getZipCode } = new UtilsCommonsService();

const ConfirmAddress = ({ formik, setView, isLoadingFiscalInformation }: IAlertData) => {
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();

  const {
    data: dataGetZipCode = [],
    execute: executeGetZipCode,
    isLoading: isLoadingGetZipCode,
    error: isErrorZipCode,
  } = useApi<IZipCode[], { zipCode: number; processId: string }>(getZipCode);
  useEffect(() => {
    if (!isLoadingGetZipCode && !isErrorZipCode) {
      formik.setFieldValue("confirmAddress.state", dataGetZipCode[0]?.state);
      formik.setFieldValue("confirmAddress.stateCRM", dataGetZipCode[0]?.stateCRM);
      formik.setFieldValue("confirmAddress.stateId", dataGetZipCode[0]?.stateId);
      formik.setFieldValue("confirmAddress.township", dataGetZipCode[0]?.township);
      formik.setFieldValue("confirmAddress.townshipId", dataGetZipCode[0]?.townshipId);
    } else {
      formik.setFieldValue(`confirmAddress.state`, "");
      formik.setFieldValue(`confirmAddress.stateCRM`, "");
      formik.setFieldValue(`confirmAddress.stateId`, "");
      formik.setFieldValue(`confirmAddress.township`, "");
      formik.setFieldValue(`confirmAddress.townshipId`, "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorZipCode, isLoadingGetZipCode]);

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (formik.values.confirmAddress?.zipCode) {
      retVal = false;
    } else {
      retVal = true;
    }

    return retVal;
  };

  return (
    <>
      <div className="container_form mb-4_3">
        <div className=" mb-4">
          <Title text={"Ingresa tu dirección"} className={"container_form__subtitle"} />
        </div>
        <>
          <div className="row mt-3">
            <div className="col-12 mb-4">
              <Input
                name="postalCodes"
                value={formik.values.confirmAddress.zipCode}
                text={Text.inputs.inputTextPostalCode}
                onChange={(e: any) => {
                  if (allownumber.test(e.target.value)) {
                    formik.setFieldValue("confirmAddress.zipCode", e.target.value);
                    if (e.target.value.length === 5) {
                      executeGetZipCode({
                        zipCode: e.target.value,
                        processId: uuid,
                      });
                    }
                  }
                }}
                className={"form-control"}
                placeholder={Text.inputs.inputTextPostalCode}
                type="text"
                onBlur={(e: any) => {
                  formik.setFieldValue("confirmAddress.zipCode", e.target.value);
                }}
                error={!isLoadingGetZipCode && isErrorZipCode ? "Código postal no encontrado" : ""}
                maxLength={6}
                id="zipCode"
              />
            </div>
            <>
              <div className="col-12 mb-4">
                <Input
                  text={Text.inputs.inputTextState}
                  value={formik.values.confirmAddress.state}
                  disabled={true}
                  className={"form-control"}
                  placeholder={Text.inputs.inputTextState}
                  type="text"
                  id="state"
                />
              </div>
              <div className="col-12 mb-2">
                <Input
                  text={Text.inputs.inputTextMunicipality}
                  value={formik.values.confirmAddress.township}
                  disabled={true}
                  className={"form-control"}
                  placeholder={Text.inputs.inputTextMunicipality}
                  type="text"
                  id="municipality"
                />
              </div>
              <div className="col-12 mb-4">
                <Select
                  value={formik.values.confirmAddress?.suburbs}
                  placeholder={Text.inputs.inputTextCologne}
                  data={
                    dataGetZipCode.length
                      ? dataGetZipCode[0]?.suburbs.map((key) => ({ label: key, value: key }))
                      : []
                  }
                  onChange={(e: any) => {
                    formik.setFieldValue("confirmAddress.suburbs", e.target.value);
                  }}
                />
              </div>
              <div className="col-12 mb-4">
                <Input
                  text={Text.inputs.inputTextStreet}
                  value={formik.values.confirmAddress?.street}
                  onChange={(e: any) =>
                    formik.setFieldValue("confirmAddress.street", e.target.value.toUpperCase())
                  }
                  className={"form-control"}
                  placeholder={Text.inputs.inputTextStreet}
                  type="text"
                  id="municipality"
                />
              </div>
              <div className="col-6 ">
                <Input
                  text={Text.inputs.inputTextOutsideNumber}
                  value={formik.values.confirmAddress?.outsideNumber}
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      "confirmAddress.outsideNumber",
                      e.target.value.toUpperCase(),
                    )
                  }
                  className={"form-control"}
                  placeholder={Text.inputs.inputTextOutsideNumber}
                  type="text"
                  id="street"
                />
              </div>
              <div className="col-6 ">
                <Input
                  text={Text.inputs.inputTextInternalNumber}
                  value={formik.values.confirmAddress?.internalNumber}
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      "confirmAddress.internalNumber",
                      e.target.value.toUpperCase(),
                    )
                  }
                  className={"form-control"}
                  placeholder={Text.inputs.inputTextInternalNumber}
                  type="text"
                  id="street"
                />
              </div>
            </>
          </div>
        </>
      </div>
      <Footer
        activeSpinner={isLoadingFiscalInformation}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={setView}
        labelButtonPrimary={Text.button.labelButtonPrimary}
      />
    </>
  );
};

export default ConfirmAddress;
