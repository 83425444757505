import { IBeneficiarie } from "../../interfaces/api/IBeneficiaries";
import fetchBxiApiGateway from "./fetchBxiApiGateway";

export const fetchBeneficiaries = (beneficiaries: IBeneficiarie[], processId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/beneficiaries`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      beneficiaries,
      processId,
    },
  });
};
