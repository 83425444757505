import { IConfirmData } from "../../infraestructure/interfaces/api/IPersonalData";
import IPersonalData from "../../domain/services/IPersonalData.service";
import { getFetchPersonByBpId } from "../../infraestructure/services/api/apiPersonalData";

export class DataINEuseCase {
  constructor(private item: IPersonalData) {}
  public async getDataINE(processId: string) {
    try {
      const response = await this.item.getDataINE(processId);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error en el servicio ${error}` };
    }
  }

  public async getBlackLists(processId: string) {
    try {
      const response = await this.item.getBlackLists(processId);
      if (response?.status === 200) {
        return { data: response.data, error: null, status: response?.status };
      } else {
        return {
          data: "",
          error: `Ocurrio un error, status: ${response.error}`,
          status: response.response?.status,
        };
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        return { data: "", error: error.response.data };
      } else {
        return {
          data: "",
          error: `Ocurrio un error en el servicio ${error}`,
          status: error.response?.status,
        };
      }
    }
  }
  
  async postConfirmData(payload: IConfirmData, processId: string): Promise<any> {
    return await this.item.postConfirmData(payload, processId)
    .then((r) => r.data)
    .catch((error) => {
      return error.response;
    });
  }

  public async getFederalEntity(processId: string, curp: string) {
    try {
      const response = await this.item.getFederalEntity(processId, curp);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error en el servicio ${error}` };
    }
  }

  public async getFetchPersonByBpId(bpId: string) {
    try {
      const response = await this.item.getFetchPersonByBpId(bpId);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error en el servicio ${error}` };
    }
  }
}
