import { useEffect, useState } from 'react';
// Service
import InformationTextUtilService from "../../../services/informationTextUtil.service";
// Use-Case
import { GeneralInformationTextUseCase } from "../../../../application/use-cases/generalInformationText.usecase";


const caseService = new InformationTextUtilService();
const useCase = new GeneralInformationTextUseCase(caseService);

const useGetInformation = () => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setResult] = useState<any>();

    const getInformation = async () => {
        try {
            setIsLoading(true);
            const response = await useCase.ineConsent();
            if (response.error !== null) {
                setError(true);
            }
            setResult(response.data);
        } catch (error) {
            setError(true);
        }
        finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        error,
        isLoading,
        data
    };
};

export default useGetInformation;