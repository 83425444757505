import "../../styles/input.scss";
import { Label } from "../atoms/labelV2";
import React from "react";

interface IInput
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  htmlFor?: string;
  error?: string | undefined;
  text?: string;
  displayLabel?: string;
}

export default function Input(props: IInput) {
  const htmlForLabel = props.htmlFor ? props.htmlFor : props.name;
  const displayLabel = props.displayLabel ? props.displayLabel : "";
  return (
    <>
      <form className="form-floating" autoComplete="off">
        <input
          id={props.id}
          className={props.className}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          maxLength={props.maxLength}
          minLength={props.minLength}
          style={{
            background: `url(${props.src}) no-repeat scroll right center content-box`,
            backgroundSize: "24px 24px",
          }}
          pattern={props.pattern}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
            if (
              !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode == 8
              ) &&
              props.type === "number"
            ) {
              e.preventDefault();
            }
          }}
        />
        <Label
          text={props.text ? props.text : ""}
          htmlFor={htmlForLabel}
          className={displayLabel}
        />
        {props.error && <small className="block dangerous-red">{props.error}</small>}
      </form>
    </>
  );
}
