import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  comeFrom: "",
};

export const contactNavigateSlice = createSlice({
  name: "navigate",
  initialState,
  reducers: {
    addContactNavigate: (state, action) => {
      const { comeFrom } = action.payload;
      state.comeFrom = comeFrom;
    },
    changeContactNavigate: (state, action) => {
      const { comeFrom } = action.payload;
      state.comeFrom = comeFrom;
    },
  },
});

export const { addContactNavigate, changeContactNavigate } = contactNavigateSlice.actions;
export default contactNavigateSlice.reducer;
