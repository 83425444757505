import { useEffect } from "react";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import UtilsCommonsService from "../services/utilsCommons.service";

const { getFederalEntity } = new UtilsCommonsService();

export function useFederalEntity({ curp }: { curp: string }) {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const {
    data: dataGetFederalEntity = [],
    error: errorFederalEntity,
    execute: executeGetFederalEntity,
    isLoading: isLoadingFederalEntity,
  } = useApi<any, { processId: string; curp: string }>(getFederalEntity);

  useEffect(() => {
    if (
      curp?.match(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      )
    ) {
      executeGetFederalEntity({ processId: uuid, curp: curp });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curp]);

  return {
    dataGetFederalEntity,
    isLoadingFederalEntity,
    errorFederalEntity,
  };
}
