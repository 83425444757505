/* eslint-disable no-undef */

const dateISO = () => {
  const today = new Date();
  return today.toISOString();
};

const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const selectedFiles: any = event.target.files as FileList;
    const arrayList = [...selectedFiles];
    const result = await uploadDocuments(arrayList);
    return { files: { arrayBase64Files: result, arrayDataFiles: arrayList }, error: null };
  } catch (error) {
    console.error(error);
    return { files: null, error };
  }
};

const uploadDocuments = async (files: any) => {
  const filePromises = files.map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          // Resolve the promise with the response value
          resolve(reader.result);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });

  // Wait for all promises to be resolved
  const fileInfos = await Promise.all(filePromises);

  // Profit
  return fileInfos;
};

const findTypeFile = (type: any) => {
  const arrayTypes = ["pdf", "bmp", "tiff", "gif", "png", "eps", "svg"];
  let foundMatch = false;
  for (const element of arrayTypes) {
    // eslint-disable-next-line eqeqeq
    if (type.search(element) != -1) {
      foundMatch = true;
      break;
    }
  }
  return foundMatch;
};

const rfcRules = (isRequired: boolean) => {
  let validateParameters = {};
  if (isRequired) {
    validateParameters = {
      maxLength: (v: any) => v.length <= 10 || "Máximo 10 caracteres.", //Este se muestra si rebasa los N digitos
      matchPattern: (v: any) => /^[a-zA-Z]{4}[0-9]{6}/g.test(v) || "RFC inválido",
    };
  }
  return {
    required: isRequired,
    validate: validateParameters,
  };
};

const homoclaveRules = (isRequired: boolean) => {
  let validateParameters = {};
  if (isRequired) {
    validateParameters = {
      maxLength: (v: any) => v.length <= 3 || "Máximo 3 caracteres.", //Este se muestra si rebasa los N digitos
      matchPattern: (v: any) => /^[A-Z0-9]{3}/g.test(v) || "Homoclave invalidó",
    };
  }
  return {
    required: isRequired,
    validate: validateParameters,
  };
};

export { dateISO, selectFile, findTypeFile, rfcRules, homoclaveRules };
