import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isClient: false,
  phone: "",
  email: "",
  emailVerify: false,
  creationDate: "",
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addData: (state, action) => {
      const { isClient, phone, email, emailVerify, creationDate } = action.payload;
      state.isClient = isClient;
      state.phone = phone;
      state.email = email;
      state.emailVerify = emailVerify;
      state.creationDate = creationDate;
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
    changePhone: (state, action) => {
      state.phone = action.payload;
    },
    changeVerifyEmail: (state, action) => {
      state.emailVerify = action.payload;
    },
    changeIsClient: (state, action) => {
      state.isClient = action.payload.isClient;
    },
  },
});

export const { addData, changeEmail, changePhone, changeVerifyEmail, changeIsClient } =
  dataSlice.actions;
export default dataSlice.reducer;
