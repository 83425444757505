/* eslint-disable no-lone-blocks */
import Title from "../atoms/title";
import { IAccordion, IAccordionItem, IAccordionItems } from "../../interfaces/accordion";
import InformationSection from "../molecules/information-section";
import "../../styles/accordion.scss";
import LinksSection from "../molecules/links-section";

const Accordion = ({ accordionItems, listType, onClick }: IAccordion) => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionDefault">
        {accordionItems?.map((itemAccordion: IAccordionItems ) => {
          return (
            <div className="accordion-item" key={itemAccordion.title}>
              <h2 className="accordion-header" id={`heading${itemAccordion.title}`}>
                <button
                  onClick={() => onClick && onClick(itemAccordion)}
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${itemAccordion.title}`}
                  aria-expanded="false"
                  aria-controls={`collapse${itemAccordion.title}`}
                  style={{ position: "static" }}
                >
                  <Title
                    text={itemAccordion.title}
                    className={"fw-bold"}
                    styleTitle={{ color: "#00376F" }}
                  />
                </button>
              </h2>
              <div
                id={`collapse${itemAccordion.title}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${itemAccordion.title}`}
                data-bs-parent="#accordionDefault"
              >
                <div className="mb-3_2">
                  {itemAccordion.items?.map((item: IAccordionItem) => {
                    {
                      return listType == "links" ? (
                        <LinksSection
                          key={item.title}
                          url={item.url}
                          isDownload={true}
                          target={"_blank"}
                          className={""}
                          styleLink={{
                            fontSize: "16px",
                            color: "#004A94",
                            textDecorationLine: "underline",
                          }}
                          state={undefined}
                          text={item.title}
                          divStyleLinks={{}}
                          divClassLinks={"mb-3"}
                        />
                      ) : (
                        <InformationSection
                          key={item.title}
                          titleText={item.title}
                          paragraphText={item.description}
                          classNameImg={"pt-2"}
                          srcImage={item.url}
                          columnA={"col-1 p-0"}
                          columnB={"col-11"}
                          titleClass={"m-0 ms-1 fs-6 lh-20 fw-medium"}
                          paragraphClass={"ms-1 fs-7 lh-20 fw-normal"}
                          styleParagraph={{}}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Accordion;
