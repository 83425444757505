import IParagraphs from "../../interfaces/paragraphs";
import * as React from "react";

const Paragraph = ({ text, className, styleParagraph }: IParagraphs) => {
  return (
    <>
      <p className={className} style={styleParagraph}>
        {text}
      </p>
    </>
  );
};

export default Paragraph;
