const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Consentimiento del INE",
  },
  text_3: {
    id: "t3",
    mainText: "",
  },
  text_4: {
    id: "t4",
    mainText: "Acepto y solicito código de confirmación.",
  },
  text_5: {
    id: "t5",
    mainText: "Solicitar código",
  },
};

export default listText;
