// Permite letras, ñ, tildes y espacios
export const allowAlphanumeric = RegExp("^[a-zA-Z0-9À-ÿ\u00f1\u00d1\\s]+$");

// Permite letras, ñ, tildes y espacios y puntos
// eslint-disable-next-line no-useless-escape
export const allowAlphanumericAndDots = RegExp("^[a-zA-Z0-9À-ÿ\u00f1\u00d1\\s.]+$");

// Permite letras, ñ, tildes, espacios, puntos, ampersands, guiones altos, guiones bajos.
// eslint-disable-next-line no-useless-escape
export const allowedTextForBusinessName = RegExp("^[a-zA-Z0-9À-ÿ\u00f1\u00d1\\s.\\-_&+]+$");

// Permite letras del alfabeto incluyendo ñ
export const allowAplphabetic = RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d1\\s]{0,250}$");

// Permite digitos
export const allownumber = RegExp("^[0-9]*$");

// Permite digitos y letra k
export const allownumberAndK = RegExp("([0-9]|K)");
// Permite email
export const allowEmail = RegExp("^[a-zA-Z0-9-@_.\\s]+$");
// Permite digitos y letra k V.2.0
export const allownumberAndKV20 = RegExp("^[0-9kK.-]+$");

interface INumberOptions {
  [key: number]: boolean;
}

export const stateConfigSeller: INumberOptions = {
  0: false,
  1: true,
};

export const headers = {
  "Content-Type": "application/json",
};

export const formartNumberPoint = (number: number) => {
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
