const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Confirmación de datos personales",
  },
  text_3: {
    id: "t3",
    mainText: "Para continuar el proceso, es necesario que confirmes tu información:",
  },
  text_4: {
    id: "t4",
    mainText: "Datos personales",
  },
  text_5: {
    id: "t5",
    mainText: "Primer nombre",
  },
  text_6: {
    id: "t6",
    mainText: "Segundo nombre",
  },
  text_7: {
    id: "t7",
    mainText: "Apellidos",
  },
  text_9: {
    id: "t9",
    mainText: "Fecha nacimiento",
  },
  text_10: {
    id: "t10",
    mainText: "Género",
  },
  text_11: {
    id: "t11",
    mainText: "Nacionalidad",
  },
  text_12: {
    id: "t12",
    mainText: "CURP",
  },
  text_13: {
    id: "t13",
    mainText: "País de nacimiento",
  },
  text_14: {
    id: "t14",
    mainText: "Datos de contacto",
  },
  text_15: {
    id: "t15",
    mainText: "Teléfono:",
  },
  text_16: {
    id: "t16",
    mainText: "Correo electrónico:",
  },
  text_17: {
    id: "t17",
    mainText: "Datos del domicilio",
  },
  text_18: {
    id: "t18",
    mainText: "País de residencia",
  },
  text_19: {
    id: "t19",
    mainText: "Código postal",
  },
  text_20: {
    id: "t20",
    mainText: "Estado",
  },
  text_21: {
    id: "t21",
    mainText: "Colonia",
  },
  text_22: {
    id: "t22",
    mainText: "Calle",
  },
  text_23: {
    id: "t23",
    mainText: "Número",
  },
  text_24: {
    id: "t24",
    mainText: "Datos del INE",
  },
  text_25: {
    id: "t25",
    mainText: "Clave de elector",
  },
  text_26: {
    id: "t26",
    mainText: "Año de registro",
  },
  text_27: {
    id: "t27",
    mainText: "Año de emisión",
  },
  text_28: {
    id: "t28",
    mainText: "Código OCR",
  },
  text_29: {
    id: "t29",
    mainText: "Código CIC",
  },
  text_30: {
    id: "t30",
    mainText: "¿Dónde encuentro estos datos?",
  },
  text_31: {
    id: "t31",
    mainText: "Continuar",
  },
  text_32: {
    id: "t32",
    mainText: "Código OCR / CIC",
  },
  text_33: {
    id: "t33",
    mainText: "Ubica el código OCR y CIC en tu INE",
  },
  text_34: {
    id: "t34",
    mainText: "¡Ups lo sentimos!",
  },
  text_35: {
    id: "t35",
    mainText: "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_36: {
    id: "t36",
    mainText: "Vuelve a intentar si el error persiste.",
  },
  text_37: {
    id: "t37",
    mainText: "Reintentar",
  },
  text_38: {
    id: "t38",
    mainText: "¡Lo sentimos!",
  },
  text_39: {
    id: "t39",
    mainText: "Hemos detectado que estás realizando tu apertura de cuenta digital pero no eres tu.",
  },
  text_40: {
    id: "t40",
    mainText: "Por favor confirma los datos ingresados y vuelve a intentarlo.",
  },
  text_41: {
    id: "t41",
    mainText: "Revisar datos",
  }
};

export default listText;
