import fetchBxiApiGateway from "./fetchBxiApiGateway";

const sendContactInfo = (request: any) => {
  return fetchBxiApiGateway({
    url: "personal-data/person/contact-information",
    method: "POST",
    data: {
      ...request,
    },
  });
};

export { sendContactInfo };
