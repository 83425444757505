import { createSlice } from "@reduxjs/toolkit";

interface IDataState {
  uuid: string;
  token: string;
  token_encoded: string;
  public_url?: string;
  resource: string;
}

const initialState: IDataState = {
  uuid: "",
  token: "",
  token_encoded: "",
  public_url: "",
  resource: "",
};

export const videoRecordingSlice = createSlice({
  name: "videoRecordingData",
  initialState,
  reducers: {
    videoRecordingData: (state, action) => {
      const { uuid, token, token_encoded, public_url, resource } = action.payload;
      state.uuid = uuid;
      state.token = token;
      state.token_encoded = token_encoded;
      state.public_url = public_url;
      state.resource = resource;
    },
  },
});

export const { videoRecordingData } = videoRecordingSlice.actions;
export default videoRecordingSlice.reducer;
