/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import UtilsContractsService from "../services/utilsContracts.service";

const { getMaxMinByDeposit } = new UtilsContractsService();

export function useMaxMinByDeposit() {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();

  const {
    data: dataMaxMinByDeposit = {
      amountMin: 0,
      amountMax: 232000,
    },
    error: errorMaxMinByDeposit,
    execute: execGetMaxMinByDeposit,
    isLoading: isLoadingMaxMinByDeposit,
  } = useApi<
    {
      amountMin: number;
      amountMax: number;
    },
    { processId: string }
  >(getMaxMinByDeposit);

  useEffect(() => {
    execGetMaxMinByDeposit({ processId: uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataMaxMinByDeposit,
    isLoadingMaxMinByDeposit,
    errorMaxMinByDeposit,
  };
}
