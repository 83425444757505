import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
// Atoms
import Loader from "../../components/atoms/loader";
import Button from "../../components/atoms/button";
// Moleculas
import Checkbox from "../../components/molecules/checkbox";
// Organisms
import FormatTextPlain from "../../components/organisms/format-text-plain";
// Template
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Service
import OtpUtilService from "../../services/otpUtil.service";
// Use-Case
import { ValidateCodeUseCase } from "../../../application/use-cases/validateCode.usecase";
// Redux
import { store } from "../../redux/store";
import { changeNavigate, changeNextContinue } from "../../redux/slices/navigateReducer";
// Hooks
import useGetInformation from "./hooks/useGetInformation";
import { useFollowLater } from "../../hooks/useFollowLater";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-ine-consent";
// Utilss
import { dateISO } from "../../utils/helpers";
import { AmplitudEventName } from "../../utils/amplitude/types";
import { trackAnalyticsEvent } from "../../utils/amplitude";
// Assets
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";

const otpService = new OtpUtilService();
const usecaseOtp = new ValidateCodeUseCase(otpService);

const IneConsent = () => {
  const access_geoposition = localStorage.getItem("access_geoposition")
  ? JSON.parse(localStorage.getItem("access_geoposition") || "")
  : undefined;
  const { onClickContinue } = useFollowLater();
  const state = store.getState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  const { data : textIneConsent, error, isLoading: isLoadingGetInformation}  = useGetInformation();

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const onHandleNext = async (e: any) => {

    e.preventDefault();
    setShowSpinner(true);
    const responseOtp = await usecaseOtp.sendOtp(
      {
        phone: state.client.dataPersist.phone,
        email: state.client.dataPersist.email,
        channel: "SMS",
        date: dateISO(),
      },
      state.client.clientPersist.uuid,
    );
    if (responseOtp.error !== null) {
      setModalShow(true);
    } else {
      dispatch(
        changeNextContinue({
          nextContinue: "/CaptureINE",
          comeFrom: "IneConsent",
        }),
      );
      dispatch(
        changeNavigate({
          progressBarPercentage: "25%",
          editableValue: state.client.dataPersist.phone,
          typeValue: "phone",
          textButton: "Continuar",
          nextContinue: "/CaptureINE",
          comeFrom: "IneConsent",
          editableReceiverOtp: state.client.clientPersist.bpId !== null ? false: true,
        }),
      );
      onClickContinue({
        data: { dataPersist: state.client.dataPersist },
        processId: state.client.clientPersist.uuid,
        resource: "IneConsent",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Aceptar_consentimiento_INE_N4,
        { "Checbox_Aceptación": checked ? "SI" : "NO",
          "Fecha": moment(new Date()).format('ll'),
          "Hora": moment(new Date()).format('LTS'),
          "Geolocalización": access_geoposition,
        }
      )
      navigate("/ValidateCode");
    }
    setShowSpinner(false);
  };

  const onHandleRetry = () => {
    setModalShow(false);
    window.location.reload();
  };

  if(isLoadingGetInformation && !textIneConsent) {
    return <Loader />
  }
  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={"Apertura de cuenta"}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={""}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"20%"}
          comeFrom={"/IneConsent"}
          onClickGoBackIcon={undefined}
        />
        <div className="p-3">
          <FormatTextPlain text={textIneConsent} type={"onlyDot"} />
          <Checkbox
            text={listText.text_4.mainText}
            htmlFor={"flexCheckDefault"}
            classNameLabel={"fs-6"}
            classCheckbox={"form-check mt-4 mb-4"}
            checked={checked}
            onChange={() =>  setChecked(!checked)}
            disabled={!textIneConsent && !isLoadingGetInformation}
          />
        </div>
      </div>
      <div className="footer--pin">
        <Button
          text={listText.text_5.mainText}
          buttonClassName={`${
            showSpinner ? "pe-none" : ""
          } btn-bg-primary text-white w-100 place-content-center align-self-center 
          ${!checked ? "button-disabled " : "button-enabled" }`}
          spinner={showSpinner}
          onClick={(e:any)=> onHandleNext(e)}
          disabled={!checked ? 'disabled' : null}
        />
      </div>
      <MessagesReactModal
        show={modalShow}
        onHide={() => {setModalShow(false)}}
        textTitle="¡Ups lo sentimos!"
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {onHandleRetry()},
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default IneConsent;
