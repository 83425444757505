import { useEffect } from "react";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import { ICatalog } from "../interfaces/api/IUtilsCommons";
import UtilsCommonsService from "../services/utilsCommons.service";

const { getRegimenFiscal } = new UtilsCommonsService();

export function useRegimenFiscal() {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();

  const {
    data: dataRegimenFiscal = [],
    error: errorRegimenFiscal,
    execute: execGetRegimenFiscal,
    isLoading: isLoadingRegimenFiscal,
  } = useApi<ICatalog[], { processId: string }>(getRegimenFiscal);

  useEffect(() => {
    execGetRegimenFiscal({ processId: uuid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataRegimenFiscal,
    isLoadingRegimenFiscal,
    errorRegimenFiscal,
  };
}
