import { useEffect } from "react";
import { ICatalog, IPersonalData } from "../interfaces/api/IUtilsCommons";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import UtilsCommonsService from "../services/utilsCommons.service";

const { getParentesco } = new UtilsCommonsService();

export function useParentesco() {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const {
    data: dataParentesco = [],
    error: errorParentesco,
    execute: execGetParentesco,
    isLoading: isLoadingParentesco,
  } = useApi<ICatalog[], { processId: string }>(getParentesco);

  useEffect(() => {
    execGetParentesco({
      processId: uuid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataParentesco,
    isLoadingParentesco,
    errorParentesco,
  };
}
