import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DynamicHeader from "../../components/molecules/dynamic-header";

import FormatTextPlain from "../../components/organisms/format-text-plain";
import InformationTextUtilService from "../../services/informationTextUtil.service";
import { GeneralInformationTextUseCase } from "../../../application/use-cases/generalInformationText.usecase";
import Loader from "../../components/atoms/loader";
import MessagesView from "../../components/templates/messages-view";

const TermsCondition = () => {
  const [isReadyData, setIsReadyData] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [textData, setTextData] = useState();
  const location = useLocation();
  const { typeText, typeFormat, titleText } = location.state;
  const caseService = new InformationTextUtilService();
  const useCase = new GeneralInformationTextUseCase(caseService);

  useEffect(() => {
    const getText = async () => {
      setIsReadyData(false);
      let response: { data: any; error: any } = { data: null, error: false };
      if (typeText === "avisoprivacidad") {
        response = await useCase.getPrivacyNotices();
      } else if (typeText === "terminoscondiciones") {
        response = await useCase.getTermsAndConditions();
      }
      if (response.error != null) {
        setSomethingWentWrong(true);
      }
      setTextData(response?.data);
      setIsReadyData(true);
    };
    getText();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      {isReadyData ? (
        somethingWentWrong ? (
          <MessagesView
            message={"Algo salio mal!"}
            status={false}
            continue={"/InformationRequirements"}
            textBotton={"Reintentar"}
          />
        ) : (
          <div style={{ backgroundColor: "#D9D9D9" }}>
            <DynamicHeader titleText={titleText} />
            <div className="p-2">
              <div className="card">
                <div className="card-body p-3">
                  <FormatTextPlain text={textData} type={typeFormat} />
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TermsCondition;
