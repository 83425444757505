let styles = {
  paragraph1: {
    color: 'var(--dark-blue-title)',
    fontWeight: 400,
    fontSize: 20,
  },
  paragraph2: {
    color: 'var(--dark-blue-title)',
    fontWeight: 400,
    fontSize: 16,
  },
  title: {
    fontWeight: 700,
    color: 'var(--dark-blue-title)',
    fontSize: 28,
  },
  modalTitle: {
    color: 'var(--dark-blue-title)',
    fontSize: 20,
    fontWeight: 700,
  },
  modalParagraph:{
    fontSize: 16, 
    color: 'var(--dark-blue-title)', 
    fontWeight: 450
  }
}
export default styles;