const listText = {
  text_1: {
    id: "t1",
    mainText: "¡Gracias por elegirnos!",
  },
  text_2: {
    id: "t2",
    mainText: "Antes de comenzar, es necesario que conozcas la siguiente información:",
  },
  text_3: {
    id: "t3",
    mainText: "Beneficios",
  },
  text_4: {
    id: "t4",
    mainText: "Proceso de apertura totalmente digital",
  },
  text_5: {
    id: "t5",
    mainText: "Podrás completar el proceso sin necesidad de acudir a sucursal.",
  },
  text_6: {
    id: "t6",
    mainText: "Nivel de cuenta N4",
  },
  text_7: {
    id: "t7",
    mainText: `Contarás con un límite de depósitos de 30,000 UDIS ($231,000)`,
  },
  text_8: {
    id: "t8",
    mainText: "Rápido, fácil y seguro",
  },
  text_9: {
    id: "t9",
    mainText: "Realiza transferencias, ahorro e inversiones desde tu aplicación Consubanco",
  },
  text_10: {
    id: "t10",
    mainText: "Requisitos",
  },
  text_11: {
    id: "t11",
    mainText: "Identificación oficial vigente: INE",
  },
  text_12: {
    id: "t12",
    mainText: "Este proceso únicamente se podrá realizar con tu INE vigente.",
  },
  text_13: {
    id: "t13",
    mainText: "Comprobante de domicilio",
  },
  text_14: {
    id: "t14",
    mainText:
      "En caso de que el domicilio en tu INE no sea el actual o no aparezca. Vigencia no mayor a 3 meses.",
  },
  text_15: {
    id: "t15",
    mainText: "Validación biométrica y videograbación",
  },
  text_16: {
    id: "t16",
    mainText:
      "Un proceso sencillo que nos sirve para validar tu identidad y garantizar que eres tú quien solicita la apertura.",
  },
  text_17: {
    id: "t16",
    mainText: "Términos y condiciones",
  },
  text_18: {
    id: "t18",
    mainText: "Aviso de privacidad",
  },
  text_19: {
    id: "t19",
    mainText: "Términos y Condiciones",
  },
  text_20: {
    id: "t20",
    mainText: "Contrato del producto",
  },
  text_21: {
    id: "t21",
    mainText: "Caratula inversiones",
  },
  text_22: {
    id: "t22",
    mainText: "He leído los documentos, los cuales Acepto y Autorizo.",
  },
  text_23: {
    id: "t23",
    mainText: "Continuar",
  },
  text_24: {
    id: "t24",
    mainText: "¡Ups lo sentimos!",
  },
  text_25: {
    id: "t25",
    mainText: "Reintentar",
  },
  text_26: {
    id: "t26",
    mainText:
      "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_27: {
    id: "t27",
    mainText: "Vuelve a intentar si el error persiste.",
  },
};

export default listText;
