import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// Components
import { RadioToggleButton } from "../../components/molecules/radio-toggle-buttons";
import { Footer } from "../../components/templates";
import Loader from "../../components/atoms/loader";
import Checkbox from "../../components/molecules/checkbox";
import Input from "../../components/molecules/inputV2";
import Paragraph from "../../components/atoms/paragraph";
import Top from "../../components/templates/topV2";

// Utils
import vulnerabilityQuestionnaireDictionary from "../../utils/dictionaries/dictionary-vulnerability-questionnaire";

// Schema
import validationSchema from "./schema";

// Redux
import { onVulnerabilityQuestionnaire } from "../../redux/slices/clientInformation";
import { store } from "../../redux/store";

// Hooks
import { useDinamicSurvey } from "./hooks/useDinamicSurvey";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useRedux } from "../../hooks/useRedux";

// Services
import UtilsCommonsService from "../../services/utilsCommons.service";
import { postVulnerabilityInformation } from "../../services/api/apiPersonalData";

// Styles
import styles from "./styles";

// Interfaces
import { IVulnerabilityQuestionnaireResp } from "../../interfaces/api/IUtilsCommons";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import listText from "../../utils/dictionaries/dictionary-information-requirements";

// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";

const VulnerabilityQuestionnaire = () => {
  const navigate = useNavigate();
  const state = store.getState();
  const utilsService = new UtilsCommonsService();
  const [vulnerabilityQuestionnaireData, setVulnerabilityQuestionnaireData] = useState<IVulnerabilityQuestionnaireResp[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ [key: string]: boolean }>({});
  const [selectedValue, setSelectedValue] = useState<string>("No");
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const { onClickContinue } = useFollowLater();
  const { dataDinamicSurvey = [], errorDinamicSurvey, isLoadingDinamicSurvey } = useDinamicSurvey();
  const { data: { clientInformation: { vulnerabilityQuestionnaire }, clientPersist: { uuid } }, dispatch } = useRedux();
  const [modalShow, setModalShow] = useState(false);

  const ALLOWED_CHARACTERS_REGEX = /[^A-Z-ÁÉÍÓÚÑ\s]+/g;

  const sanitizeInput = (value: string) => {
    return value.toUpperCase().replace(ALLOWED_CHARACTERS_REGEX, '');
  };

  const formik = useFormik({
    initialValues: {
      surve: vulnerabilityQuestionnaire.surve?.length ? vulnerabilityQuestionnaire.surve : dataDinamicSurvey
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true
  });

  const setFormikField = (field: string, value: any) => formik.setFieldValue(field, value);

  const getVulnerabilityQuestionnaireData = async () => {
    setLoadingRedirect(true);
    try {
      const response = await utilsService.getVulnerabilityQuestionnaire({ processId: uuid });
      if (Array.isArray(response) && response.length > 1) {
        setSelectedValue(response[0]?.answers[1]?.description || "No");
        setVulnerabilityQuestionnaireData(response);
      }
    } catch (error) {
      console.error("Error fetching Vulnerability Questionnaire:", error);
      setModalShow(true)
    } finally {
      setLoadingRedirect(false);
    }
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = { ...prevSelected, [value]: !prevSelected[value] };

      formik.setFieldValue(
        "surve[1].answers",
        formik.values?.surve[1]?.answers?.map((answer) => {
          if (answer?.description === value) {
            return { ...answer, selection: updatedSelected[value] };
          }
          return answer;
        })
      );

      if (!updatedSelected[vulnerabilityQuestionnaireData[1]?.answers[3]?.description]) {
        formik.setFieldValue("surve[1].answers[4].freeText", "");
        formik.setFieldValue("surve[1].answers[4].selection", false);
      } else {
        formik.setFieldValue("surve[1].answers[4].selection", true);
      }

      return updatedSelected;
    });
  };

  const btnDisabled = () => {
    const isAllUnselected = selectedValue === "Si" && Object.values(selectedCheckboxes).every(value => !value);
    const isDescribeGroupEmpty = selectedValue === "Si" && selectedCheckboxes[vulnerabilityQuestionnaireData[1]?.answers[3]?.description] &&
      !formik.values?.surve[1]?.answers.find(answer => answer?.description === vulnerabilityQuestionnaireData[1]?.answers[4]?.description)?.freeText?.trim();
    const isSurveyEmpty = formik.values?.surve?.length === 0;

    return isAllUnselected || isDescribeGroupEmpty || isSurveyEmpty || modalShow;
  };


  const submit = async () => {
    setLoadingRedirect(true);
    try {
      const response = await postVulnerabilityInformation(uuid, formik.values);
      if (response) {
        dispatch(onVulnerabilityQuestionnaire({ ...formik.values }));
        onClickContinue({
          data: { vulnerabilityQuestionnaire: formik.values },
          processId: uuid,
          resource: "VulnerabilityQuestionnaire",
          step: "CONTINUE",
        });
        navigate("/BeneficiariesClient");
      }
    } catch (error) {
      console.error("Error submitting Vulnerability Questionnaire:", error);
      setModalShow(true)
    } finally {
      setLoadingRedirect(false);
    }
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "VulnerabilityQuestionnaire",
        processId: uuid,
        step: "CONTINUE_AFTER",
        data: {
          confirmData: { ...state.client.confirmData },
          dataPersist: { ...state.client.dataPersist },
          clientInformation: { ...state.client.clientInformation }
        },
      },
    });
  };

  const handleInputChange = (e: { target: { value: string; }; }) => {
    const newValue = e.target.value;
    const sanitizedValue = sanitizeInput(newValue);

    setFormikField(
      "surve[1].answers",
      formik.values.surve[1].answers.map(answer =>
        answer.description === vulnerabilityQuestionnaireData[1].answers[4].description
          ? { ...answer, freeText: sanitizedValue }
          : answer
      )
    );
  }

  const handleBlur = () => {
    const sanitizedValue = sanitizeInput(formik.values.surve[1].answers[4].freeText || '');

    setFormikField(
      "surve[1].answers",
      formik.values.surve[1].answers.map(answer =>
        answer.description === vulnerabilityQuestionnaireData[1].answers[4].description
          ? { ...answer, freeText: sanitizedValue }
          : answer
      )
    );
  };

  useEffect(() => {
    getVulnerabilityQuestionnaireData();
    setSelectedCheckboxes({});
    formik.setFieldValue("surve[1].answers[4].freeText", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.surve.length) {
      if (!formik.values.surve[0]?.answers[0]?.selection) {
        setSelectedCheckboxes({});
        formik.setFieldValue("surve[0].answers[1].selection", true);
      } else {
        formik.setFieldValue("surve[0].answers[1].selection", false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.surve]);

  const functionProcessModal = () => {
    setModalShow(false);
    window.location.reload();
  };


  if (isLoadingRedirect || isLoadingDinamicSurvey) return <Loader />;

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={vulnerabilityQuestionnaireDictionary.headerTitle}
          subTitle={vulnerabilityQuestionnaireDictionary.navigationTitle}
          displayLeftImageTitle="d-block"
          displayProgressBar="d-block"
          classHeader="mt-2"
          classProgressBar="mt-3"
          valueProgressBar="65%"
          onClickGoBackIcon={() => navigate("/UseAccountManagement")}
        />

        <div className="p-3">
          <Paragraph
            text={vulnerabilityQuestionnaireDictionary.title}
            styleParagraph={styles.title}
          />
          <Paragraph
            text={vulnerabilityQuestionnaireDictionary.subtitle}
            className="fs-6"
          />

          {!modalShow ?
            <>
              <div className="d-flex pb-3">
                <div className="toggle-button-group d-flex">
                  <RadioToggleButton
                    id="vulnerability"
                    text={formik.values.surve[0]?.answers[0]?.description}
                    value="Si"
                    type="radio"
                    name="vulnerability"
                    onChange={() => setSelectedValue("Si")}
                    onClick={() => {
                      formik.setFieldValue("surve[0].answers[0].selection", true);
                      formik.setFieldValue("surve[0].answers[1].selection", false);
                      setSelectedValue("Si")
                    }}
                    isSelected={selectedValue === "Si"}
                    className="radioToggleButton me-3"
                    displayImg="d-none"
                    labelStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
                  />
                  <RadioToggleButton
                    id="vulnerability"
                    text={formik.values.surve[0]?.answers[1]?.description}
                    value="No"
                    type="radio"
                    name="vulnerability"
                    isSelected={selectedValue === "No"}
                    className="radioToggleButton"
                    displayImg="d-none"
                    labelStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
                    onChange={() => { setSelectedValue("No"); setSelectedCheckboxes({}); }}
                    onClick={() => {
                      formik.setFieldValue("surve[0].answers[0].selection", false);
                      formik.setFieldValue("surve[0].answers[1].selection", true);
                      setSelectedValue("No");
                      setSelectedCheckboxes({});
                      formik.setFieldValue("surve", vulnerabilityQuestionnaire.surve?.length ? vulnerabilityQuestionnaire.surve : dataDinamicSurvey);
                    }}
                  />
                </div>
              </div>

              {selectedValue === "Si" && (
                <div className="py-3">
                  <Paragraph text={vulnerabilityQuestionnaireDictionary.group.title} styleParagraph={styles.groupTitle} />
                  {vulnerabilityQuestionnaireData[1]?.answers?.map((item, index: number) => (
                    index !== 4 && (
                      <Checkbox
                        key={index}
                        text={item.description}
                        htmlFor={`flexCheckDefault-${index}`}
                        classNameLabel={"fs-7 ms-2"}
                        classCheckbox={"mb-4 d-flex"}
                        checked={!!selectedCheckboxes[item.description]}
                        onChange={() => handleCheckboxChange(item.description)}
                      />
                    )
                  ))}

                  {selectedCheckboxes && selectedCheckboxes[vulnerabilityQuestionnaireData[1]?.answers[3]?.description] && (
                    <Input
                      className="form-control"
                      type="text"
                      id="other"
                      name="other"
                      placeholder={vulnerabilityQuestionnaireDictionary.inputOther.placeholder}
                      text={vulnerabilityQuestionnaireDictionary.inputOther.name}
                      value={formik.values.surve[1]?.answers[4]?.freeText || ''} // Aseguramos que Formik controle el valor
                      onChange={handleInputChange} // Llamar a handleInputChange cuando el usuario escriba
                      maxLength={50}
                      onBlur={handleBlur} // Sanitizar cuando el campo pierda el foco
                      style={{ borderColor: "var(--dark-gray)" }}
                      displayLabel={"dark-gray"}
                    />
                  )}
                </div>
              )}
            </>
            : null
          }
        </div>
      </div>

      <Footer
        activeSpinner={false}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => submit()}
        onClickButtonLink={() => followLaterAction()}
        labelButtonPrimary={vulnerabilityQuestionnaireDictionary.btn.submit}
        labelButtonSecundary={"Seguir más tarde"}
      />

      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_24.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_25.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_26.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_27.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default VulnerabilityQuestionnaire;