import ILabel from "../../interfaces/label";


export const Label = (props: ILabel) => {
  return (
    <>
      <label className={props.className}>{props.text}</label>
    </>
  );
};
