import fetchBxiApiGateway from "./fetchBxiApiGateway";

const digitalContract = () => {
  return fetchBxiApiGateway({
    url: "contracts/digital-contract",
    method: "GET",
  });
};

const caratulaInversiones = () => {
  return fetchBxiApiGateway({
    url: "contracts/caratula-inversiones",
    method: "GET",
  });
};

const identificationQuestionnaire = (processId: string) => {
  return fetchBxiApiGateway({
    url: "contracts/getCUB",
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export { digitalContract, caratulaInversiones, identificationQuestionnaire };
