import { useEffect } from "react";
import Header from "../molecules/header";
import ProgressBar from "../molecules/progress-bar";
import ITop from "../../interfaces/top";
import arrow_back from "../../assets/icons/arrow_back.svg";
import TitleAndImage from "../molecules/title-and-image";
import { changeContactNavigate } from "../../redux/slices/contactNavigateReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Top = ({
  titleHeader,
  subTitle,
  displayProgressBar,
  displayLeftImageTitle,
  classTitleImage,
  classHeader,
  styleHeader,
  classProgressBar,
  styleProgressBar,
  classTitleAndImage,
  styleTitleAndImage,
  valueProgressBar,
  onClickGoBackIcon,
  comeFrom,
}: ITop) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      changeContactNavigate({
        comeFrom,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rounded-bottom-3 p-2" style={{ backgroundColor: "#00376F" }}>
      <Header
        style={styleHeader}
        class={classHeader}
        title={titleHeader}
        onClickEarphonesIcon={() => navigate("/TelephoneContact")}
      />
      <ProgressBar
        text={""}
        value={valueProgressBar}
        className={`${classProgressBar} ${displayProgressBar}`}
      />
      <TitleAndImage
        srcImage={arrow_back}
        titleText={subTitle}
        displayLeftImageTitle={displayLeftImageTitle}
        classTitle={`fw-bold fs-10 ms-2 ${classTitleImage}`}
        styleTitle={{ color: "white" }}
        onClickGoBackIcon={onClickGoBackIcon}
        divClass={"mt-3 mb-3"}
      />
    </div>
  );
};

export default Top;
