const listText = {
  top: {
    id: "top-component",
    titleHeader: "Apertura de cuenta",
    subTitle: "Información del cliente",
  },
  title: {
    id: "title",
    titleText: "Información fiscal",
  },
  inputs: {
    id: "t3",
    title: "Captura tu RFC",
    inputTextRFC: "RFC",
    inputTextHomeClave: "Homoclave",
  },
  infoToltipsText: {
    title: "¿Dónde puedo consultar mi RFC?",
    bottomSheetMessage: {
      title: "Domicilio",
      description:
        "El RFC los puedes obtener en tu constancia fiscal emitida por el SAT y recibos de nómina.",
    },
  },
  select: {
    id: "t5",
    title: "Régimen fiscal",
    placeholder: "Selecciona una opción",
  },
  radio: {
    id: "t6",
    title: "Domicilio",
    description: "¿El domicilio que aparece en tu INE coincide con tu domicilio fiscal actual?",
    radioToggleButtons: {
      toggleButtonsYes: {
        id: "Si",
        text: "Si",
      },
      toggleButtonsNo: {
        id: "No",
        text: "No",
      },
    },
  },
  button: {
    labelButtonPrimary: "Continuar",
    labelButtonSecundary: "Seguir más tarde",
  },
};

export default listText;
