import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Title from "../atoms/titleV2";
import Paragraph from "../atoms/paragraph";
import Button from "../atoms/buttonV2";
import Image from "../atoms/image";

interface IMessagesReactModal {
  show: boolean;
  onHide: () => void;
  textTitle: string;
  listButtonsModal: { text: string; spinner: boolean; onClick: () => void }[];
  onClickClose: () => void;
  listParagraphs: { textParagraph: string }[];
}

const MessagesReactModal = ({
  show,
  onHide,
  textTitle,
  listButtonsModal,
  onClickClose,
  listParagraphs,
}: IMessagesReactModal) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <div style={{ padding: 23 }}>
          <div
            onClick={onClickClose}
            style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}
          >
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.125 11.6885C11.3125 11.501 11.625 11.501 11.8125 11.6885L16 15.8135L20.125 11.6885C20.3125 11.501 20.625 11.501 20.8125 11.6885C21 11.876 21 12.1885 20.8125 12.376L16.6875 16.501L20.8125 20.6885C21 20.876 21 21.1885 20.8125 21.376C20.625 21.5635 20.3125 21.5635 20.125 21.376L16 17.251L11.8125 21.376C11.625 21.5635 11.3125 21.5635 11.125 21.376C10.9375 21.1885 10.9375 20.876 11.125 20.6885L15.25 16.501L11.125 12.376C10.9375 12.1885 10.9375 11.876 11.125 11.6885ZM21.375 2.50098C22.9375 2.50098 24.375 3.37598 25.1875 4.68848L31 14.1885C31.875 15.626 31.875 17.4385 31 18.876L25.1875 28.376C24.375 29.6885 22.9375 30.501 21.375 30.501H10.5625C9 30.501 7.5625 29.6885 6.75 28.376L0.9375 18.876C0.0625 17.4385 0.0625 15.626 0.9375 14.1885L6.75 4.68848C7.5625 3.37598 9 2.50098 10.5625 2.50098H21.375ZM1.8125 14.6885C1.125 15.8135 1.125 17.251 1.8125 18.376L7.625 27.876C8.25 28.876 9.375 29.501 10.5625 29.501H21.375C22.5625 29.501 23.6875 28.876 24.3125 27.876L30.125 18.376C30.8125 17.251 30.8125 15.8135 30.125 14.6885L24.3125 5.18848C23.6875 4.18848 22.5625 3.50098 21.375 3.50098H10.5625C9.375 3.50098 8.25 4.18848 7.625 5.18848L1.8125 14.6885Z"
                fill="#F8A15C"
              />
            </svg>
          </div>
          <div
            className="my-4"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <svg
              width="60"
              height="61"
              viewBox="0 0 60 61"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.500977" width="60" height="60" rx="30" fill="#E2F0FF" />
              <g clipPath="url(#clip0_1256_5889)">
                <path
                  d="M30 21.9854C30.2927 21.9854 30.5322 22.2249 30.5322 22.5176V33.1622C30.5322 33.4549 30.2927 33.6944 30 33.6944C29.7073 33.6944 29.4678 33.4549 29.4678 33.1622V22.5176C29.4678 22.2249 29.7073 21.9854 30 21.9854ZM31.0644 36.8877C31.0644 37.4732 30.5854 37.9522 30 37.9522C29.4145 37.9522 28.9355 37.4732 28.9355 36.8877C28.9355 36.3023 29.4145 35.8233 30 35.8233C30.5854 35.8233 31.0644 36.3023 31.0644 36.8877ZM35.7214 15.5986C37.3913 15.5986 38.9481 16.4702 39.8129 17.8979L45.9668 28.0128C46.8982 29.543 46.8982 31.459 45.9668 32.9892L39.8129 43.1015C38.9481 44.5319 37.3913 45.4034 35.7214 45.4034H24.2187C22.6087 45.4034 21.0519 44.5319 20.187 43.1015L14.0305 32.9892C13.0992 31.459 13.0992 29.543 14.0305 28.0128L20.187 17.8979C21.0519 16.4702 22.6087 15.5986 24.2187 15.5986H35.7214ZM14.9399 28.565C14.2154 29.7559 14.2154 31.2461 14.9399 32.437L21.0918 42.5493C21.7704 43.6603 22.9746 44.3389 24.2187 44.3389H35.7214C37.0254 44.3389 38.2296 43.6603 38.9015 42.5493L45.062 32.437C45.7872 31.2461 45.7872 29.7559 45.062 28.565L38.9015 18.4514C38.2296 17.341 37.0254 16.6631 35.7214 16.6631H24.2187C22.9746 16.6631 21.7704 17.341 21.0918 18.4514L14.9399 28.565Z"
                  fill="#5A6172"
                />
              </g>
              <defs>
                <clipPath id="clip0_1256_5889">
                  <rect width="40" height="40" fill="white" transform="translate(10 10.501)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className="mt-2 mx-2"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Title
              text={textTitle}
              styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
            />
            {listParagraphs.map((item: any, key: number) => {
              return (
                <Paragraph
                  key={key}
                  text={item.textParagraph}
                  className={""}
                  styleParagraph={{ fontSize: 16, color: "#00254A", fontWeight: "450", margin: 0 }}
                />
              );
            })}
            <div style={{ width: "100%" }} className="">
              {listButtonsModal.map((item: any) => {
                return (
                  <Button
                    key={item.text}
                    text={item.text}
                    buttonClassName="button-enabled w-100 place-content-center align-self-center mt-3"
                    spinner={item.spinner}
                    onClick={item.onClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MessagesReactModal;
