import Image from "../atoms/image";
import Sheet from "react-modal-sheet";
import ic_xclose from "../../../infraestructure/assets/icons/ic_xclose.svg";

const BottomSheetMessage = ({
  title,
  text,
  isOpen,
  onClickClose,
  imageInside,
  styleMainDiv,
}: any) => {
  return (
    <>
      <Sheet isOpen={isOpen} onClose={onClickClose} detent="content-height">
        <Sheet.Container>
          <Sheet.Content>
            <Sheet.Scroller>
              <div className="p-3" style={styleMainDiv}>
                <div className="row">
                  <div className="col-10 pe-0">
                    <strong className={"fw-bold"} style={{ color: "#00254A", fontSize: "17px" }}>
                      {title}
                    </strong>
                  </div>
                  <div className="col-2 py-0 ps-0 pe-3 text-end">
                    <Image
                      srcImage={ic_xclose}
                      className={""}
                      alt={""}
                      dataBsDismiss={""}
                      styleImg={{ cursor: "pointer" }}
                      onClick={onClickClose}
                    />
                  </div>
                </div>
                <div className="py-4">{text}</div>
                <div className={imageInside ? "py-0 text-center" : "d-none"}>
                  <Image
                    srcImage={imageInside}
                    className={""}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={{ width: "75%" }}
                    onClick={undefined}
                  />
                </div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClickClose} />
      </Sheet>
    </>
  );
};

export default BottomSheetMessage;
