import {
  CardOcrSDK,
  SdkOptionsType,
  TransactionMode,
} from "@identy/identy-ocr";
import { S3FileUploaderService } from "./s3FileUploader";
import { IContextMenuSelection } from "./sdk-config.interface";

interface ICardType {
  name: string;
}

const s3Upload = new S3FileUploaderService();

const makeid = function (length: any) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // tslint:disable-next-line:one-variable-per-declaration no-bitwise
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function postData(sdk: CardOcrSDK, capresult: any) {
  return new Promise((resolve, reject) => {
    return resolve(capresult);
  });
}

export function runCaptureOCR(
  transaction: TransactionMode,
  contextMenuSelection: IContextMenuSelection,
  selectAsFile: boolean,
) {
  return new Promise((resolve, reject) => {
    //this.started = contextMenuSelection.selection.fileSelection;
    let uuid = 1;
    let group = makeid(5);

    const options: SdkOptionsType = {
      allowClose: true,
      detectionModes: [],
      displayMode: "LANDSCAPE",
      a4IntegrityCheck: false,
      cardtype: contextMenuSelection.card_type,
      transaction: {
        type: transaction,
      },
      useFlash: contextMenuSelection.useFlash,
      graphics: {
        canvas: {
          labelBackground: "#FFF",
        },
        DIALOG_SPINNER: {},
      },
      localization: {
        en: {
          FEEDBACK_RETRY: "Algo es raro, muévete un poco para cambiar la iluminación.",
          FEEDBACK_RETRY_INSECURE: "Transmisión de cámara insegura",
          FEEDBACK_RETRY_QUALITY: "Pobre calidad del documento, intente otro",
          FEEDBACK_ENROLLED: "Registrado correctamente",
          FEEDBACK_CAPTURED: "Capturado con éxito",
          FEEDBACK_NOT_ENROLLED: "Usuario no inscrito",
          FEEDBACK_VERIFY_COMPLETED: "Verificación exitosa",
          FEEDBACK_VERIFY_FAILED: "Verificación no exitosa",
          FEEDBACK_SEARCHING: "Buscando...",
          FEEDBACK_INSIDE_GUIDE: "Por favor dentro de la guía",
          FEEDBACK_PLEASE_HOLD: "Por favor espera.",
          FEEDBACK_STABLE: "Permanece estable",
          FEEDBACK_EYE_LEVEL: "Mira recto",
          FEEDBACK_UP_FACING: "Mira hacia abajo para ser paralelo a la cámara",
          FEEDBACK_DOWN_FACING: "Mira hacia arriba para ser paralelo a la cámara",
          FEEDBACK_FACE_OUTSIDE: "Por favor, esté dentro de la guía",
          FEEDBACK_CLOSE: "Aleja tu teléfono",
          FEEDBACK_FAR: "Por favor acércate un poco",
          FEEDBACK_LEFT_FACING: "Looking Left, Please Look straight",
          FEEDBACK_RIGHT_FACING: "Looking Right, Please Look straight",
          FEEDBACK_ROTATED: "Mira recto",
          FEEDBACK_NOT_STABLE: "por favor mantente estable",
          FEEDBACK_OK: "Por favor espera",
          FEEDBACK_NO: "Checking",
          FEEDBACK_NOT_CENTERED: "Centra tu rostro en el óvalo",
          FEEDBACK_CAMERA_ACQUIRING_FAILED:
            "No se puede adquirir la cámara, permitir el permiso o reintentar la captura",
          FEEDBACK_PROCESSING: "Procesando...",
          FEEDBACK_UPLOAD_FAILURE: "Reintentar error interno",
          FEEDBACK_INITIALIZATION: "Inicializando...",
          FEEDBACK_CHANGE_LIGHT: "Evita las sombras",
          FEEDBACK_CHANGE_LIGHT_TOO_BRIGHT: "Demasiado brillante, muévase a una mejor iluminación",
          FEEDBACK_CHANGE_LIGHT_TOO_DARK: "Demasiado oscuro, muévase a una mejor iluminación",
          FEEDBACK_BUTTON_CLOSE: "Cerrar",
          FEEDBACK_BUTTON_RETRY: "Retry",
          FEEDBACK_TRAINING_BUTTON_NEXT: "Próximo",
          FEEDBACK_TRAINING_LABEL: "No volver a mostrar",
          FEEDBACK_ORIENTATION_NOT_SUPPORTED: "Solo se admite el modo Retrato",
          FEEDBACK_TRAINING_DIALOG_CLOSED: "Training dialog, exited",
          FEEDBACK_LICENCE_INVALID: "Licencia no válida!!",
          ERROR_BROWSER_NOT_SUPPORTED:
            "Navegador no compatible. Utilice Chrome, Firefox, Opera, Edge (iOS, Android, escritorio), Samsung Internet (Android) o Safari (iOS, MAC)",
          ERROR_BROWSER_VERSION_NOT_SUPPORTED:
            "La versión del navegador no es compatible. Actualice a la última versión disponible",
          ERROR_DEVICE_NOT_SUPPORTED:
            "Dispositivo no compatible, utilice un dispositivo móvil o una computadora de escritorio.",
          ERROR_DEVICE_NOT_SUPPORTED_ANDROID:
            "La versión de Android no es compatible. Actualice a la última versión disponible",
          ERROR_DEVICE_NOT_SUPPORTED_IOS:
            "La versión de IOS no es compatible. Actualice a la última versión disponible",
          ERROR_DEVICE_NOT_SUPPORTED_MEMORY: "Dispositivo no compatible. Memoria insuficiente",
          ERROR_PLATFORM_NOT_SUPPORTED_ANDROID:
            "La versión de Android no es compatible. Actualice a la última versión disponible",
          ERROR_PLATFORM_NOT_SUPPORTED_IOS:
            "La versión de IOS no es compatible con este navegador. Actualice a la última versión disponible",
          ERROR_BROWSER_FEATURE_NOT_SUPPORTED: "TextDecoder o Crypto API no presentes",
          ERROR_WEBRTC_NOT_SUPPORTED: "Webrtc no soportado",
          ERROR_MODEL_FAIL: "La detección del modelo falló",
          ERROR_SERVER_INTERNAL: "Error Interno del Servidor",
          ERROR_SERVER_CONNECTION_FAILURE: "Error de conexión del servidor",
          FEEDBACK_RETAKE_HEADER: "Capture de nuevo",
          FEEDBACK_RETAKE_CONTENT:
            "Asegure que esté mirando de frente y en un entorno bien iluminado",
          FEEDBACK_RETAKE_YOUR_IMAGE: "Tu imagen",
          FEEDBACK_RETAKE_OPTIMAL_IMAGE: "Imagen óptima",
          FEEDBACK_RETAKE_BUTTON: "Recapturar",
          FEEDBACK_RETAKE_BUTTON_CLOSE: "Cerrar",
          FEEDBACK_ONLY_PORTRAIT:
            "Coloca el dispositivo en posición vertical para la toma del selfie",
          FEEDBACK_CAPTURE_TIMEOUT: "Algo es extraño, inténtalo de nuevo!",
          FEEDBACK_DIALOG_CLOSED: "Captura Interrumpida",
          FEEDBACK_CHECK_QUALITY_MESSAGE: "Tu comprobante se cargó exitosamente",
          BUTTON: {
            NEXT: "Continuar",
          },
        },
      },
      events: {
        onBatteryLow: () => {
          alert("Battery low, Capture quality may suffer without flash. Please charge your phone.");
        },
        onNoDataDetected: () => {
          alert("No data detected, retry again. EXITING");
        },
        onCaptureStarted: (face: any) => {
          if (face === "BACK" && !contextMenuSelection.fileSelection) {
            alert("Capture back of the document.");
          }
        },
        onCaptured: (image: string, face: string) => {
          s3Upload.addUploadObject({
            file: `frame_${group}_${uuid}_${new Date().getTime()}.jpg`,
            type: "IMAGE",
            face: face,
            data: image,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
        },
        onRecognition: (image: string, face: string, data?: any) => {
          console.log("onRecognition", image, data);
          const ts = new Date().getTime();
          s3Upload.addUploadObject({
            file: `frame_recog_${group}_${uuid}_${ts}.jpg`,
            type: "IMAGE",
            face: face,
            data: image,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
          if (data) {
            if (options.cardtype) {
              s3Upload.addUploadObject({
                file: `frame_recog_data_${
                  options.cardtype.isA4 ? "a4" : "card"
                }_${group}_${uuid}_${ts}.txt`,
                type: "TEXT",
                face: "FRONT",
                data: JSON.stringify(data),
                bucket: "image-identy-web",
                modality: "OCR",
                ts: new Date().getTime(),
              });
            }
          }
        },
        onA4CropComplete: (full: string, cropped: string, bounds: any, actual_bounds: any) => {
          s3Upload.addUploadObject({
            file: `frame_A4_FULL_${group}_${uuid}_${new Date().getTime()}.jpg`,
            type: "IMAGE",
            face: "FRONT",
            data: full,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
          s3Upload.addUploadObject({
            file: `frame_A4_CROPPED_${group}_${uuid}_${new Date().getTime()}.jpg`,
            type: "IMAGE",
            face: "FRONT",
            data: cropped,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
          s3Upload.addUploadObject({
            file: `frame_A4_BOUNDS_corrected_${group}_${uuid}_${new Date().getTime()}.txt`,
            type: "TEXT",
            face: "FRONT",
            data: JSON.stringify(bounds),
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
          s3Upload.addUploadObject({
            file: `frame_A4_ACTUAL_BOUNDS_corrected_${group}_${uuid}_${new Date().getTime()}.txt`,
            type: "TEXT",
            face: "FRONT",
            data: JSON.stringify(actual_bounds),
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
        },
        onAttempt: (attempt: any) => {
          uuid = attempt;
        },
        onCrash: (image: any) => {
          console.log("Adding crash", image);
          s3Upload.addUploadObject({
            file: `frame_A4_CRASHED_${group}_${uuid}_${new Date().getTime()}.jpg`,
            type: "IMAGE",
            face: "FRONT",
            data: image,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
        },
        onVerifyFrame: (image: any) => {
          console.log("Adding verify", image);
          s3Upload.addUploadObject({
            file: `frame_A4_VERIFY_${group}_${uuid}_${new Date().getTime()}.jpg`,
            type: "IMAGE",
            face: "FRONT",
            data: image,
            bucket: "image-identy-web",
            modality: "OCR",
            ts: new Date().getTime(),
          });
        },
      },
      debug: true,
      selectAsFile,
    };

    const cardOcrSDK = new CardOcrSDK(options);
    cardOcrSDK.onInit = () => {
      // @ts-ignore
      cardOcrSDK
        .capture()
        .then(async (blob: any) => {
          return resolve(postData(cardOcrSDK, blob));
        })
        .catch((err) => {
          s3Upload.upload_frames().then(() => {
            console.log("Frames uploaded....");
          });
          if (err && err["code"] && err["code"] === 811) {
            alert("Native crash");
          }
          return console.log(err);
        });
    };

    cardOcrSDK.initialize().catch((err) => {
      const m = err && err["getLocalizedString"];
      if (m) {
        if (err.code === 100 || err.code === 104 || err.code === 500) {
          alert(err.getLocalizedString());
          console.log(err);
        }

        if (err.code === 600 || err.code === 506) {
          const clientResponse = { code: err.code, feedback_code: err.message, spoof: null };
          console.log(clientResponse);
        }
      } else {
        console.log(err);
      }
    });
  });
}
