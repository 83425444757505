/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authToken: "",
  geoPosition: "",
  callerIP: "",
};

export const authTokenSlice = createSlice({
  name: "authToken",
  initialState,
  reducers: {
    addAuthToken: (state, action) => {
      const { authToken, geoPosition, callerIP } = action.payload;
      state.authToken = authToken;
      state.geoPosition = geoPosition;
      state.callerIP = callerIP;
    },
  },
});

export const { addAuthToken } = authTokenSlice.actions;
export default authTokenSlice.reducer;
