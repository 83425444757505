import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerCode: "",
};

export const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    addOTP: (state, action) => {
      const { registerCode } = action.payload;
      state.registerCode = registerCode;
    },
  },
});

export const { addOTP } = otpSlice.actions;
export default otpSlice.reducer;
