import fetchBxiApiGateway from "./fetchBxiApiGateway";

const apiPostVideoCapture = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/video-recording`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
  });
};

const apiPostVideoCaptureRedirect = (processId: string, urlRedirect: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/video-recording/redirect?url=${urlRedirect}`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
  });
};

const getVideoRecordingWorkflows = (
  processId: string,
  uuidVideoCapture: string,
  methodType: string,
) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/video-recording/workflows/${uuidVideoCapture}`,
    method: methodType,
    headers: {
      "process-id": processId,
    },
  });
};

export { apiPostVideoCapture, apiPostVideoCaptureRedirect, getVideoRecordingWorkflows };
