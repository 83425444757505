const vulnerabilityQuestionnaireDictionary = {
  headerTitle: "Apertura de cuenta",
  navigationTitle: "Información del cliente",
  title: "Cuestionario de vulnerabilidad",
  subtitle: "Declaro de forma libre y voluntaria que me autodenomino parte de un grupo de personas en situación de vulnerabilidad.",
  group: {
    title: "Selecciona el grupo al que perteneces:"
  },
  inputOther: {
    name: "Respuesta",
    placeholder: "Ingresa el grupo al que perteneces"
  },
  btn: {
    submit:"Continuar"
  }
};

export default vulnerabilityQuestionnaireDictionary;
