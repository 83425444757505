/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pdfViewerData } from "../redux/slices/pdfViewerReducer";
import withe_phone from "../assets/icons/withe_phone.svg";

const Test = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showPdf = (url: string, title: string) => {
    dispatch(
      pdfViewerData({
        urlPdf: url,
        comeFrom: "/Test",
        title: title,
      }),
    );
    navigate("/PdfViewer");
  };

  const irBlackList = () => {
    navigate("/BlackLists");
  }

  const callExecutive1 = () => {
    window.open(`tel:8008003224`, "_self");
  }

  return (
    <>
      <div className="content-container">
        <div className="p-3">
          <p
            className={"mb-3"}
            style={{
              fontSize: "16px",
              color: "#004A94",
              textDecorationLine: "underline",
            }}
            onClick={() => navigate("/InformationRequirements")}
          >
            {"Regresar"}
          </p>
          <p
            className={"mb-3"}
            style={{
              fontSize: "16px",
              color: "#004A94",
              textDecorationLine: "underline",
            }}
            onClick={() =>
              showPdf("https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK", "Test view PDF 2")
            }
          >
            {"Test view PDF 1"}
          </p>
          <p
            className={"mb-3"}
            style={{
              fontSize: "16px",
              color: "#004A94",
              textDecorationLine: "underline",
            }}
            onClick={() => showPdf("https://arxiv.org/pdf/quant-ph/0410100.pdf", "Test view PDF 2")}
          >
            {"Test view PDF 2"}
          </p>
          <p
            className={"mb-3"}
            style={{
              fontSize: "16px",
              color: "#004A94",
              textDecorationLine: "underline",
            }}
            onClick={() => irBlackList()}
          >
            {"Black List"}
          </p>
          <p
            className={"mb-3"}
            style={{
              fontSize: "16px",
              color: "#004A94",
              textDecorationLine: "underline",
            }}
            onClick={() => callExecutive1()}
          >
            {"llamar ejecutivo 1"}
          </p>
          <a
            target="_blank"
            rel=""
            href="tel:8008003224"
            className={
              "d-grid gap-2 btn-bg-primary text-white w-100 place-content-center align-self-center rounded-3 pt-3"
            }
          >
            <p>
              <img src={"" + withe_phone} alt="" /> {"Llamar a un Ejecutivo"}
            </p>
          </a>
          <a
            target="_blank"
            rel=""
            href="tel:8008003224"
            className={`toggle-btn-dark-blue text-white btn-bg-primary align-self-center align-items-center`}
            style={{ 
              fontSize: 16,
              fontWeight: 600,
              display: "flex",
              width: "100%",
              padding: "10px 24px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              height: "40px"
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.905 3.75C4.95 4.4175 5.0625 5.07 5.2425 5.6925L4.3425 6.5925C4.035 5.6925 3.84 4.74 3.7725 3.75H4.905ZM12.3 12.765C12.9375 12.945 13.59 13.0575 14.25 13.1025V14.22C13.26 14.1525 12.3075 13.9575 11.4 13.6575L12.3 12.765ZM5.625 2.25H3C2.5875 2.25 2.25 2.5875 2.25 3C2.25 10.0425 7.9575 15.75 15 15.75C15.4125 15.75 15.75 15.4125 15.75 15V12.3825C15.75 11.97 15.4125 11.6325 15 11.6325C14.07 11.6325 13.1625 11.4825 12.3225 11.205C12.2475 11.175 12.165 11.1675 12.09 11.1675C11.895 11.1675 11.7075 11.2425 11.5575 11.385L9.9075 13.035C7.785 11.9475 6.045 10.215 4.965 8.0925L6.615 6.4425C6.825 6.2325 6.885 5.94 6.8025 5.6775C6.525 4.8375 6.375 3.9375 6.375 3C6.375 2.5875 6.0375 2.25 5.625 2.25Z"
                fill="#ffffff"
              />
            </svg>
            <p>{"Llamar a un Ejecutivo"}</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default Test;
