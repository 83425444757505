import * as React from "react";
import ITitle from "../../interfaces/title";

const Title = ({ text, className, styleTitle }: ITitle) => {
  return (
    <strong className={className} style={styleTitle}>
      {text}
    </strong>
  );
};

export default Title;
