import { useState } from "react";

import Text from "../../../utils/dictionaries/dictionary-use-account-management";
import circleInfoSharpRegular from "../../../assets/icons/circle-info-sharp-regular.svg";

import Paragraph from "../../../components/atoms/paragraph";
import { RadioToggleButton } from "../../../components/molecules/radio-toggle-buttons";
import TitleAndImage from "../../../components/molecules/title-and-image";
import BottomSheetMessage from "../../../components/templates/bottom-sheet-message";
import "./style.scss";
interface IAlertData {
  formik: any;
}

const Management = ({ formik }: IAlertData) => {
  const [isOpenTooltips, setIsOpenTooltips] = useState<boolean>(false);
  return (
    <>
      <div className="">
        <Paragraph
          text={formik.values.surve[0]?.description}
          className={""}
          styleParagraph={undefined}
        />
        <RadioToggleButton
          id={"accountType"}
          text={formik.values.surve[0]?.answers[0].description.replace("A)", "")}
          value={"Si"}
          type={"radio"}
          name={"accountType"}
          onChange={() => {
            formik.setFieldValue("surve[0].answers[0].selection", true);
            formik.setFieldValue("surve[0].answers[1].selection", false);
          }}
          isSelected={formik.values.surve[0]?.answers[0].selection === true}
          className="radioToggleButton me-3"
          icon={undefined}
          displayImg="d-none"
          disabledInput={false}
          divStyle={undefined}
          labelStyle={{ paddingRight: "10px", paddingLeft: "10px" }}
          onClick={() => {
            formik.setFieldValue("surve[0].answers[0].selection", true);
            formik.setFieldValue("surve[0].answers[1].selection", false);
            formik.setFieldValue("accountType", "yes");
          }}
        />
        <RadioToggleButton
          id={"accountType"}
          text={formik.values.surve[0]?.answers[1].description.replace("B)", "")}
          value={"No"}
          type={"radio"}
          name={"accountType"}
          onChange={() => formik.setFieldValue("accountType", "no")}
          isSelected={formik.values.surve[0]?.answers[1].selection === true}
          className="radioToggleButton"
          icon={undefined}
          displayImg="d-none"
          disabledInput={undefined}
          divStyle={undefined}
          labelStyle={{ paddingRight: "10px", paddingLeft: "10px" }}
          onClick={() => {
            formik.setFieldValue("surve[0].answers[1].selection", true);
            formik.setFieldValue("surve[0].answers[0].selection", false);
            formik.setFieldValue("accountType", "no");
          }}
        />
      </div>
      <div className="mt-4_3">
        <Paragraph
          text={formik.values.surve[1]?.description}
          className={""}
          styleParagraph={undefined}
        />
        <div className="">
          <RadioToggleButton
            id={"myAccount"}
            text={formik.values.surve[1]?.answers[0].description.replace("A)", "")}
            value={"Si"}
            type={"radio"}
            name={"myAccount"}
            onChange={() => formik.setFieldValue("myAccount", "yes")}
            isSelected={formik.values.surve[1]?.answers[0].selection === true}
            className="radioToggleButton me-3"
            icon={undefined}
            displayImg="d-none"
            disabledInput={undefined}
            divStyle={undefined}
            labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
            onClick={() => {
              formik.setFieldValue("surve[1].answers[0].selection", true);
              formik.setFieldValue("surve[1].answers[1].selection", false);
              formik.setFieldValue("myAccount", "yes");
            }}
          />
          <RadioToggleButton
            id={"myAccount"}
            text={formik.values.surve[1]?.answers[1].description.replace("B)", "")}
            value={"No"}
            type={"radio"}
            name={"myAccount"}
            onChange={() => formik.setFieldValue("myAccount", "no")}
            isSelected={formik.values.surve[1]?.answers[1].selection === true}
            className="radioToggleButton"
            icon={undefined}
            displayImg="d-none"
            disabledInput={undefined}
            divStyle={undefined}
            labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
            onClick={() => {
              formik.setFieldValue("surve[1].answers[0].selection", false);
              formik.setFieldValue("surve[1].answers[1].selection", true);
              formik.setFieldValue("myAccount", "no");
            }}
          />
        </div>
      </div>
      <div className="mt-4_3 mb-4_3">
        <Paragraph
          text={formik.values.surve[2]?.description}
          className={""}
          styleParagraph={undefined}
        />
        <div className="">
          <RadioToggleButton
            id={"accountFamily"}
            text={formik.values.surve[2]?.answers[0].description.replace("A)", "")}
            value={"Confirmo"}
            type={"radio"}
            name={"accountFamily"}
            onChange={() => formik.setFieldValue("accountFamily", "yes")}
            isSelected={formik.values.surve[2]?.answers[0].selection === true}
            className="radioToggleButton me-3"
            icon={undefined}
            displayImg="d-none"
            disabledInput={undefined}
            divStyle={undefined}
            labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
            onClick={() => {
              formik.setFieldValue("surve[2].answers[0].selection", true);
              formik.setFieldValue("surve[2].answers[1].selection", false);
              formik.setFieldValue("accountFamily", "yes");
            }}
          />
          <RadioToggleButton
            id={"accountFamily"}
            text={formik.values.surve[2]?.answers[1].description.replace("B)", "")}
            value={"No confirmo"}
            type={"radio"}
            name={"accountFamily"}
            onChange={() => formik.setFieldValue("accountFamily", "no")}
            isSelected={formik.values.surve[2]?.answers[1].selection === true}
            className="radioToggleButton"
            icon={undefined}
            displayImg="d-none"
            disabledInput={undefined}
            divStyle={undefined}
            labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
            onClick={() => {
              formik.setFieldValue("surve[2].answers[0].selection", false);
              formik.setFieldValue("surve[2].answers[1].selection", true);
              formik.setFieldValue("accountFamily", "no");
            }}
          />
        </div>
      </div>
      <TitleAndImage
        srcImage={circleInfoSharpRegular}
        titleText={Text.titleAndImage.title}
        classTitle={"container_form__title_and_image m-2"}
        onClickTitleImgeDiv={() => setIsOpenTooltips(!isOpenTooltips)}
        divClass={""}
      />
      <BottomSheetMessage
        imageInside={undefined}
        title={Text.titleAndImage.bottomSheetMessage.title}
        text={Text.titleAndImage.bottomSheetMessage.descrition}
        isOpen={isOpenTooltips}
        onClickClose={() => setIsOpenTooltips(!isOpenTooltips)}
        styleMainDiv={{ height: "auto" }}
      />
    </>
  );
};

export default Management;
