import IAcceptAuthorizeService from "../../domain/services/IAcceptAuthorize.service";

export class classAcceptAuthorizeUseCase {
  constructor(private acceptAuthorizeService: IAcceptAuthorizeService) {}

  public async termsConditionsAutorize(uuid: string) {
    try {
      const response = await this.acceptAuthorizeService.saveTermsConditionsAutorize(uuid);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: null, error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }

  public async ineConsenAcceptances(request: any) {
    try {
      const response = await this.acceptAuthorizeService.saveIneConsenAcceptances(request);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: null, error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error(error);
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }

  public async saveDocumentationAcceptances(request: any) {
    try {
      const response = await this.acceptAuthorizeService.saveDocumentationAcceptances(request);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: null, error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error(error);
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }

  public async saveSignatureData(request: any, uuid: string) {
    try {
      const response = await this.acceptAuthorizeService.saveSignatureData(request, uuid);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: null, error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error(error);
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }
}
