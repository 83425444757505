import insert_drive_file from "../../assets/icons/insert_drive_file.svg";
import document_scanner from "../../assets/icons/document_scanner.svg";
import hand_wave_sharp_regular from "../../assets/icons/hand-wave-sharp-regular.svg";
import sun_sharp_regular from "../../assets/icons/sun-sharp-regular.svg";

const listText = {
  text_0: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_1: {
    id: "t1",
    mainText: "Captura comprobante de domicilio",
  },
  text_2: {
    id: "t2",
    mainText: "Usa la cámara de tu celular para capturar el comprobante.",
  },
  arrayTextsCFE: [
    {
      title: "",
      description: "Usa un comprobante de luz CFE.",
      icon: insert_drive_file,
    },
    {
      title: "",
      description: "El comprobante deberás enfocarlo en la sección para capturarlo.",
      icon: document_scanner,
    },
    {
      title: "",
      description: "Evitar mover la cámara al capturarlo, la imagen debe ser clara.",
      icon: hand_wave_sharp_regular,
    },
    { title: "", description: "Elige un lugar con buena iluminación.", icon: sun_sharp_regular },
  ],
  arrayTextTelmex: [
    {
      title: "",
      description: "Usa un recibo TELMEX.",
      icon: insert_drive_file,
    },
    {
      title: "",
      description: "El comprobante deberás enfocarlo en la sección para capturarlo.",
      icon: document_scanner,
    },
    {
      title: "",
      description: "Evitar mover la cámara al capturarlo, la imagen debe ser clara.",
      icon: hand_wave_sharp_regular,
    },
    { title: "", description: "Elige un lugar con buena iluminación.", icon: sun_sharp_regular },
  ],

  text_23: {
    id: "t23",
    mainText: "Continuar",
  },
  text_24: {
    id: "t24",
    mainText: "Seguir más tarde",
  },
};

export default listText;
