import IImage from "../../interfaces/image";

const Image = ({ srcImage, className, alt, dataBsDismiss, styleImg, onClick }: IImage) => {
  return (
    <img
      src={srcImage}
      className={className}
      alt={alt}
      data-bs-dismiss={dataBsDismiss}
      style={styleImg}
      onClick={onClick}
    />
  );
};

export default Image;
