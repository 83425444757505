const listText = {
  header: {
    title: "Apertura de cuenta",
  },
  body: {
    title: "¡Felicidades!"
  },
  modal: {
    modalTitle: "¡Ups lo sentimos!",
    retryButton: "Reintentar",
    errorMessage1: "La aplicación no está respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
    errorMessage2: "Vuelve a intentar si el error persiste."
  },
  footer: {
    title: "Entendido"
  }
};

export default listText;
