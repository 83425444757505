import IProgressBar from "../../interfaces/progress-bar";
import * as React from "react";

const ProgressBar = (props: IProgressBar) => {
  return (
    <div className={props.className}>
      <div className="progressBar">
        <div
          className="progress"
          role="progressbar"
          aria-label="progressBar"
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div className="progress-bar" style={{ width: props.value }}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
