import "../../styles/button.scss";
import Spinner from "./spinner";

const Button = (props: any) => {
  return (
    <div className={props.divClassName}>
      <button
        className={props.buttonClassName}
        type="button"
        onClick={props.onClick}
        disabled={props.disabled}
        data-bs-dismiss={props.dataBsDismiss}
        style={props.styleButton}
      >
        {props.img !== "" && props.img !== undefined && props.img !== null ? (
          <img className={props.classNameImg} src={props.img} alt="" />
        ) : (
          ""
        )}

        {props.spinner ? <Spinner></Spinner> : props.text}
      </button>
    </div>
  );
};

export default Button;
