import { useState, useEffect, SetStateAction, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Atoms
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import Paragraph from "../../components/atoms/paragraph";
import Loader from "../../components/atoms/loader";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Molecules
import { RadioToggleButton } from "../../components/molecules/radio-toggle-buttons";
// Services
import PersonalDataService from "../../services/personalData.service";
// Use-Case
import { DataINEuseCase } from "../../../application/use-cases/dataINE.usecase";
// Redux
import { store } from "../../redux/store";
import {
  addConfirmData,
  credentialData,
  principalAddress,
} from "../../redux/slices/confirmDataReducer";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";

const getDataINEService = new PersonalDataService();
const usecasegetDataINE = new DataINEuseCase(getDataINEService);

const AddressCondition = () => {
  const { onClickContinue } = useFollowLater();
  const state = store.getState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [btnColor, setBtnColor] = useState("button-disabled");
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [enabledButton, setEnabledButton] = useState(true);
  const [radioToggleValue, setRadioToggleValue] = useState("");
  const [addressIne, setAddressIne] = useState(true);
  const [, setSec] = useState<number>(0);
  const [toggle] = useState<boolean>(false);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const [modalShow, setModalShow] = useState(false);
  

  const getDataINE = async () => {
    setIsLoading(true);
    const response = await usecasegetDataINE.getDataINE(state.client.clientPersist.uuid);
    if (response.error !== null) {
      setModalShow(true);
    } else {
      if (response.data) {
        const dateString = response.data.dateBirth;
        const dateObj = dateString?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3");
        /// Si el verifica cual de los datos de array tiene un codigo de  zip para cargar mejor la data
        const address = response.data.addresses.find((key: any) => key.zipCode !== "");

        dispatch(
          addConfirmData({
            ...response.data,
            dateBirth: dateObj,
            email: state.client.dataPersist.email,
            phone: state.client.dataPersist.phone,
            placeBirth: "MX",
            nationality: "MX",
          }),
        );

        dispatch(principalAddress({ ...address, suburbs: address?.town, country: "MX" }));

        dispatch(credentialData({ ...response.data.credentialData }));

        onClickContinue({
          data: {
            confirmData: {
              ...response.data,
              dateBirth: dateObj,
              email: state.client.dataPersist.email,
              phone: state.client.dataPersist.phone,
              placeBirth: "MX",
              nationality: "MX",
              principalAddress: { ...address, suburbs: address.town, country: "MX" },
              ...response.data.credentialData,
            },
            dataPersist: { ...state.client.dataPersist },
          },
          processId: state.client.clientPersist.uuid,
          resource: "AddressCondition",
          step: "CONTINUE",
        });
      }
    }
    setIsLoading(false);
  };

  const continueProcess = async (e:any) => {
    e.preventDefault();
    let routerNext = "/ProofAddressCondition";
    if (radioToggleValue == "Si coincide") {
      routerNext = "/DataConfirmation";
    }
    trackAnalyticsEvent(
      AmplitudEventName.Confirmar_dirección_INE_N4,
      { "Dirección_del_INE" :  radioToggleValue === "Si coincide" ? "Si coincide" : "No coincide"}
    )
    navigate(routerNext);
  };

  const radioChangeHandler = (e: { target: { value: SetStateAction<string> } }) => {
    setRadioToggleValue(e.target.value);
    setEnabledButton(false);
    setBtnColor("button-enabled");
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "AddressCondition",
        processId: state.client.clientPersist.uuid,
        step: "CONTINUE_AFTER",
        data: {
          confirmData: { ...state.client.confirmData },
          dataPersist: { ...state.client.dataPersist },
        },
      },
    });
  };

  const functionProcessModal = () => {
    setModalShow(false);
    window.location.reload();
  };

  useEffect(() => {
    getDataINE();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!addressIne) {
      setRadioToggleValue("No, capturar comprobante");
      setEnabledButton(false);
      setBtnColor("button-enabled");
    }
  }, [addressIne]);

  useEffect(() => {
    ref.current = setInterval(() => {
      if (toggle) setSec((state) => state + 1);
      const condition = localStorage.getItem("condition") === "true" ? true : false;
      if (!condition) {
        navigate("/CaptureINE");
      } else {
      }
    });

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={"Apertura de cuenta"}
          subTitle={"Domicilio"}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={""}
          classHeader={"mt-2 mb-3"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"40%"}
          comeFrom={"/AddressCondition"}
          onClickGoBackIcon={undefined}
        />
        <div className="p-3">
          <Paragraph
            text={"¿El domicilio que aparece en tu INE coincide con tu domicilio actual?"}
            className={""}
            styleParagraph={undefined}
          />
          <div className="mb-4_3">
            <RadioToggleButton
              id={"Si coincide"}
              text={"Si coincide"}
              value={"Si coincide"}
              type={"radio"}
              name={"Si coincide"}
              onChange={radioChangeHandler}
              isSelected={radioToggleValue == "Si coincide"}
              className="radioToggleButton me-3"
              icon={undefined}
              displayImg="d-none"
              disabledInput={
                !addressIne || state.client.confirmData.principalAddress.zipCode === ""
              }
              divStyle={undefined}
              labelStyle={
                !addressIne || !state.client.confirmData.principalAddress.zipCode
                  ? { color: "#A9AEBB", pointerEvents: "none" }
                  : { pointerEvents: "auto" }
              }
              onClick={undefined}
            />
            <RadioToggleButton
              id={"No, capturar comprobante"}
              text={"No, capturar comprobante"}
              value={"No, capturar comprobante"}
              type={"radio"}
              name={"No, capturar comprobante"}
              onChange={radioChangeHandler}
              isSelected={radioToggleValue == "No, capturar comprobante"}
              className="radioToggleButton"
              icon={undefined}
              displayImg="d-none"
              disabledInput={undefined}
              divStyle={undefined}
              labelStyle={undefined}
              onClick={undefined}
            />
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <div className="wrapping-box-container">
          <div id="main-interface">
            <Button
              text={"Continuar"}
              divClassName={"d-grid gap-2"}
              buttonClassName={`${btnColor} w-100 place-content-center align-self-center`}
              spinner={activeSpinner}
              onClick={(e:any) => {
                continueProcess(e);
              }}
              disabled={enabledButton}
              dataBsDismiss={undefined}
            />
            <div className="text-center mt-3">
              <Link
                text={"Seguir más tarde"}
                url={null}
                styleLink={{
                  color: "#5A6172",
                  lineHeight: "16px",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={followLaterAction}
                className={""}
              />
            </div>
          </div>
        </div>
      </div>
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={"¡Ups lo sentimos!"}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default AddressCondition;
