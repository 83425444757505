import ISignatureData from "../interfaces/api/ISignatureData";
import IAcceptAuthorizeService from "../../domain/services/IAcceptAuthorize.service";
import {
  saveTermsConditionsAcceptAutorize,
  saveIneConsenAcceptances,
  saveDocumentationAcceptances,
  saveSignatureData,
} from "./api/apiAcceptAuthorize";

export default class AcceptAuthorizeUtilService implements IAcceptAuthorizeService {
  async saveTermsConditionsAutorize(uuid: string): Promise<any> {
    return await saveTermsConditionsAcceptAutorize(uuid);
  }
  async saveIneConsenAcceptances(request: any): Promise<any> {
    return await saveIneConsenAcceptances(request);
  }
  async saveDocumentationAcceptances(request: any): Promise<any> {
    return await saveDocumentationAcceptances(request);
  }
  async saveSignatureData(request: ISignatureData, uuid: string): Promise<any> {
    return await saveSignatureData(request, uuid);
  }
}
