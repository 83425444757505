import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

// Atoms
import Button from "../../components/atoms/button";
import Title from "../../components/atoms/titleV2";
import Paragraph from "../../components/atoms/paragraph";
import Loader from "../../components/atoms/loader";
// Templates
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import MessagesReactModal from "../../components/templates/messages-react-modalV2";

// Molecules
import Input from "../../components/molecules/input";
import Select from "../../components/molecules/select";
import { onBeneficiaries } from "../../redux/slices/beneficiaries";
// hooks 
import { useRedux } from "../../hooks/useRedux";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useApi } from "../../hooks/useApi";
// Services 
import BeneficiariesService from "../../services/apiBeneficiaries.service";
// Intefaces 
import { IBeneficiarie } from "../../interfaces/api/IBeneficiaries";
// Components
import { map2Obj } from "../beneficiaries/utils";
// Dictionaries
import Text from "../../utils/dictionaries/dictionary-laborInformation-client";
// Schema
import validationSchema from "./schema";
// Amplitud
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
const { saveBeneficiaries } = new BeneficiariesService();

const LivenessCheckClient = () => {
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();
  const { onClickContinue } = useFollowLater();
  const [isModaError, setIsModalError] = useState<boolean>(false);
  const {
    data: {
      clientPersist: { uuid },
      beneficiaries: beneficiariesSate,
      dataPersist,
    },
    dispatch,
  } = useRedux();
  const {
    data: dataBeneficiaries,
    error: errorSaveBeneficiaries,
    execute: executeSaveBeneficiaries,
    isLoading: isBeneficiaries,
  } = useApi<{ message: string }, { beneficiaries: IBeneficiarie[]; processId: string }>(
    saveBeneficiaries,
  );

  const formik = useFormik({
    initialValues: {
      beneficiaries: beneficiariesSate.data,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  const isPercentage: number = formik.values.beneficiaries.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue.profitPercentage),
    0,
  );

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (isPercentage === 100) {
      retVal = false;
    } else {
      retVal = true;
    }
    return retVal;
  };

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "BeneficiariesCheckClient",
        step: "CONTINUE_AFTER",
        data: { beneficiaries: formik.values.beneficiaries, dataPersist },
      },
    });
    dispatch(onBeneficiaries(formik.values.beneficiaries));
  };

  const onOk = () => {
    const dataFormBeneficiaries: IBeneficiarie[] = formik.values.beneficiaries.map((key) => ({
      ...map2Obj({ ...key }),
    }));
    executeSaveBeneficiaries({ beneficiaries: dataFormBeneficiaries, processId: uuid });
    trackAnalyticsEvent(
      AmplitudEventName.Capturar_beneficiarios_N4,
      {
        "Número_de_beneficiarios_ingresados" :  formik.values.beneficiaries.length + 1
      }
    );
  };

  useEffect(() => {
    if (
      dataBeneficiaries?.message === "Beneficiaries save Success" &&
      !errorSaveBeneficiaries &&
      !isBeneficiaries
    ) {
      navigate("/LivenessCheck");
      onClickContinue({
        data: { beneficiaries: formik.values.beneficiaries, dataPersist },
        processId: uuid,
        resource: "BeneficiariesCheckClient",
        step: "CONTINUE",
      });
      dispatch(onBeneficiaries(formik.values.beneficiaries));
    } else if (errorSaveBeneficiaries && !isBeneficiaries) {
      setIsModalError(true);
      setLoadingRedirect(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSaveBeneficiaries, dataBeneficiaries, isBeneficiaries]);

  if (isBeneficiaries) {
    return <Loader />;
  }

  return (
    <>
      <Top
        titleHeader={"Apertura de cuenta"}
        subTitle={"Beneficiarios"}
        displayLeftImageTitle={"d-block"}
        displayProgressBar={"d-block"}
        classHeader={"mt-2"}
        classProgressBar={"mt-3"}
        valueProgressBar={"60%"}
        onClickGoBackIcon={() => navigate("/BeneficiariesClient")}
      />
      <div
        className="container_form mb-4_3"
        style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 34 }}
      >
        <div className=" mb-4">
          <Paragraph
            styleParagraph={{
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 400,
            }}
            text={"Selecciona el porcentaje que asignas a tus beneficiarios."}
            className={"container_form__title"}
          />
        </div>
        <>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 32,
              width: "100%",
              height: "70%",
            }}
          >
            {formik.values.beneficiaries.map((memo, i) => {
              return (
                <>
                  <div style={{ width: "100%", height: 140 }}>
                    <div className="">
                      <Title text={` Beneficiario ${i + 1}`} className={"container_form__title"} />
                    </div>
                    <div style={{ display: "inline-flex", alignItems: "center", gap: 8 }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="12" fill="#CBE1F8" />
                        <path
                          d="M11.9987 7.00065C12.9154 7.00065 13.6654 7.75065 13.6654 8.66732C13.6654 9.58398 12.9154 10.334 11.9987 10.334C11.082 10.334 10.332 9.58398 10.332 8.66732C10.332 7.75065 11.082 7.00065 11.9987 7.00065ZM11.9987 15.334C14.2487 15.334 16.832 16.409 16.9987 17.0007H6.9987C7.19036 16.4007 9.75703 15.334 11.9987 15.334ZM11.9987 5.33398C10.157 5.33398 8.66536 6.82565 8.66536 8.66732C8.66536 10.509 10.157 12.0007 11.9987 12.0007C13.8404 12.0007 15.332 10.509 15.332 8.66732C15.332 6.82565 13.8404 5.33398 11.9987 5.33398ZM11.9987 13.6673C9.7737 13.6673 5.33203 14.784 5.33203 17.0007V18.6673H18.6654V17.0007C18.6654 14.784 14.2237 13.6673 11.9987 13.6673Z"
                          fill="#005CB9"
                        />
                      </svg>
                      <div style={{ display: "flex", flexDirection: "column", height: 42 }}>
                        <Title
                          text={
                            `${memo.name} ` + `${memo.fatherLastName} ` + `${memo.motherLastName}`
                          }
                          className={"container_form__title"}
                          styleTitle={{ fontWeight: 500, fontSize: 15 }}
                        />
                        <p
                          style={{
                            color: "#363A44",
                            fontSize: 12,
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          {`${memo.date.day} - ${memo.date.month} - ${memo.date.year}`}
                        </p>
                      </div>
                    </div>
                    <Select
                      value={formik.values.beneficiaries[i]?.profitPercentage}
                      placeholder={"%"}
                      data={[10, 20, 30, 40, 50, 60, 70, 80, 90].map((key, i) => ({
                        value: key,
                        label: `${key}% `,
                      }))}
                      required={isPercentage > 100 ? true : false}
                      onChange={(e: any) =>
                        formik.setFieldValue(`beneficiaries[${i}].profitPercentage`, e.target.value)
                      }
                    />
                  </div>
                  {i !== formik.values.beneficiaries.length - 1 && (
                    <div style={{ width: "100%", height: 1, background: "#CBCFD6" }}></div>
                  )}
                </>
              );
            })}
            {isPercentage > 100 && (
              <div className="alert_message">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22.002C14.6522 22.002 17.1957 20.9484 19.0711 19.073C20.9464 17.1977 22 14.6541 22 12.002C22 9.34979 20.9464 6.80625 19.0711 4.93089C17.1957 3.05552 14.6522 2.00195 12 2.00195C9.34784 2.00195 6.8043 3.05552 4.92893 4.93089C3.05357 6.80625 2 9.34979 2 12.002C2 14.6541 3.05357 17.1977 4.92893 19.073C6.8043 20.9484 9.34784 22.002 12 22.002ZM12.9375 7.00195V7.93945V12.3145V13.252H11.0625V12.3145V7.93945V7.00195H12.9375ZM11.0625 16.377V14.502H12.9375V16.377H11.0625Z"
                    fill="#E42A23"
                  />
                </svg>
                <p style={{ color: "#363A44", fontSize: 16, fontWeight: 400 }}>
                  {"Para poder continuar, asegúrate de que el porcentaje total sea 100%"}
                </p>
              </div>
            )}
          </div>
        </>
      </div>
      <Footer
        activeSpinner={isBeneficiaries}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => onOk()}
        labelButtonPrimary={Text.button.labelButtonPrimary}
        labelButtonSecundary={Text.button.labelButtonSecundary}
        onClickButtonLink={() => onClickButtonLink}
        position="relative"
      />
      <MessagesReactModal
        show={isModaError}
        onHide={() => setIsModalError(false)}
        textTitle={"¡Ups lo sentimos!"}
        listButtonsModal={[
          {
            text: "Reintentar",
            spinner: false,
            onClick: () => {
              setIsModalError(false);
            },
          },
        ]}
        onClickClose={() => console.log(false)}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
          },
        ]}
      />
    </>
  );
};

export default LivenessCheckClient;
