const listText = {
  title: {
    id: "title",
    titleText: "Información fiscal",
  },
  inputs: {
    id: "t3",
    inputTextPostalCode: "Código postal",
    inputTextState: "Estado",
    inputTextMunicipality: "Municipio/Alcaldía",
    inputTextCologne: "Colonia",
    inputTextStreet: "Calle",
    inputTextOutsideNumber: "Núm. exterior",
    inputTextInternalNumber: "Núm. interior (op..)",
  },
  button: {
    labelButtonPrimary: "Continuar",
  },
};

export default listText;
