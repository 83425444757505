const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Captura del INE",
  },
  text_3: {
    id: "t3",
    mainText: "Usa la cámara de tu celular para capturar el frente de tu INE.",
  },
  text_4: {
    id: "t4",
    mainText: "Elige un lugar con buena iluminación",
  },
  text_5: {
    id: "t5",
    mainText: "La imagen debe ser clara",
  },
  text_6: {
    id: "t6",
    mainText: "Enfoca tu identificación de manera correcta",
  },
  text_7: {
    id: "t7",
    mainText: "Continuar",
  },
};

export default listText;
