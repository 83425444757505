import { ISelect } from "../../interfaces/select";
import Spinner from "../atoms/spinnerV2";

const SelectSheet = (props: ISelect) => {
  return (
    <>
      <select
        {...props}
        className={`form-select py-0 ${props.className} ${props.required || props.error ? "is-invalid" : ""
          }`}
        aria-label="select default "
        style={{ height: "55px" }}
        disabled={props.disabled}
      >
        {props.empty ?
          <option key="EMPTY" value="EMPTY" disabled>
            {props.placeholder}
          </option>
          :
          <option key="EMPTY" value="" disabled>
            {props.placeholder}
          </option>}
        {props.data.map((memo) => {
          return (
            <option key={memo.value + memo.label} value={memo.value}>
              {memo.label}
            </option>
          );
        })}
      </select>
      {props.text ? (
        <label htmlFor={props.id} className={props.disabled ? "disabled-link" : "dark-gray"}>
          {props.text}
        </label>
      ) : (
        ""
      )}

      {props.error && <small className="block dangerous-red">{props.error}</small>}
    </>
  );
};

export default SelectSheet;
