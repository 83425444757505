const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "¡Lo sentimos!",
  },
  text_3: {
    id: "t3",
    mainText: "No es posible continuar con la solicitud de apertura de cuenta digital.",
  },
  text_4: {
    id: "t4",
    mainText:
      "De acuerdo a los datos proporcionados hemos detectado que te encuentras en la listas de de Unidad de Inteligencia Financiera. Revisa tu correo para conocer el detalle.",
  },
  text_5: {
    id: "t5",
    mainText: "Para continuar se requiere asistencia de un Ejecutivo",
  },
  text_6: {
    id: "t6",
    mainText:
      "Hemos identificado que se encuentra registrado (a) como persona Políticamente Expuesta. Para continuar con el proceso de su apertura de cuenta es necesario ser atendido por uno de nuestros Ejecutivos.",
  },
  text_7: {
    id: "t7",
    mainText:
      "De acuerdo a la información proporcionada es necesario que para concluir el trámite de apertura de cuenta seas atendido por uno de nuestros Ejecutivos.",
  },
  text_8: {
    id: "t8",
    mainText:
      "De acuerdo a la información proporcionada es necesario contactar a nuestra línea de atención.",
  },
  text_9: {
    id: "t9",
    mainText: "¡Hola!",
  },
  text_10: {
    id: "t10",
    mainText: "Te hemos identificado como cliente Consubanco",
  },
  text_11: {
    id: "t11",
    mainText:
      "Con la información proporcionada encontramos que ya eres cliente de Consubanco. Esto ayudará a que tu proceso sea más breve.",
  },
  text_12: {
    id: "t12",
    mainText: "¡Ups!",
  },
  text_13: {
    id: "t13",
    mainText: "El CURP obtenido es inválido",
  },
  text_14: {
    id: "t14",
    mainText:
      "Tras revisar la información extraída de tu INE, no fue posible localizar tu CURP en los registros de RENAPO (Registro Nacional de Población). Para continuar con tu proceso es necesario contactar a un Ejecutivo.",
  },
  text_15: {
    id: "t15",
    mainText: "Entendido",
  },
  text_16: {
    id: "t16",
    mainText: "Llamar a un Ejecutivo",
  },
  text_17: {
    id: "t17",
    mainText: "Terminar",
  },
  text_18: {
    id: "t18",
    mainText: "Continuar",
  },
  text_19: {
    id: "t19",
    mainText: "¡Gracias por tu interés!",
  },
  text_20: {
    id: "t20",
    mainText: "Hemos detectado que ya tienes una cuenta con nosotros.",
  },
  text_21: {
    id: "t21",
    mainText:
      "Por el momento no es posible continuar con la apertura de cuenta, para más información comunícate al 8008003224.",
  }
};

export default listText;
