import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  processId: "",
  name1: "",
  name2: "",
  dateBirth: "",
  placeBirth: "",
  gender: "",
  nationality: "",
  curp: "",
  phone: "",
  email: "",
  rfc: "",
  bpId: "",
  clientId: "",
};

export const personByBpIdSlice = createSlice({
  name: "personByBpIdData",
  initialState,
  reducers: {
    personByBpIdData: (state, action) => {
      const {
        processId,
        name1,
        name2,
        dateBirth,
        placeBirth,
        gender,
        nationality,
        curp,
        phone,
        email,
        rfc,
        bpId,
        clientId,
      } = action.payload;
      state.processId = processId;
      state.name1 = name1;
      state.name2 = name2;
      state.dateBirth = dateBirth;
      state.placeBirth = placeBirth;
      state.gender = gender;
      state.nationality = nationality;
      state.curp = curp;
      state.phone = phone;
      state.email = email;
      state.rfc = rfc;
      state.bpId = bpId;
      state.clientId = clientId;
    },
  },
});

export const { personByBpIdData } = personByBpIdSlice.actions;
export default personByBpIdSlice.reducer;
