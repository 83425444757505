import Button from "../atoms/button";
import Image from "../atoms/image";
import Sheet from "react-modal-sheet";
import ic_xclose from "../../../infraestructure/assets/icons/ic_xclose.svg";
import Input from "../molecules/input";
import * as yup from "yup";
import { useFormik } from "formik";
import { store } from "../../redux/store";
import { allownumber } from "../../utils/patterns";

const BottomSheetFieldModification = ({
  title,
  textButton,
  conditionShowInputs,
  isOpen,
  onClickClose,
  changeValueFunction,
}: any) => {
  const state = store.getState();
  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
    },
    onSubmit: (values) => console.log(values),
    validationSchema: yup.object({
      phone: yup
        .string()
        .required("Campo requerido")
        .min(10, "Debe contener 10 dígitos")
        .max(10, "Debe contener 10 dígitos")
        .test("match", "El teléfono debe ser diferente al anterior", function (value) {
          return value !== state.client.dataPersist.phone;
        }),
      email: yup
        .string()
        .required("Campo requerido")
        .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "Correo electrónico no válido")
        .test("match", "El correo debe ser diferente al anterior", function (value) {
          return value !== state.client.dataPersist.email;
        }),
    }),
  });

  const onSubmit = (data: any) => {
    changeValueFunction(data.email != "" ? formik.values.email : formik.values.phone);
  };

  return (
    <>
      <Sheet isOpen={isOpen} onClose={onClickClose} detent="content-height">
        <Sheet.Container>
          <Sheet.Content>
            <Sheet.Scroller>
              <div style={{ height: 200 }} className="p-3">
                <div className="position-relative">
                  <strong className={"fw-bold"} style={{ color: "#00254A", fontSize: "17px" }}>
                    {title}
                  </strong>
                  <Image
                    srcImage={ic_xclose}
                    className={"position-absolute end-0"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={{ cursor: "pointer" }}
                    onClick={onClickClose}
                  />
                </div>
                {conditionShowInputs.phoneValidate ? (
                  <>
                    <div className="py-4">
                      <Input
                        className={`form-control ${formik.errors.phone ? "is-invalid" : ""}`}
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder=""
                        value={formik.values.phone}
                        error={formik.errors.phone ? formik.errors.phone : ""}
                        text="Teléfono"
                        onChange={(e: any) => {
                          if (allownumber.test(e.target.value)) {
                            formik.setFieldValue("phone", e.target.value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel=""
                        htmlFor=""
                      ></Input>
                    </div>
                    <Button
                      text={textButton}
                      divClassName={"d-grid gap-2 pb-4"}
                      buttonClassName={
                        "btn-bg-primary text-white w-100 place-content-center align-self-center"
                      }
                      spinner={false}
                      onClick={() => onSubmit(formik.values)}
                      disabled={formik.errors.phone || formik.values.phone === ""}
                    />
                  </>
                ) : (
                  <>
                    <div className="py-4">
                      <Input
                        className={`form-control ${formik.errors.email ? "is-invalid" : ""}`}
                        type={"email"}
                        id={"email"}
                        name={"email"}
                        placeholder=""
                        value={formik.values.email}
                        error={formik.errors.email ? formik.errors.email : ""}
                        text={"Correo electrónico"}
                        onChange={(e: any) => formik.setFieldValue("email", e.target.value)}
                        onBlur={formik.handleBlur}
                        src={""}
                        disabled={false}
                        style={undefined}
                        displayLabel={""}
                        htmlFor={""}
                      ></Input>
                    </div>
                    <Button
                      text={textButton}
                      divClassName={"d-grid gap-2 pb-4"}
                      buttonClassName={
                        "btn-bg-primary text-white w-100 place-content-center align-self-center"
                      }
                      spinner={false}
                      onClick={() => onSubmit(formik.values)}
                      disabled={formik.errors.email || formik.values.email === ""}
                    />
                  </>
                )}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClickClose} />
      </Sheet>
    </>
  );
};

export default BottomSheetFieldModification;
