import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fiscalFormation: {
    valueRFC: "",
    homoclave: "",
    addressMatch: "",
    fiscalRegimeKey: "",
    regimenFiscal: "",
    confirmAddress: {
      zipCode: "",
      state: "",
      stateId: "",
      stateCRM: "",
      suburbs: "",
      township: "",
      townshipId: "",
      street: "",
      outsideNumber: "",
      internalNumber: "",
    },
  },
  laborInformation: {
    occupation: "",
    occupationKey: "",
    activities: "",
    activitiesKey: "",
    sector: "",
    sectorKey: ""
  },
  useAccountManagement: {
    accountType: "",
    myAccount: "",
    accountFamily: "",
    accountUse: "",
    accountOrigin: "",
    accountMoney: 0,
    surve: [],
  },
  vulnerabilityQuestionnaire: {
    surve: []
  }
};

export const dataSlice = createSlice({
  name: "clientInformation",
  initialState,
  reducers: {
    onFiscalFormation: (state, action) => {
      state.fiscalFormation = { ...action.payload };
    },
    onLaborInformation: (state, action) => {
      state.laborInformation = { ...action.payload };
    },
    onUseAccountManagement: (state, action) => {
      state.useAccountManagement = { ...action.payload };
    },
    onVulnerabilityQuestionnaire: (state, action) => {
      state.vulnerabilityQuestionnaire = { ...action.payload };
    },
  },
});

export const { onFiscalFormation, onLaborInformation, onUseAccountManagement, onVulnerabilityQuestionnaire } = dataSlice.actions;

export default dataSlice.reducer;
