import ITextarea from "../../interfaces/textarea";
import "../../styles/input.scss";
import { Label } from "../atoms/labelV2";

export default function Textarea(props: ITextarea) {
  const htmlForLabel = props.htmlFor ? props.htmlFor : props.name;
  const displayLabel = props.displayLabel ? props.displayLabel : "";
  return (
    <>
      <form className="form-floating" autoComplete="off">
        <textarea
          id={props.id}
          className={`form-control ` + props.className}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          maxLength={props.maxLength}
          minLength={props.minLength}
          style={{
            padding: "25px 10px",
            ...props.style,
            borderRadius: 4,
          }}
        />
        <Label
          text={props.text ? props.text : ""}
          htmlFor={htmlForLabel}
          className={displayLabel}
        ></Label>
        {props.error && <small className="block dangerous-red">{props.error}</small>}
      </form>
    </>
  );
}
