/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import Top from "../components/templates/top";
import listText from "../utils/dictionaries/dictionary-black-lists";
import Image from "../components/atoms/image";
import hexagonExclamation from "../assets/icons/hexagon-exclamation.svg";
import hiHand from "../assets/icons/ic_hi_hand_blue.svg";
import Title from "../components/atoms/title";
import Paragraph from "../components/atoms/paragraph";
import Button from "../components/atoms/button";
import { useNavigate } from "react-router-dom";
import PersonalDataService from "../services/personalData.service";
import { DataINEuseCase } from "../../application/use-cases/dataINE.usecase";
import { store } from "../redux/store";
import MessagesReactModal from "../components/templates/messages-react-modal";
import hexagonExclamationThin from "../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../assets/icons/ic_xclose.svg";
import Loader from "../components/atoms/loader";
import { useDispatch } from "react-redux";
import { changeIsClient } from "../redux/slices/dataReducer";

const BlackLists = () => {
  const [num, setNum] = useState(Number);
  const navigate = useNavigate();
  const getBlackLists = new PersonalDataService();
  const useCaseGetBlackLists = new DataINEuseCase(getBlackLists);
  const state = store.getState();
  const [modalShow, setModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();

  interface romanType {
    [key: number]: string;
  }

  const textPrimaryButton: romanType = {
    400.5: listText.text_15.mainText,
    400.6: listText.text_16.mainText,
    400.7: listText.text_16.mainText,
    400.8: listText.text_16.mainText,
    400.9: listText.text_18.mainText,
    400.1: listText.text_16.mainText,
    400.13: listText.text_16.mainText
  };

  const textParagraph: romanType = {
    400.5: listText.text_4.mainText,
    400.6: listText.text_6.mainText,
    400.7: listText.text_7.mainText,
    400.8: listText.text_8.mainText,
    400.9: listText.text_11.mainText,
    400.1: listText.text_14.mainText,
    400.13: listText.text_21.mainText
  };

  const textSubtitle: romanType = {
    400.5: listText.text_3.mainText,
    400.6: listText.text_5.mainText,
    400.7: listText.text_5.mainText,
    400.8: listText.text_5.mainText,
    400.9: listText.text_10.mainText,
    400.1: listText.text_13.mainText
  };

  const textTitle: romanType = {
    400.5: listText.text_2.mainText, //UIF
    400.6: listText.text_2.mainText, //PEP
    400.7: listText.text_2.mainText, //DetectArt
    400.8: listText.text_2.mainText, //Producto Vector
    400.9: listText.text_9.mainText, //Cliente CSB
    400.1: listText.text_12.mainText, //CURP no válido
    400.13: listText.text_19.mainText, //ya es cliente CSB y cuenta de débito Consubanco asociadas
  };

  const next = async (value: string) => {
    if (value === "entendido") {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      [400.5].includes(num)
        ? navigate("/")
        : [400.9].includes(num)
        ? dispatch(changeIsClient({ isClient: true })) && navigate("/FiscalInformationClient")
        : "";
    } else {
      [400.13].includes(num)
        ? window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "CLOSED_WEBVIEW",
              data: { processId: state.client.clientPersist.uuid },
            }),
          )
        : navigate("/");
    }
  };

  const functionProcessModal = () => {
    setShowLoader(false);
    setModalShow(false);
    navigate("/DataConfirmation");
  };

  const blackLists = async () => {
    setShowLoader(true);
    const response = await useCaseGetBlackLists.getBlackLists(state.client.clientPersist.uuid);
    if (response.error !== null) {
      setNum(Number(response.error.errorCode));
      if (
        response.status === 500 ||
        response.error.errorCode === "404.n4_personal_data.1" ||
        response.status === 401
      ) {
        setModalShow(true);
      } else {
        setShowLoader(false);
      }
    } else {
      setNum(response.status);
      navigate("/FiscalInformationClient");
    }
  };

  useEffect(() => {
    blackLists();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={""}
          displayProgressBar={"d-none"}
          displayLeftImageTitle={"d-none"}
          classTitleImage={"d-none"}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"d-none"}
          styleProgressBar={{}}
          classTitleAndImage={"d-none"}
          styleTitleAndImage={{}}
          valueProgressBar={""}
          onClickGoBackIcon={undefined}
          comeFrom={"/BlackLists"}
        />
        {showLoader ? (
          <Loader />
        ) : (
          <div className="p-3">
            <div className="text-center">
              <div className="mt-5 mb-4">
                <Image
                  srcImage={[400.9].includes(num) ? hiHand : hexagonExclamation}
                  className={"bg-blue-light p-1"}
                  alt={""}
                  dataBsDismiss={""}
                  styleImg={{ borderRadius: "100%" }}
                  onClick={undefined}
                ></Image>
              </div>

              <Title
                text={textTitle[num]}
                className={"fw-bold dark-blue-title"}
                styleTitle={{ fontSize: "28px" }}
              />

              <div className="mb-4_3 mt-4_3">
                <Paragraph
                  text={textSubtitle[num]}
                  className={"fs-5 lh-base dark-blue-title"}
                  styleParagraph={undefined}
                />
              </div>

              <div className="mb-4_3">
                <Paragraph
                  text={textParagraph[num]}
                  className={"fs-6 lh-base text-start dark-blue-title"}
                  styleParagraph={undefined}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {showLoader || num === 500 ? (
        <Loader />
      ) : (
        <div className="footer--pin">
          {
            [400.5, 400.9].includes(num) ? 
            (
              <Button
                text={textPrimaryButton[num]}
                img={""}
                classNameImg={""}
                divClassName={""}
                buttonClassName="btn-bg-primary text-white w-100 place-content-center align-self-center"
                spinner={false}
                onClick={() => next("entendido")}
                disabled={false}
              />
            ) : (
              <a
                target="_blank"
                rel=""
                href="tel:8008003224"
                className={`toggle-btn-dark-blue text-white btn-bg-primary align-self-center align-items-center`}
                style={{ 
                  fontSize: 16,
                  fontWeight: 600,
                  display: "flex",
                  width: "100%",
                  padding: "10px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "8px",
                  height: "40px"
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.905 3.75C4.95 4.4175 5.0625 5.07 5.2425 5.6925L4.3425 6.5925C4.035 5.6925 3.84 4.74 3.7725 3.75H4.905ZM12.3 12.765C12.9375 12.945 13.59 13.0575 14.25 13.1025V14.22C13.26 14.1525 12.3075 13.9575 11.4 13.6575L12.3 12.765ZM5.625 2.25H3C2.5875 2.25 2.25 2.5875 2.25 3C2.25 10.0425 7.9575 15.75 15 15.75C15.4125 15.75 15.75 15.4125 15.75 15V12.3825C15.75 11.97 15.4125 11.6325 15 11.6325C14.07 11.6325 13.1625 11.4825 12.3225 11.205C12.2475 11.175 12.165 11.1675 12.09 11.1675C11.895 11.1675 11.7075 11.2425 11.5575 11.385L9.9075 13.035C7.785 11.9475 6.045 10.215 4.965 8.0925L6.615 6.4425C6.825 6.2325 6.885 5.94 6.8025 5.6775C6.525 4.8375 6.375 3.9375 6.375 3C6.375 2.5875 6.0375 2.25 5.625 2.25Z"
                    fill="#ffffff"
                  />
                </svg>
                <p>{textPrimaryButton[num]}</p>
              </a>
            )
          }

          <Button
            text={listText.text_17.mainText}
            img={""}
            classNameImg={""}
            divClassName={`my-4 ${
              [400.6, 400.7, 400.8, 400.1, 400.13].includes(num) ? "d-block" : "d-none"
            }`}
            buttonClassName={
              "toggle-btn-dark-blue bg-white btn-outline-btn-dark-blue w-100 fw-semibold place-content-center align-self-center align-items-center"
            }
            spinner={false}
            onClick={() => next("terminar")}
            disabled={false}
          />
        </div>
      )}
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle="¡Ups lo sentimos!"
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => functionProcessModal()}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default BlackLists;
