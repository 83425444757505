import fetchBxiApiGateway from "./fetchBxiApiGateway";

export const fetchMaxMinByDeposit = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/contracts/getMaximumDeposit`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};
