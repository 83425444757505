import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useRedux } from "../../hooks/useRedux";
// Atoms
import Button from "../../components/atoms/buttonV2";
import Title from "../../components/atoms/titleV2";
import Paragraph from "../../components/atoms/paragraph";
import Loader from "../../components/atoms/loader";
// Templates
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Redux
import { onBeneficiaries } from "../../redux/slices/beneficiaries";
// hooks
import { useApi } from "../../hooks/useApi";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useParentesco } from "../../hooks/useParentesco";
// Components
import { AddBeneficiaries } from "./item";
// Services
import BeneficiariesService from "../../services/apiBeneficiaries.service";
// Intefaces
import { IBeneficiarie } from "../../interfaces/api/IBeneficiaries";
// Utils
import { map2Obj } from "./utils";
import { getUid, removeFileItem, updateFileList } from "../../utils/utils";
// Schema
import validationSchema from "./schema";
// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
// Dictionaries
import Text from "../../utils/dictionaries/dictionary-laborInformation-client";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";



const { saveBeneficiaries } = new BeneficiariesService();

const BeneficiariesClient = () => {
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();
  const { onClickContinue } = useFollowLater();
  const [isModaError, setIsModalError] = useState<boolean>(false);
  const { dataParentesco, errorParentesco, isLoadingParentesco } = useParentesco();
  const {
    data: {
      clientPersist: { uuid },
      beneficiaries: beneficiariesSate,
      dataPersist,
    },
    dispatch,
  } = useRedux();
  const {
    data: dataSaveBeneficiaries,
    error: errorSaveBeneficiaries,
    execute: executeSaveBeneficiaries,
    isLoading: isLoadingBeneficiaries,
  } = useApi<{ message: string }, { beneficiaries: IBeneficiarie[]; processId: string }>(
    saveBeneficiaries,
  );

  const [beneficiaries, setBeneficiaries] = useState<any[]>(beneficiariesSate.data);

  const formik = useFormik({
    initialValues: {
      beneficiaries: beneficiariesSate.data,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (errorParentesco && !isLoadingParentesco) {
      setIsModalError(!isModaError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorParentesco, isLoadingParentesco]);

  const onAdd = () => {
    const newData = updateFileList(
      {
        id: getUid(),
        name: "",
        fatherLastName: "",
        motherLastName: "",
        relationship: "",
        relationshipKey: "",
        date: {
          day: "",
          month: "",
          year: "",
        },
        profitPercentage: "",
        sameAddress: "",
        confirmAddress: {
          postalCode: "",
          state: "",
          stateId: "",
          suburbs: "",
          township: "",
          townshipId: "",
          street: "",
          outsideNumber: "",
          internalNumber: "",
        },
      },
      formik.values.beneficiaries,
    );
    formik.setValues({ beneficiaries: newData });
    setBeneficiaries((__oldData) => newData);
  };

  const onDelete = (data: any) => {
    const newData = removeFileItem(data, formik.values.beneficiaries);
    formik.setValues({ beneficiaries: newData });
    setBeneficiaries((__oldData) => newData);
    dispatch(onBeneficiaries(newData));
  };

  const btnDisabled = (): boolean => {
    let retVal = false;

    if (
      formik.values.beneficiaries[beneficiaries.length - 1].name &&
      formik.values.beneficiaries[beneficiaries.length - 1].fatherLastName &&
      formik.values.beneficiaries[beneficiaries.length - 1].relationship &&
      formik.values.beneficiaries[beneficiaries.length - 1].date.day &&
      formik.values.beneficiaries[beneficiaries.length - 1].date.month &&
      formik.values.beneficiaries[beneficiaries.length - 1].date.year &&
      formik.values.beneficiaries[beneficiaries.length - 1].sameAddress && 
      !formik.values.beneficiaries[beneficiaries.length - 1]?.date?.monthError && 
      !formik.values.beneficiaries[beneficiaries.length - 1]?.date?.dayError
    ) {
      if (formik.values.beneficiaries[beneficiaries.length - 1].sameAddress === "no") {
        if (formik.values.beneficiaries[beneficiaries.length - 1].confirmAddress.postalCode) {
          retVal = false;
        } else {
          return true;
        }
      } else {
        retVal = false;
      }
    } else {
      retVal = true;
    }
    return retVal;
  };

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "BeneficiariesClient",
        step: "CONTINUE_AFTER",
        data: { beneficiaries: formik.values.beneficiaries, dataPersist },
      },
    });
    dispatch(onBeneficiaries(formik.values.beneficiaries));
  };

  const onOk = () => {
    if (formik.values.beneficiaries.length > 1) {
      setLoadingRedirect(true);
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_beneficiarios_N4,
        {
          "Número_de_beneficiarios_ingresados" :  formik.values.beneficiaries.length + 1
        }
      );
      setTimeout(() => {
        navigate("/BeneficiariesCheckClient");
        dispatch(onBeneficiaries(formik.values.beneficiaries));
        setLoadingRedirect(false);
      }, 1000);
    } else {
      setLoadingRedirect(true);
      formik.setFieldValue(`beneficiaries[0].profitPercentage`, 100);
      const dataFormBeneficiaries: IBeneficiarie[] = formik.values.beneficiaries.map((key) => ({
        ...map2Obj({ ...key, profitPercentage: 100 }),
      }));
      executeSaveBeneficiaries({ beneficiaries: dataFormBeneficiaries, processId: uuid });
      setLoadingRedirect(false);
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_beneficiarios_N4,
        {
          "Número_de_beneficiarios_ingresados" :  formik.values.beneficiaries.length 
        }
      );
    }
  };

  useEffect(() => {
    if (!dataSaveBeneficiaries && errorSaveBeneficiaries && !isLoadingBeneficiaries) {
      setIsModalError(true);
    } else {
      if (dataSaveBeneficiaries && !errorSaveBeneficiaries && !isLoadingBeneficiaries) {
        const dataFormBeneficiaries: IBeneficiarie[] = formik.values.beneficiaries.map((key) => ({
          ...map2Obj({ ...key, profitPercentage: 100 }),
        }));
        setTimeout(() => {
          navigate("/LivenessCheck");
          onClickContinue({
            data: { beneficiaries: dataFormBeneficiaries, dataPersist },
            processId: uuid,
            resource: "BeneficiariesClient",
            step: "CONTINUE",
          });
          dispatch(onBeneficiaries(formik.values.beneficiaries));
        }, 1000);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSaveBeneficiaries, errorSaveBeneficiaries && !isLoadingBeneficiaries]);

  const functionProcessModal = () => {
    setIsModalError(false);
    window.location.reload();
  };

  if (isLoadingBeneficiaries) {
    return <Loader />;
  }

  return (
    <>
      <Top
        titleHeader={"Apertura de cuenta"}
        subTitle={"Beneficiarios"}
        displayLeftImageTitle={"d-none"}
        displayProgressBar={"d-block"}
        classHeader={"mt-2"}
        classProgressBar={"mt-3"}
        valueProgressBar={"60%"}
      />
      <div
        className="container_form mb-4_3"
        style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 34 }}
      >
        <>
          <div className=" mb-4">
            <Paragraph
              styleParagraph={{
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
              }}
              text={
                "En caso de fallecimiento la(s) persona(s) que elijas serán las autorizadas para la entrega del dinero acumulado que tengas en tu cuenta."
              }
              className={"container_form__title"}
            />
          </div>
          <div className="accordion" id="accordionExample">
            {formik.values.beneficiaries.length === 1 ? (
              <>
                <Title
                  text={`Beneficiario 1`}
                  className={"fw-bold"}
                  styleTitle={{ color: "#00376F" }}
                />
                <AddBeneficiaries
                  formik={formik}
                  i={0}
                  dataParentesco={dataParentesco}
                  codePostRedux={beneficiariesSate.data[0].confirmAddress.postalCode}
                />
              </>
            ) : (
              <>
                {formik.values.beneficiaries.map((memo, i) => {
                  return (
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id={`beneficiaries-${i}`}
                        style={{ display: "flex" }}
                      >
                        <button
                          className="accordion-button left-auto accordion-button-arrow-done"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-beneficiaries-${memo.id}`.replace(/\s+/g, "")}
                          aria-expanded={"true"}
                          aria-controls={`collapse-beneficiaries-${memo.id}`.replace(/\s+/g, "")}
                        >
                          <Title
                            text={`Beneficiario ${i + 1}`}
                            className={"fw-bold"}
                            styleTitle={{ color: "#00376F" }}
                          />
                        </button>
                        <button className="btn-light accordion-button-actions" type="button">
                          {i === formik.values.beneficiaries.length - 1 ? (
                            <span
                              onClick={() => onDelete(memo)}
                              className="accordion-button-arrow-red"
                            >{`Eliminar`}</span>
                          ) : (
                            <span
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse-beneficiaries-${memo.id}`.replace(
                                /\s+/g,
                                "",
                              )}
                              aria-expanded={
                                i === formik.values.beneficiaries.length - 1 ? "true" : "false"
                              }
                              aria-controls={`collapse-beneficiaries-${memo.id}`.replace(
                                /\s+/g,
                                "",
                              )}
                              className="accordion-button-arrow-blue"
                            >{`Editar`}</span>
                          )}
                        </button>
                      </div>
                      <div
                        id={`collapse-beneficiaries-${memo.id}`.replace(/\s+/g, "")}
                        className={`accordion-collapse collapse collapsed ${
                          i === formik.values.beneficiaries.length - 1 ? "show" : ""
                        }`}
                        aria-labelledby={`collapse`.replace(/\s+/g, "")}
                        data-bs-parent="#accordionExample"
                      >
                        <AddBeneficiaries
                          formik={formik}
                          i={i}
                          dataParentesco={dataParentesco}
                          codePostRedux={beneficiariesSate.data[i]?.confirmAddress.postalCode}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      </div>
      <div style={{ padding: 16 }}>
        <Button
          text={"Agregar beneficiario"}
          disabled={btnDisabled()}
          classNameImg={""}
          buttonClassName={`toggle-btn-dark-blue ${
            btnDisabled() ? `footer--pin__button_blue-disabled` : ``
          } bg-white btn-outline-btn-dark-blue  w-100 fw-semibold place-content-center align-self-center align-items-center btn-outline-dark-blue`}
          spinner={false}
          onClick={onAdd}
        />
      </div>
      <Footer
        activeSpinner={isLoadingBeneficiaries}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => onOk()}
        labelButtonPrimary={Text.button.labelButtonPrimary}
        labelButtonSecundary={Text.button.labelButtonSecundary}
        onClickButtonLink={onClickButtonLink}
        position="relative"
      />
      <MessagesReactModal
        show={isModaError}
        onHide={() => setIsModalError(false)}
        textTitle="¡Ups lo sentimos!"
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setIsModalError(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default BeneficiariesClient;
