const listText = {
  text_0: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_1: {
    id: "t1",
    mainText: "Captura comprobante de domicilio",
  },
  text_2: {
    id: "t2",
    mainText:
      "Los comprobantes validos para este proceso son: TELMEX y CFE , el que elijas debe tener una vigencia de 3 meses de antigüedad a partir de su expedición.",
  },
  text_3: {
    id: "t3",
    mainText: "Comprobante subido con éxito",
  },
  text_4: {
    id: "t4",
    mainText: "Comprobante capturado con éxito",
  },
  text_5: {
    id: "t5",
    mainText: "Capturar comprobante",
  },
  text_6: {
    id: "t6",
    mainText: "Subir un archivo",
  },
  text_7: {
    id: "t7",
    mainText: "¿Qué tipo de archivos?",
  },
  text_8: {
    id: "t8",
    mainText: "PDF, BMP, TIFF, GIF, PNG, EPS, SVG.",
  },
  text_9: {
    id: "t9",
    mainText: "Capturar de nuevo",
  },
  text_10: {
    id: "t10",
    mainText: "Elegir un archivo diferente",
  },
  text_11: {
    id: "t11",
    mainText: "No fue posible validar tu comprobante, vuelve a intentarlo.",
  },
  text_12: {
    id: "t12",
    mainText: "No fue posible cargar tu documento intenta de nuevo",
  },
  text_13: {
    id: "t13",
    mainText: "",
  },
  text_14: {
    id: "t14",
    mainText: "",
  },
  text_15: {
    id: "t15",
    mainText: "",
  },
  text_16: {
    id: "t16",
    mainText: "",
  },
  text_17: {
    id: "t16",
    mainText: "",
  },
  text_18: {
    id: "t18",
    mainText: "",
  },
  text_19: {
    id: "t19",
    mainText: "",
  },
  text_20: {
    id: "t20",
    mainText: "",
  },
  text_21: {
    id: "t21",
    mainText: "",
  },
  text_22: {
    id: "t22",
    mainText: "",
  },
  text_23: {
    id: "t23",
    mainText: "Continuar",
  },
  text_24: {
    id: "t24",
    mainText: "Seguir más tarde",
  },
};

export default listText;
