import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import dataReducer from "./slices/dataReducer";
import navigateReducer from "./slices/navigateReducer";
import clientReducer from "./slices/userReducer";
import otpReducer from "./slices/otpReducer";
import confirmDataReducer from "./slices/confirmDataReducer";
import contractSignatureReducer from "./slices/contractSignatureReducer";
import clientInformation from "./slices/clientInformation";
import beneficiaries from "./slices/beneficiaries";
import contactNavigateReducer from "./slices/contactNavigateReducer";
import authTokenReducer from "./slices/authTokenReducer";
import videoRecordingReducer from "./slices/videoRecordingReducer";
import PdfViewerReducer from "./slices/pdfViewerReducer";
import pdfsListReducer from "./slices/pdfsListReducer";
import personByBpIdReducer from "./slices/personByBpIdReducer";

let persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  clientPersist: clientReducer,
  dataPersist: dataReducer,
  navigatePersist: navigateReducer,
  otpPersist: otpReducer,
  confirmData: confirmDataReducer,
  contractSignaturePersist: contractSignatureReducer,
  clientInformation: clientInformation,
  beneficiaries: beneficiaries,
  contactNavigatePersist: contactNavigateReducer,
  authTokenPersist: authTokenReducer,
  videoRecordingPersist: videoRecordingReducer,
  pdfViewerPersist: PdfViewerReducer,
  pdfsListPersist: pdfsListReducer,
  personByBpIdPersist: personByBpIdReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: {
    client: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
