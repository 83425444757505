import { IFollowLater } from "../../interfaces/api/IProcessContracts";
import fetchBxiApiGateway from "./fetchBxiApiGateway";

export const fetchCancelProcess = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/cancel/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchFinishProcess = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/finish/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchDataProcess = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/process-id/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchSaveDataProcess = (data: IFollowLater, processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: data,
  });
};

export const fetchGetBiometricIneData = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/biometric-validation/get-by-processId/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchPostCreateAccount = (processId: string) => {
  return fetchBxiApiGateway({
    url: `processes/create-account`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchGetGenerateAccount = (processId: string) => {
  return fetchBxiApiGateway({
    url: `contracts/generate-account`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};
