import React, { memo, useRef, useLayoutEffect } from "react";
import usePrevious from "../../utils/hooks/usePrevious";
import { SingleOTPInputProps } from "../../interfaces/singleOTPInputProps";

export function SingleOTPInputComponent(props: SingleOTPInputProps) {
  const { focus, autoFocus, classError, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus, classError]);

  return (
    <div className={"divClass " + classError}>
      <input ref={inputRef} {...rest} />
    </div>
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
