import React from "react";
import { Link } from "react-router-dom";

import ILinksList from "../../interfaces/link-list";
import Title from "../atoms/title";

const LinkList = ({ dataItems, onClick }: ILinksList) => {
  if (!Array.isArray(dataItems)) return <></>;
  return (
    <>
      {dataItems.map((dataItem: any) => {
        return (
          <div key={dataItem.id} className="pt-2">
            <Title text={dataItem.text} className={"fw-bold"} styleTitle={{ fontSize: "18px" }} />
            {dataItem.listItems.map((childItem: any) => {
              return (
                <div key={childItem.id} className="pt-3" onClick={() => onClick && onClick(childItem)}>
                  <Link
                    to={childItem.url}
                    download={childItem.idDownload}
                    target={childItem.target}
                    className={childItem.className}
                    style={childItem.styleLink}
                    state={childItem.state}
                  >
                    {childItem.text}
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default LinkList;
