import { SetStateAction,  useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
// Atoms
import Title from "../../components/atoms/title";
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import BottomSheetMessage from "../../components/templates/bottom-sheet-message";
// Moleculas
import Input from "../../components/molecules/input";
import TitleAndImage from "../../components/molecules/title-and-image";
import { RadioToggleButton } from "../../components/molecules/radio-toggle-buttons";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-account-opening";
// Services
import OtpUtilService from "../../services/otpUtil.service";
// Use-Case
import { ValidateCodeUseCase } from "../../../application/use-cases/validateCode.usecase";
// Redux
import { addData } from "../../redux/slices/dataReducer";
import { store } from "../../redux/store";
import { addNavigate } from "../../redux/slices/navigateReducer";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Utils
import { allownumber } from "../../utils/patterns";
import { dateISO } from "../../utils/helpers";
// Assets
import ic_phone_dark_gray from "../../assets/icons/ic_phone_dark_gray.svg";
import ic_email_dark_gray from "../../assets/icons/ic_email_dark_gray.svg";
import ic_check_circle_green from "../../assets/icons/ic_check_circle_green.svg";
import ic_dangerous_circle_red from "../../assets/icons/ic_dangerous_circle_red.svg";
import ic_check_circle_white from "../../assets/icons/ic_check_circle_white.svg";
import ic_dangerous_circle_white from "../../assets/icons/ic_dangerous_circle_white.svg";
import circleInfoSharpRegular from "../../assets/icons/circle-info-sharp-regular.svg";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";


const otpService = new OtpUtilService();
const usecaseOtp = new ValidateCodeUseCase(otpService);

const AccountOpening = () => {
  const { onClickContinue } = useFollowLater();
  const state = store.getState();
  const navigate = useNavigate();
  const [radioToggleValue, setRadioToggleValue] = useState(listText.text_4.mainText);
  const [showSpinner, setShowSpinner] = useState(false);
  const [valueClient, setvalueClient] = useState(false);
  const [isOpenBottonSheet, setOpenBottonSheet] = useState(false);

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: state.client.confirmData.phone,
      email: state.client.confirmData.email,
    },
    onSubmit: (values) => console.log(values),
    validationSchema: yup.object().shape({
      phone: yup.string().min(10, ({ min }) => `Debe contener ${min} caracteres`).nonNullable(),
      email: yup.string().matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Correo electrónico no válido').nonNullable()
    })
  });

  const radioChangeHandler = (e: { target: { value: SetStateAction<string> } }) => {
    setRadioToggleValue(e.target.value);
    if (e.target.value !== listText.text_3.mainText) {
      setvalueClient(false);
    } else {
      setvalueClient(true);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setShowSpinner(true);

    dispatch(
      addData({
        processId: state.client.clientPersist.uuid,
        isClient: valueClient,
        phone: formik.values.phone,
        email: formik.values.email,
        emailVerify: false,
        creationDate: dateISO(),
      }),
    );

    const responseOtp = await usecaseOtp.sendOtp(
      {
        phone: formik.values.phone,
        email: formik.values.email,
        channel: "EMAIL",
        date: dateISO(),
      },
      state.client.clientPersist.uuid,
    );

    if (responseOtp.error !== null) {
      setModalShow(true);
    } else {
      dispatch(
        addNavigate({
          progressBarPercentage: "15%",
          editableValue: formik.values.email,
          typeValue: "mail",
          textButton: "Continuar",
          nextContinue: "/IneConsent",
          comeFrom: "AccountOpening",
          editableReceiverOtp: true,
        }),
      );
      onClickContinue({
        data: { dataPersist: formik.values },
        processId: state.client.clientPersist.uuid,
        resource: "AccountOpening",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Información_contacto_N4,
        { "Cliente_CSB": radioToggleValue, "Telefono": formik.values.phone, "Correo": formik.values.email }
      )

      navigate("/ValidateCode");
    }
    setShowSpinner(false);
  };

  const functionProcessModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={"Apertura de cuenta"}
          subTitle={listText.text_1.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={""}
          classTitleImage={"ms-2"}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"10%"}
          comeFrom={"/AccountOpening"}
          onClickGoBackIcon={() => navigate("/InformationRequirements")}
        />
        <div className="p-3">
          <Paragraph
            text={listText.text_2.mainText}
            className={`fs-6 mb-0 lh-base ${state.client.clientPersist.bpId === null ? "" : "d-none"
              }`}
            styleParagraph={undefined}
          />
          <div
            className={`${state.client.clientPersist.bpId === null ? "mb-3" : "d-none"}`}
          >
            <RadioToggleButton
              id={listText.text_3.mainText}
              text={listText.text_3.mainText}
              value={listText.text_3.mainText}
              type={"radio"}
              name={listText.text_3.mainText}
              onChange={radioChangeHandler}
              isSelected={radioToggleValue === listText.text_3.mainText}
              className="radioToggleButton me-3"
              icon={
                radioToggleValue === listText.text_3.mainText
                  ? ic_check_circle_white
                  : ic_check_circle_green
              }
              displayImg="d-none"
              disabledInput={undefined}
              divStyle={undefined}
              labelStyle={undefined}
              onClick={undefined}
            />
            <RadioToggleButton
              id={listText.text_4.mainText}
              text={listText.text_4.mainText}
              value={listText.text_4.mainText}
              type={"radio"}
              name={listText.text_4.mainText}
              onChange={radioChangeHandler}
              isSelected={radioToggleValue === listText.text_4.mainText}
              className="radioToggleButton"
              icon={
                radioToggleValue === listText.text_4.mainText
                  ? ic_dangerous_circle_white
                  : ic_dangerous_circle_red
              }
              displayImg="d-none"
              disabledInput={undefined}
              divStyle={undefined}
              labelStyle={undefined}
              onClick={undefined}
            />
          </div>
          <TitleAndImage
            srcImage={circleInfoSharpRegular}
            titleText={listText.text_18.mainText}
            displayLeftImageTitle={""}
            classTitle={"m-2"}
            styleTitle={{
              color: "#005CB9",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClickTitleImgeDiv={() => setOpenBottonSheet(true)}
            divClass={`mt-3 mb-3 ${state.client.clientPersist.bpId === null ? "" : "d-none"
              }`}
          />
          {state.client.clientPersist.bpId === null ? <hr /> : <></>}
          <div className={`${state.client.clientPersist.bpId === null ? "my-3" : "mb-4"}`}>
            <Title
              text={listText.text_5.mainText}
              className={"fs-6 fw-bold"}
              styleTitle={undefined}
            />
          </div>
          <div className="mb-4">
            <Input
              className={`form-control ${formik.errors.phone ? "is-invalid" : ""} ${state.client.clientPersist.bpId === null ? "" : "dark-gray"}`}
              type="tel"
              id="phone"
              name="phone"
              placeholder=""
              value={formik.values.phone}
              error={formik.errors.phone}
              text={listText.text_7.mainText}
              onChange={(e: any) => {
                if (allownumber.test(e.target.value)) {
                  formik.setFieldValue("phone", e.target.value);
                }
              }}
              src={ic_phone_dark_gray}
              disabled={state.client.clientPersist.bpId === null ? false : true}
              style={undefined}
              displayLabel={`${state.client.clientPersist.bpId === null ? "" : "dark-gray"}`}
              htmlFor="phone"
              maxLength={10}
            />
          </div>
          <div className="mb-4">
            <Input
              className={`form-control ${formik.errors.email ? "is-invalid" : ""}`}
              type="email"
              id="email"
              name="email"
              placeholder=""
              value={formik.values.email}
              error={formik.errors.email}
              text={listText.text_8.mainText}
              onChange={(e: any) => {
                formik.setFieldValue("email", e.target.value)
              }}
              src={ic_email_dark_gray}
              disabled={false}
              style={undefined}
              displayLabel=""
              htmlFor="email"
            />
          </div>
          <div className="mb-4_3">
            <Paragraph
              text={listText.text_9.mainText}
              className={"fs-6 dark-gray-paragraph lh-base"}
              styleParagraph={undefined}
            />
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <Button
          text={listText.text_10.mainText}
          divClassName={"d-grid gap-2"}
          buttonClassName={`${showSpinner ? "pe-none" : ""
            } btn-bg-primary text-white w-100 place-content-center align-self-center`}
          spinner={showSpinner}
          onClick={(d: any) => onSubmit(d)}
          disabled={
            formik.errors.phone ||
            formik.values.phone == "" ||
            formik.errors.email ||
            formik.values.email == ""
          }
        />
      </div>
      <BottomSheetMessage
        imageInside={undefined}
        title={listText.text_18.mainText}
        text={listText.text_19.mainText}
        isOpen={isOpenBottonSheet}
        onClickClose={() => setOpenBottonSheet(false)}
        styleMainDiv={{ height: "auto" }}
      />
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle="¡Ups lo sentimos!"
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default AccountOpening;
