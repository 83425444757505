import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// Atoms
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import Paragraph from "../../components/atoms/paragraph";
import Loader from "../../components/atoms/loader";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Moleculas
import Checkbox from "../../components/molecules/checkbox";
import TitleAndImage from "../../components/molecules/title-and-image";
// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import insertDriveFileBgw from "../../assets/icons/insert_drive_file_bgw.svg";
// Services
import DocsUtilService from "../../services/docsUtil.service";
import AcceptAuthorizeUtilService from "../../services/acceptAuthorizeUtil.service";
import ProcessContracts from "../../services/processContracts.service";
// Use-Case
import { classAcceptAuthorizeUseCase } from "../../../application/use-cases/acceptAuthorize.usecase";
import { BiometricValidation } from "../../../application/use-cases/biometric-validation.usecase";
import { DocsUseCase } from "../../../application/use-cases/docs.usecase";
// Utils
import { dateISO } from "../../utils/helpers";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Redux
import { store } from "../../redux/store";
import { pdfViewerData } from "../../redux/slices/pdfViewerReducer";
import { pdfsListData } from "../../redux/slices/pdfsListReducer";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-identification-questionnaire";
// Amplitude
import { AmplitudEventName } from "../../utils/amplitude/types";
import { trackAnalyticsEvent } from "../../utils/amplitude";



const caseDocs = new DocsUtilService();
const useCaseDocs = new DocsUseCase(caseDocs);

const caseServiceAutorice = new AcceptAuthorizeUtilService();
const useCaseAutorize = new classAcceptAuthorizeUseCase(caseServiceAutorice);

const getBiometricIneData = new ProcessContracts();
const useCaseGetBiometricIne = new BiometricValidation(getBiometricIneData);

const IdentificationQuestionnaire = () => {
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : "";
  const { onClickContinue } = useFollowLater();
  const navigate = useNavigate();
  const state = store.getState();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [checked, setChecked] = useState(false);
  const [, setSeconds] = useState<number>(0);
  const [toggle] = useState<boolean>(false);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(true);
  const [biometricIneStatus, setBiometricIneStatus] = useState("");
  const [retries, setRetries] = useState<any>();
  const [showLoader, setShowLoader] = useState(false);
  const [viewPdf, setViewPdf] = useState<boolean >(false);
  const dispatch = useDispatch();

  const continueProcess = async () => {
    const saveAcceptanceDoc = await useCaseAutorize.saveDocumentationAcceptances({
      processId: state.client.clientPersist.uuid,
      phone: state.client.dataPersist.phone,
      latitude: "",
      longitude: "",
      acceptanceDate: dateISO(),
    });
    if (saveAcceptanceDoc.error != null) {
      setModalShow(true);
    } else {
      onClickContinue({
        data: {
          dataPersist: state.client.dataPersist,
        },
        processId: state.client.clientPersist.uuid,
        resource: "IdentificationQuestionnaire",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Contestar_Cuestionario_Identificación_N4,
        { "Reviso_cuestionario": viewPdf ? "SI" :  "NO",
          "Fecha": moment(new Date()).format('ll'),
          "Hora": moment(new Date()).format('LTS'),
          "Geolocalizacion":access_geoposition
        }
      )
      navigate("/ContractCondition");
    }
  };

  const acceptAuthorize = (value: boolean) => {
    if (!somethingWentWrong) setChecked(!value);
  };

  const getBiometricIne = async () => {
    const response = await useCaseGetBiometricIne.getBiometricIne(state.client.clientPersist.uuid);
    setBiometricIneStatus(response.data.status);
    setRetries(response.data.retries);
  };

  useEffect(() => {
    ref.current = setInterval(() => {
      if (toggle) setSeconds((state) => state + 1);

      const condition = localStorage.getItem("conditionLiveness") === "false" ? false : true;

      if (!condition && biometricIneStatus !== null) {
        navigate("/LivenessCheck");
        localStorage.setItem("biometricValidation", biometricIneStatus);
        localStorage.setItem("retries", retries);
      }
    });

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, biometricIneStatus, retries]);

  useEffect(() => {
    getBiometricIne();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getidentificationQuestionnaire = async () => {
    setShowLoader(true);
    if (
      state.client.pdfsListPersist.pdfIdentificationQuestionnaire === "" ||
      state.client.pdfsListPersist.pdfIdentificationQuestionnaire === undefined
    ) {
      const doc = await useCaseDocs.getIdentificationQuestionnaireDoc(
        state.client.clientPersist.uuid,
      );
      if (doc.error !== null) {
        setShowLoader(false);
        setSomethingWentWrong(true);
        setModalShow(true);
        return;
      }
      dispatch(
        pdfsListData({
          pdfIdentificationQuestionnaire: doc.data.url,
          pdfsContractCondition: [],
        }),
      );
      dispatch(
        pdfViewerData({
          urlPdf: doc.data.url,
          comeFrom: "/IdentificationQuestionnaire",
          title: "Cuestionario de identificación",
        }),
      );
    } else {
      dispatch(
        pdfViewerData({
          urlPdf: state.client.pdfsListPersist.pdfIdentificationQuestionnaire,
          comeFrom: "/IdentificationQuestionnaire",
          title: "Cuestionario de identificación",
        }),
      );
    }

    setSomethingWentWrong(false);
    setShowLoader(false);
  };

  useEffect(() => {
    getidentificationQuestionnaire();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        step: "CONTINUE_AFTER",
        data: {
          dataPersist: state.client.dataPersist,
        },
        processId: state.client.clientPersist.uuid,
        resource: "IdentificationQuestionnaire",
      },
    });
  };

  const onViewPdf = () => {
    setViewPdf(true);
    navigate("/PdfViewer")
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={"ms-2"}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"70%"}
          comeFrom={"/IdentificationQuestionnaire"}
          onClickGoBackIcon={undefined}
        />
        <div className="p-3">
          <Paragraph text={listText.text_3.mainText} className={""} styleParagraph={undefined} />
          <Paragraph text={listText.text_4.mainText} className={""} styleParagraph={undefined} />
          <div className={`text-center mt-5`} style={{ backgroundColor: "#F7F7F7" }}>
            <TitleAndImage
              srcImage={insertDriveFileBgw}
              titleText={listText.text_5.mainText}
              displayLeftImageTitle={""}
              classTitle={"m-2"}
              styleTitle={{
                color: "#004A94",
                fontSize: "18px",
                cursor: "pointer",
                fontWeight: 400,
                fontFamily: "Roboto",
              }}
              onClickTitleImgeDiv={() => onViewPdf()}
              divClass={"py-3"}
            />
          </div>
          <div className="mt-5">
            <Checkbox
              text={listText.text_6.mainText}
              htmlFor={"flexCheckDefault"}
              classNameLabel={"fs-6"}
              classCheckbox={"form-check my-4_2"}
              checked={checked}
              onChange={() => {
                acceptAuthorize(checked);
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <Button
          text={listText.text_7.mainText}
          divClassName={"d-grid gap-2"}
          buttonClassName={`${
            !checked ? `button-disabled` : `button-enabled`
          } w-100 place-content-center align-self-center button-enabled`}
          spinner={activeSpinner}
          onClick={() => {
            continueProcess();
          }}
          disabled={!checked}
          dataBsDismiss={undefined}
        />
        <div className="text-center mt-3">
          <Link
            text={listText.text_8.mainText}
            url={null}
            styleLink={{
              color: "#5A6172",
              lineHeight: "16px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => onClickButtonLink()}
            className={""}
          />
        </div>
      </div>
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_24.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_25.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              setModalShow(false);
              getidentificationQuestionnaire();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => {
          setModalShow(false);
          getidentificationQuestionnaire();
        }}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_26.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_27.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
        backdrop={"static"}
      />
    </>
  );
};

export default IdentificationQuestionnaire;
