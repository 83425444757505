import { useState } from "react";
import Sheet from "react-modal-sheet";
import { Label } from "../../atoms/label";
import Image from "../../atoms/image";

import ic_xclose from "../../../assets/icons/ic_xclose.svg";
import { ISelectData, ISelectSheet } from "../../../interfaces/select-sheet";

import "./style.css";

const SelectSheet = ({ title, data, onChange, styleMainDiv, placeholder }: ISelectSheet) => {
  const [memo, setSetValue] = useState<ISelectData>({ label: "", value: "" });
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);

  const onClick = (memo: ISelectData) => {
    setSetValue(memo);
    onChange(memo?.value);
    setIsOpenSelect(!isOpenSelect);
  };

  return (
    <>
      <div
        className="select-bottom-sheet-message mt-3 form-select"
        aria-label="select default"
        onClick={() => setIsOpenSelect(!isOpenSelect)}
      >
        {memo?.value ? memo.label : placeholder}
      </div>
      <Sheet
        isOpen={isOpenSelect}
        onClose={() => setIsOpenSelect(!isOpenSelect)}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Content>
            <Sheet.Scroller>
              <div className="p-3" style={styleMainDiv}>
                <div className="position-relative">
                  <strong className={"fw-bold"} style={{ color: "#00254A", fontSize: "17px" }}>
                    {title}
                  </strong>
                  <Image
                    srcImage={ic_xclose}
                    className={"position-absolute end-0"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={{ cursor: "pointer" }}
                    onClick={() => setIsOpenSelect(!isOpenSelect)}
                  />
                </div>
                <div style={{ marginTop: 36 }}>
                  {data.map((memo) => {
                    return (
                      <div className="select-option-sheet" onClick={() => onClick(memo)}>
                        {memo.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsOpenSelect(!isOpenSelect)} />
      </Sheet>
    </>
  );
};

export default SelectSheet;
