import { API_GATEWAY } from "../../env";

export var Config = (function () {
  const Authorization = localStorage.getItem("access_token")
    ? JSON.parse(localStorage.getItem("access_token") || "")
    : "";
  const access_uuid = localStorage.getItem("access_uuid")
    ? JSON.parse(localStorage.getItem("access_uuid") || "")
    : "";
  const access_callerip = localStorage.getItem("access_callerip")
    ? JSON.parse(localStorage.getItem("access_callerip") || "")
    : "";
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : "";
  const access_useragent = localStorage.getItem("access_useragent")
    ? JSON.parse(localStorage.getItem("access_useragent") || "")
    : "";
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.

  const DeviceKeyIdentifier = "dnTDuwQzkgM5F1IeW6AMFOLOwPWf18p7";

  const PublicFaceScanEncryptionKey =  "-----BEGIN PUBLIC KEY-----\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3irIdgBSVvXMWiVzWzSC\n" +
  "5zMDye1VHfInpnQaQxChfydzGW3VVnUhTVCYFnGbJqmGBmHYBN+6ssbHXE5gKg5R\n" +
  "lJSiRmqY+pb5EohJnWWsFOeZuMFK0QjiAQjVGQjktzcknm/KPCh95j6r7sSrwdp9\n" +
  "KRDf1euT32USmhUwF55TJkw2yH1RYf+5uid9p1AShYQDHuDvwKXD04L5FslI26fn\n" +
  "GxifxwR0pA0CGsjcdv/8u/rgpDZuFDgK1G7rn4gdVQTqnX4wzqk3FWL7MWqJh8aL\n" +
  "6hdZCKYcL0PanLhY2mVfRerVx1bnPGardn9nQ+G2YliM/B/A8X4kM871G34mbnF7\n" +
  "awIDAQAB\n" +
  "-----END PUBLIC KEY-----" 


  var ProductionKey = {}

  const BaseURL = `${API_GATEWAY}/utils-commons/facetec`
  const urlGeneral = `${API_GATEWAY}`

  const AuthToken = Authorization;
  const ProcessID = access_uuid;
  const GeoPosition = access_geoposition;
  const CallerIP = access_callerip;

  // -------------------------------------
  // REQUIRED
  // The FaceScan Encryption Key you define for your application.
  // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.

  return {
    DeviceKeyIdentifier,
    BaseURL,
    PublicFaceScanEncryptionKey,
    ProductionKey,
    AuthToken,
    ProcessID,
    GeoPosition,
    CallerIP,
    urlGeneral
  };

})();