import * as React from "react";

interface ITitle {
  text?: string;
  className?: string;
  styleTitle?: React.CSSProperties;
}

const Title = ({ text, className, styleTitle }: ITitle) => {
  return (
    <strong className={className} style={styleTitle}>
      {text}
    </strong>
  );
};

export default Title;
