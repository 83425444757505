import { useState } from "react";
import Top from "../../components/templates/top";
import listText from "../../utils/dictionaries/dictionary-suggestion-capture-receipt";
import { useLocation, useNavigate } from "react-router-dom";
import Image from "../../components/atoms/image";
import POA_CFE from "../../assets/images/POA_CFE.png";
import POA_TELMEX from "../../assets/images/POA_TELMEX.svg";

import Paragraph from "../../components/atoms/paragraph";
import InformationSection from "../../components/molecules/information-section";
import { Footer } from "../../components/templates";

interface IStringOptions {
  [key: string]: any[];
}

interface IJSXOptions {
  [key: string]: any;
}

const textSelectType: IStringOptions = {
  POA_CFE: listText.arrayTextsCFE,
  POA_TELMEX: listText.arrayTextTelmex,
};
const iconSelectType: IJSXOptions = {
  POA_CFE: POA_CFE,
  POA_TELMEX: POA_TELMEX,
};

const SuggestionCaptureReceipt = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "/SuggestionCaptureReceipt",
        step: "CONTINUE_AFTER",
        data: {},
      },
    });
  };

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_0.mainText}
          subTitle={listText.text_1.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={""}
          classTitleImage={"ms-2"}
          classHeader={"mt-2 mb-3"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"45%"}
          comeFrom={"/SuggestionCaptureReceipt"}
          onClickGoBackIcon={() => navigate("/ProofAddressCondition")}
        />
        <div className="p-3">
          <div className="text-center">
            <Image
              srcImage={iconSelectType[state.card_type.full_name]}
              className={""}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            />
          </div>
          <div
            style={{
              height: 80,
              display: "flex",
              width: "100%",
              padding: 16,
              alignItems: "flex-start",
              gap: 8,
              background: "#F7F7F7",
            }}
            className="my-3"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22ZM12.9375 7V7.9375V12.3125V13.25H11.0625V12.3125V7.9375V7H12.9375ZM11.0625 16.375V14.5H12.9375V16.375H11.0625Z"
                fill="#E42A23"
              />
            </svg>

            <Paragraph
              text={"No fue posible validar tu comprobante, vuelve a intentarlo."}
              className={""}
              styleParagraph={undefined}
            />
          </div>
          <div className="">
            {textSelectType[state.card_type.full_name].map((item: any) => {
              return (
                <InformationSection
                  key={item.description}
                  titleText={item.title}
                  paragraphText={item.description}
                  srcImage={item.icon}
                  columnA={"col-1 p-0"}
                  columnB={"col-11 align-self-center"}
                  titleClass={""}
                  paragraphClass={"ms-1 mb-0 mt-auto"}
                  styleParagraph={{ fontSize: "14px" }}
                  classNameImg={"pt-0"}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer
        activeSpinner={false}
        disabledButton={false}
        onClickButtonPrimary={() => navigate("/ProofAddressCondition")}
        labelButtonPrimary={"Reintentar"}
        labelButtonSecundary={listText.text_24.mainText}
        onClickButtonLink={() => followLaterAction()}
      />
    </>
  );
};

export default SuggestionCaptureReceipt;
