/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import moment from "moment";
// Atoms
import Image from "../../components/atoms/image";
import Loader from "../../components/atoms/loader";
import Paragraph from "../../components/atoms/paragraph";
// Templastes
import { Footer } from "../../components/templates/footer";
import Top from "../../components/templates/topV2";
// Molecules
import TitleAndImage from "../../components/molecules/title-and-image"
// Redux
import { store } from "../../redux/store";
import { videoRecordingData } from "../../redux/slices/videoRecordingReducer";
// hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Services
import VideoCaptureUtilService from "../../services/viedoCaptureUtil.service";
// Use-Case
import { VideoCaptureUseCase } from "../../../application/use-cases/videoCapture.usecase";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-video-capture-condition";
// Assets
import redCircleExclamationSharpSolid from "../../assets/icons/red-circle-exclamation-sharp-solid.svg";
import ic_selfie_blue from "../../assets/icons/ic_selfie_blue.svg";
import ic_face_retouching_natural from "../../assets/icons/ic_face_retouching_natural.svg";
import ic_no_sunglasses_blue from "../../assets/icons/ic_no_sunglasses_blue.svg";
import ic_document_id_blue from "../../assets/icons/ic_document_id_blue.svg";
// Styles
import "./style.scss";
import { AmplitudEventName } from "../../utils/amplitude/types";
import { trackAnalyticsEvent } from "../../utils/amplitude";

const queryClient = new QueryClient();
const caseVideo = new VideoCaptureUtilService();
const useCaseVideo = new VideoCaptureUseCase(caseVideo);

type TStateVideo = "default" | "pristine" | "working" | "error" | "success" | "failed";

export default function VideoCaptureCondition() {
  return (
    <QueryClientProvider client={queryClient}>
      <Video />
    </QueryClientProvider>
  );
}

function Video() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onClickContinue } = useFollowLater();
  const [isLoadingStartVideo, setIsLoadingStartVideo] = useState<boolean>(false);
  const [stateVideo, setStateVideo] = useState<TStateVideo>("default");
  const [loaderActive, setLoaderActive] = useState(false);
  const [count, setCount] = useState(1);

  const {
    client: {
      videoRecordingPersist,
      clientPersist: { uuid },
      dataPersist,
      confirmData,
    },
  } = store.getState();

  const onFollowLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "VideoCaptureCondition",
        step: "CONTINUE_AFTER",
        data: { dataPersist: dataPersist, confirmData: confirmData },
      },
    });
  };

  const {
    isLoading,
    error,
    data: dataVideoWf,
  } = useQuery({
    queryKey: ["videoWorkflows", videoRecordingPersist.uuid],
    queryFn: async (queryParams) => {
      if (videoRecordingPersist.uuid) {
        const dataQueryFn = await useCaseVideo.getVideoRecordingWorkflows(
          uuid,
          videoRecordingPersist.uuid,
          "GET",
        );
        return dataQueryFn;
      }
    },
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (dataVideoWf?.data) {
      setLoaderActive(true);
      switch (dataVideoWf?.data.result.status) {
        case "success":
          if (dataVideoWf?.data.result.steps[0].payload.match_face_result.success) {
            onClickContinue({
              data: { dataPersist: dataPersist, confirmData: confirmData },
              processId: uuid,
              resource: "VideoCaptureCondition",
              step: "CONTINUE",
            });
            trackAnalyticsEvent(
              AmplitudEventName.Realizar_videograbación_N4,
              { "Captura_realizada": "Exitosa",
                "Fecha": moment(new Date()).format('ll'),
                "Hora": moment(new Date()).format('LTS'),
                "No_de_intentos": count,
              }
            )
            navigate("/CustomizableViewOk");
          }
          break;
        case "pristine":
          setStateVideo("pristine");
          break;
        case "working":
          setStateVideo("working");
          break;
        case "error":
        case "failed":
          setStateVideo("error");
          trackAnalyticsEvent(
            AmplitudEventName.Realizar_videograbación_N4,
            { "Captura_realizada": "No exitosa",
              "Fecha": moment(new Date()).format('ll'),
              "Hora": moment(new Date()).format('LTS'),
              "No_de_intentos": count,
            }
          )
          break;
      }
      setLoaderActive(false);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVideoWf]);

  const onGenerateVideoUrlFunction = async () => {
    setIsLoadingStartVideo(true);
    const responseVideoRecording = await useCaseVideo.postVideoRecording(uuid);
    // Condition retoma
    if (responseVideoRecording.error !== null) {
      setCount(count + 1);
      dispatch(videoRecordingData({ ...responseVideoRecording.data, resource: "" }));
      onClickContinue({
        data: {
          dataPersist: { ...dataPersist },
          videoRecordingData: { ...responseVideoRecording.data },
        },
        processId: uuid,
        resource: "VideoCaptureCondition",
        step: "CONTINUE",
      });
    }
    trackAnalyticsEvent(
      AmplitudEventName.Realizar_videograbación_N4,
      { "Captura_realizada": "No exitosa",
        "Fecha": moment(new Date()).format('ll'),
        "Hora": moment(new Date()).format('LTS'),
        "No_de_intentos":count  + 1,
      }
    )
    setIsLoadingStartVideo(false);
  };

  useEffect(() => {
    const osType = getMobileOperatingSystem();

      if (osType === "IOS") {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "OPEN_URL",
            data: { url: videoRecordingPersist.public_url },
          }),
        );
      } else {
        const idVideo = document.getElementById("videoId");
        idVideo?.click();
      }
  }, [videoRecordingPersist.public_url]);

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    // Windows Phone debe ir primero porque su UA tambien contiene "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "IOS";
    }
    return "desconocido";
  };

  if (loaderActive) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayLeftImageTitle={"d-none"}
          displayProgressBar={"d-block"}
          classHeader={"mt-2"}
          classProgressBar={"mt-3"}
          valueProgressBar={"90%"}
        />
        <main className="container__videocapturecondition">
          <div className="text-center mb-4_3 mt-4">
            <Image
              srcImage={ic_selfie_blue}
              className={"ic_selfie_blue"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            />
          </div>
          {["working", "pristine"].includes(stateVideo) && (
            <>
              <div className="col-12 mb-4_9">
                <Paragraph
                  text={
                    "Por requisito regulatorio de la Comisión Nacional Bancaria de Valores (CNBV) es necesario realizar la validación de tu identidad, la cual consta de comparar tu rostro contra la fotografía en tu INE."
                  }
                  className={"fs-7 mb-0 lh-base dark-blue-title"}
                  styleParagraph={undefined}
                />
              </div>
              <div className="d-flex align-items-center pb-3">
                <div className="col-1">
                  <Image
                    srcImage={ic_face_retouching_natural}
                    className={"h-32 w-32"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={undefined}
                    onClick={undefined}
                  />
                </div>
                <div className="col-11 mt-2">
                  <Paragraph
                    text={"Mantén tu rostro visible y colócate en un lugar iluminado."}
                    className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                    styleParagraph={undefined}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center pb-3">
                <div className="col-1">
                  <Image
                    srcImage={ic_no_sunglasses_blue}
                    className={"h-32 w-32"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={undefined}
                    onClick={undefined}
                  />
                </div>
                <div className="col-11 mt-2">
                  <Paragraph
                    text={"No uses accesorios como lentes, gorras o cubrebocas."}
                    className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                    styleParagraph={undefined}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-4_9">
                <div className="col-1">
                  <Image
                    srcImage={ic_document_id_blue}
                    className={"h-32 w-32"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={undefined}
                    onClick={undefined}
                  />
                </div>
                <div className="col-11 mt-2">
                  <Paragraph
                    text={
                      "Muestra tu identificación oficial (INE) por el Anverso y Reverso, ésta no debe cubrir tu rostro"
                    }
                    className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                    styleParagraph={undefined}
                  />
                </div>
              </div>
            </>
          )}
          {stateVideo === "error" && (
            <div
              className="container__videocapturecondition--error"
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <TitleAndImage
                srcImage={redCircleExclamationSharpSolid}
                titleText={listText.text_3.mainText}
                displayLeftImageTitle={""}
                classTitle={"ms-2"}
                styleTitle={{ fontSize: 16, fontWeight: 400 }}
                onClickTitleImgeDiv={undefined}
                divClass={""}
              />
            </div>
          )}
        </main>
      </div>
      <a
        id="videoId"
        target="_blank"
        rel="noreferrer"
        href={videoRecordingPersist.public_url}
        style={{
          color: "#fcfcfc",
          fontSize: 16,
          fontWeight: 600,
          borderColor: "#004A94",
          display: "none",
        }}
        onClick={videoRecordingData}
      />
      <Footer
        componentsTop={
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            target="_blank"
            rel=""
            href="tel:8008003224"
            className={`toggle-btn-dark-blue bg-white btn-outline-btn-dark-blue   align-self-center align-items-center btn-outline-dark-blue`}
            style={{ color: "#004A94", fontSize: 16, fontWeight: 600, borderColor: "#004A94" }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.905 3.75C4.95 4.4175 5.0625 5.07 5.2425 5.6925L4.3425 6.5925C4.035 5.6925 3.84 4.74 3.7725 3.75H4.905ZM12.3 12.765C12.9375 12.945 13.59 13.0575 14.25 13.1025V14.22C13.26 14.1525 12.3075 13.9575 11.4 13.6575L12.3 12.765ZM5.625 2.25H3C2.5875 2.25 2.25 2.5875 2.25 3C2.25 10.0425 7.9575 15.75 15 15.75C15.4125 15.75 15.75 15.4125 15.75 15V12.3825C15.75 11.97 15.4125 11.6325 15 11.6325C14.07 11.6325 13.1625 11.4825 12.3225 11.205C12.2475 11.175 12.165 11.1675 12.09 11.1675C11.895 11.1675 11.7075 11.2425 11.5575 11.385L9.9075 13.035C7.785 11.9475 6.045 10.215 4.965 8.0925L6.615 6.4425C6.825 6.2325 6.885 5.94 6.8025 5.6775C6.525 4.8375 6.375 3.9375 6.375 3C6.375 2.5875 6.0375 2.25 5.625 2.25Z"
                fill="#004A94"
              />
            </svg>
            <p>{listText.text_5.mainText}</p>
          </a>
        }
        activeSpinner={isLoadingStartVideo}
        disabledButton={isLoadingStartVideo}
        onClickButtonPrimary={() => onGenerateVideoUrlFunction()}
        labelButtonPrimary={listText.text_6.mainText}
        labelButtonSecundary={listText.text_7.mainText}
        onClickButtonLink={() => onFollowLaterAction()}
      />
    </>
  );
}
