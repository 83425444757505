import IGeneralInformationTextService from "../../domain/services/IGeneralInformationText.service";

export class GeneralInformationTextUseCase {
  constructor(private generalInformationTextService: IGeneralInformationTextService) {}
  public async informationText() {
    try {
      const response = await this.generalInformationTextService.getInformationText();
      if (!response.data) return { data: [], error: "Ocurrio un error" };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: [], error: `Ocurrio un error ${error}` };
    }
  }
  public async ineConsent() {
    try {
      const response = await this.generalInformationTextService.getIneConsent();
      if (!response.data.content) return { data: null, error: "Ocurrio un error" };

      return { data: response.data.content, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
  public async getTermsAndConditions() {
    try {
      const response = await this.generalInformationTextService.getTermsAndConditions();
      if (!response.data.content) return { data: "", error: "Ocurrio un error" };

      return { data: response.data.content, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
  public async getPrivacyNotices() {
    try {
      const response = await this.generalInformationTextService.getPrivacyNotices();
      if (!response.data.content) return { data: "", error: "Ocurrio un error" };

      return { data: response.data.content, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
  public async listContractDocs(uuid: string) {
    try {
      const response = await this.generalInformationTextService.getListContractDocs(uuid);
      if (!response.data) return { data: [], error: "Ocurrio un error" };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: [], error: `Ocurrio un error ${error}` };
    }
  }
}
