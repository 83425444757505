import IDocsService from "../../domain/services/IDocs.service";
import { digitalContract, caratulaInversiones, identificationQuestionnaire } from "./api/apiDocs";

export default class DocsUtilService implements IDocsService {
  async getDigitalContract(): Promise<any> {
    return await digitalContract();
  }
  async getCaratulaInversiones(): Promise<any> {
    return await caratulaInversiones();
  }
  async getidentificationQuestionnaire(processId: string): Promise<any> {
    return await identificationQuestionnaire(processId);
  }
}
