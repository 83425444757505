import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progressBarPercentage: "",
  editableValue: "",
  typeValue: "",
  textButton: "",
  nextContinue: "",
  comeFrom: "",
  isReturn: false,
  editableReceiverOtp: true,
};

export const navigateSlice = createSlice({
  name: "navigate",
  initialState,
  reducers: {
    addNavigate: (state, action) => {
      const {
        progressBarPercentage,
        editableValue,
        typeValue,
        textButton,
        nextContinue,
        comeFrom,
        isReturn,
        editableReceiverOtp,
      } = action.payload;
      state.progressBarPercentage = progressBarPercentage;
      state.editableValue = editableValue;
      state.typeValue = typeValue;
      state.textButton = textButton;
      state.nextContinue = nextContinue;
      state.comeFrom = comeFrom;
      state.isReturn = isReturn;
      state.editableReceiverOtp = editableReceiverOtp;
    },
    changeNavigate: (state, action) => {
      const {
        progressBarPercentage,
        editableValue,
        typeValue,
        textButton,
        nextContinue,
        comeFrom,
        isReturn,
        editableReceiverOtp,
      } = action.payload;
      state.progressBarPercentage = progressBarPercentage;
      state.editableValue = editableValue;
      state.typeValue = typeValue;
      state.textButton = textButton;
      state.nextContinue = nextContinue;
      state.comeFrom = comeFrom;
      state.isReturn = isReturn;
      state.editableReceiverOtp = editableReceiverOtp;
    },
    changeNextContinue: (state, action) => {
      const { nextContinue, comeFrom } = action.payload;
      state.nextContinue = nextContinue;
      state.comeFrom = comeFrom;
    },
    changeEditableValue: (state, action) => {
      const { editableValue } = action.payload;
      state.editableValue = editableValue;
    },
  },
});

export const { addNavigate, changeNavigate, changeNextContinue, changeEditableValue } =
  navigateSlice.actions;
export default navigateSlice.reducer;
