import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";
import { useRedux } from "../../../hooks/useRedux";
import UtilsCommonsService from "../../../services/utilsCommons.service";
import { IVulnerabilityQuestionnaireResp } from "../../../interfaces/api/IUtilsCommons";

const { getVulnerabilityQuestionnaire } = new UtilsCommonsService();

export function useDinamicSurvey() {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();

  const {
    data: dataDinamicSurvey = [],
    error: errorDinamicSurvey,
    execute: execGetDinamicSurvey,
    isLoading: isLoadingDinamicSurvey,
  } = useApi<IVulnerabilityQuestionnaireResp[], { processId: string }>(getVulnerabilityQuestionnaire);

  useEffect(() => {
    execGetDinamicSurvey({ processId: uuid });
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataDinamicSurvey,
    isLoadingDinamicSurvey,
    errorDinamicSurvey,
  };
}