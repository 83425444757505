import ILinksSection from "../../interfaces/links-section";
import { Link } from "react-router-dom";

const LinksSection = ({
  url,
  isDownload,
  target,
  className,
  styleLink,
  state,
  text,
  divStyleLinks,
  divClassLinks,
}: ILinksSection) => {
  return (
    <>
      <div className={divClassLinks} style={divStyleLinks}>
        <Link
          to={url}
          download={isDownload}
          target={target}
          className={className}
          style={styleLink}
          state={state}
        >
          {text}
        </Link>
      </div>
    </>
  );
};

export default LinksSection;
