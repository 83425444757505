/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import Button from "../../components/atoms/buttonV2";
import SlideButton from "../../components/atoms/swipeable-button";
import ProcessContracts from "../../services/processContracts.service";
import { useApi } from "../../hooks/useApi";
import { useRedux } from "../../hooks/useRedux";
import MessagesModal from "./messages-modal";
import "./style.scss";
import { useModalError } from "../../hooks/useModalError";

const { getCancelProcess } = new ProcessContracts();

const FinishProcess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState<boolean>(false);
  const { modaComponente, setIsModalError } = useModalError();
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const {
    data: isDataProcess,
    error,
    execute,
    isLoading,
  } = useApi<string, { processId: string }>(getCancelProcess);

  useEffect(() => {
    if (!isLoading && isDataProcess && !error) {
      setIsShow(true);
    }
  }, [isLoading, isDataProcess, error]);

  useEffect(() => {
    if (!isLoading && !isDataProcess && error) {
      setIsModalError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  const onSlideDone = () => {
    execute({ processId: uuid });
  };

  const okModal = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_uuid");
    localStorage.removeItem("access_reload");
    localStorage.removeItem("persist:root");
    navigate("/");
    window.location.reload();
    setIsShow(false);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "CLOSED_WEBVIEW", data: { processId: uuid } }),
      );
    }
  };

  return (
    <>
      <Top
        titleHeader={"Apertura de cuenta"}
        subTitle={"Terminar proceso"}
        displayLeftImageTitle={"d-none"}
        displayProgressBar={"d-none"}
      />

      <main className="container_confirmation-finish-process ">
        <p>
          {
            "Si decides abandonar tu proceso de apertura, todo tu progreso se perderá y tendrás que comenzar desde el principio para realizar una nueva solicitud."
          }
        </p>
        <p>{"Desliza la barra para confirmar que deseas abandonar el proceso."}</p>
        <Button
          text={"Continuar proceso"}
          divClassName={"d-grid gap-2  w-100"}
          buttonClassName={`footer--pin__button_primary  w-100 place-content-center align-self-center`}
          onClick={(e) => navigate(state.resource)}
          spinner={false}
          dataBsDismiss={undefined}
        />
      </main>

      <Footer
        activeSpinner={false}
        disabledButton={false}
        componentsBottom={
          <SlideButton
            onSlideDone={onSlideDone}
            overlayText={"Desliza para abandonar"}
            mainText={"Desliza para abandonar"}
          />
        }
      />
      <MessagesModal
        show={isShow}
        acceptSpinner={false}
        onClickAccept={okModal}
        onClickClose={() => setIsShow(false)}
        onHide={() => setIsShow(false)}
      />
      {modaComponente}
    </>
  );
};

export default FinishProcess;
