import * as Yup from "yup";

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>
  Yup.object({
    accountType: Yup.string().required(""),
    myAccount: Yup.string().required(""),
    accountFamily: Yup.string().required(""),
    accountUse: Yup.string().required(""),
    accountOrigin: Yup.string().required(""),
    accountMoney: Yup.number().required(""),
  });
