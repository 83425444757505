import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

export class S3FileUploaderService {
  ak: string = "AKIA6CTPNUSYK6FVQ5J7";
  sk: string = "pyE2hO/mikvijsRodVtME07GZJqNwGJe9x2yD8rx";
  bucket: string = "image-identy-web";
  client: S3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: this.ak,
      secretAccessKey: this.sk,
    },
  });
  uploadItemQueue: Array<UploadObject> = [];

  async upload(
    bucket: any,
    filePath: string,
    data: ArrayBuffer,
    contentType: string,
    contentEncoding: string = "base64",
  ) {
    const params: any = {
      Bucket: bucket,
      Key: filePath,
      Body: data,
      ContentEncoding: contentEncoding,
      ContentType: contentType,
    };

    return this.client.send(new PutObjectCommand(params));
  }

  async uploadImage(bucket: string, file: string, data: ArrayBuffer) {
    console.log(bucket, file, data);
    return this.upload(bucket, file, data, "image/jpeg");
  }

  async uploadFrame(file: string, data: ArrayBuffer) {
    return this.upload(file, "web-ui-images", data, "image/jpeg");
  }

  addUploadObject(frame: UploadObject) {
    this.uploadItemQueue.push(frame);
  }

  async upload_frames() {
    const uuid = uuidv4();
    for (let i = 0; i < this.uploadItemQueue.length; i++) {
      const ui = this.uploadItemQueue[i];
      const group = `${this.format(new Date(), "dd-MM-yyyy")}/${
        !sessionStorage.getItem("lu") ? "default" : sessionStorage.getItem("lu")
      }/${ui.modality}/${uuid}`;
      let buffer: any;
      if (ui.type === "IMAGE") {
        buffer = Uint8Array.from(atob(ui.data), (c) => c.charCodeAt(0));
      } else if (ui.type === "TEXT") {
        buffer = new TextEncoder().encode(ui.data);
      }

      await this.upload(
        ui.bucket,
        `${group}/${ui.file}`,
        buffer,
        ui.type = "IMAGE" ? "image/jpeg" : "text/plain",
      );
    }
    this.uploadItemQueue.length = 0;
  }

  format = function date2str(x: any, y: any) {
    var z: any = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v: any) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v: any) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  ImageDataToBase64Url(imagedata: ImageData, isFull: boolean = false) {
    const destCanvas: any = document.createElement("canvas");

    destCanvas.width = imagedata.width;
    destCanvas.height = imagedata.height;
    const quality = isFull ? 1.0 : 0.95;

    destCanvas.getContext("2d").putImageData(imagedata, 0, 0); // newCanvas, same size as source rect
    const url = destCanvas.toDataURL("image/jpeg", quality);

    destCanvas.getContext("2d").clearRect(0, 0, destCanvas.width, destCanvas.height);
    destCanvas.remove();
    return url;
  }
}
export interface UploadObject {
  file: string;
  type: string;
  modality: string;
  ts: number;
  bucket: string;
  face: string;
  data: string;
}
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // tslint:disable-next-line:one-variable-per-declaration no-bitwise
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
