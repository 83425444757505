import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signatureImageBase64: null,
};

export const contractSignatureSlice = createSlice({
  name: "contractSignatureData",
  initialState,
  reducers: {
    addContractSignatureData: (state, action) => {
      const { signatureImageBase64 } = action.payload;
      state.signatureImageBase64 = signatureImageBase64;
    },
  },
});

export const { addContractSignatureData } = contractSignatureSlice.actions;
export default contractSignatureSlice.reducer;
