import Button from "../../atoms/buttonV2";
import Link from "../../atoms/link";
import "./style.css";
import IFooter from "../../../interfaces/footer";

const Footer = (props: IFooter) => {
  return (
    <div className="footer">
      {props.componentsTop}
      {props.onClickButtonPrimary && (
        <Button
          text={props.labelButtonPrimary}
          divClassName={"d-grid gap-2"}
          buttonClassName={`footer--pin__button_primary ${
            props.disabledButton ? `footer--pin__button_primary-disabled ` : ``
          }  w-100 place-content-center align-self-center`}
          spinner={props.activeSpinner ? props.activeSpinner : false}
          onClick={props.onClickButtonPrimary}
          disabled={props.disabledButton}
          dataBsDismiss={undefined}
        />
      )}

      {props.onClickButtonLink && (
        <div className="text-center">
          <Link
            text={props.labelButtonSecundary}
            url={null}
            styleLink={{}}
            onClick={props.onClickButtonLink}
            className={"footer--pin__button_link"}
          />
        </div>
      )}
      {props.onClickButtonSecundary && (
        <Button
          text={props.labelButtonSecundary}
          divClassName={"d-grid gap-2"}
          buttonClassName={
            "toggle-btn-dark-blue bg-white btn-outline-btn-dark-blue w-100 fw-semibold place-content-center align-self-center align-items-center"
          }
          spinner={props.activeSpinner ? props.activeSpinner : false}
          onClick={props.onClickButtonSecundary}
          disabled={props.disabledButton}
          dataBsDismiss={undefined}
        />
      )}
      {props.componentsBottom}
    </div>
  );
};

export default Footer;
