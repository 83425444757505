const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "¡Felicidades!",
  },
  text_3: {
    id: "t3",
    mainText:
      "Tu cuenta y servicio de banca se registraron de forma exitosa. El contrato ha sido enviado a tu correo.",
  },
  text_4: {
    id: "t4",
    mainText:
      'Para conocer tus atributos digitales y empezar hacer transferencias vía SPEI es necesario crear tu  contraseña en Consubanco  "Tu Aplicación Móvil".',
  },
  text_5: {
    id: "t5",
    mainText: "Crear mi contraseña",
  },
  text_6: {
    id: "t6",
    mainText: "Tu cuenta y servicio de banca se crearon de forma exitosa. Para empezar hacer transferencias SPEI es necesario que vuelvas a realizar inicio de sesión en tu Aplicación.",
  },
  text_7: {
    id: "t7",
    mainText: "Entendido",
  },
  modal: {
    modalTitle: "¡Ups lo sentimos!",
    retryButton: "Reintentar",
    errorMessage1: "La aplicación no está respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
    errorMessage2: "Vuelve a intentar si el error persiste."
  }
};

export default listText;
