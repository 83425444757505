import IAuthenticationService from "../../domain/services/IAuthentication.service";
import {
  fetchAuthentication,
  fetchProcessesValidate,
  fetchProcessesCurrentProcessId,
} from "./api/apiAuthentication";
import { IAccount, IAccountProcess, ICurrentProcessId } from "../interfaces/api/IAccount";

export default class Authentication implements IAuthenticationService {
  async getAuthOauthToken(): Promise<IAccount> {
    return await fetchAuthentication().then((r) => r.data);
  }
  async getProcessesValidate({
    token,
    BearerToken,
  }: {
    token: string;
    BearerToken: string;
  }): Promise<IAccountProcess> {
    return await fetchProcessesValidate(token, BearerToken).then((r) => r.data);
  }
  async getProcessesCurrentProcessId({
    processId,
  }: {
    processId: string;
  }): Promise<ICurrentProcessId> {
    return await fetchProcessesCurrentProcessId(processId).then((r) => r.data);
  }
}
