import Header from "../molecules/header";
import { useNavigate } from "react-router";
import ProgressBar from "../molecules/progress-bar";
import arrow_back from "../../assets/icons/arrow_back.svg";
import TitleAndImage from "../molecules/title-and-image";
import * as React from "react";

interface ITop {
  titleHeader: string;
  subTitle?: string;
  displayProgressBar?: "d-block" | "d-none";
  displayLeftImageTitle?: "d-block" | "d-none";
  classTitleImage?: string;
  classHeader?: string;
  styleHeader?: React.CSSProperties;
  classProgressBar?: string;
  styleProgressBar?: React.CSSProperties;
  classTitleAndImage?: string;
  styleTitleAndImage?: React.CSSProperties;
  valueProgressBar?: string;
  onClickEarphonesIcon?: () => void;
  onClickGoBackIcon?: () => void;
}

const Top = ({
  titleHeader,
  subTitle,
  displayProgressBar,
  displayLeftImageTitle,
  classTitleImage,
  classHeader,
  styleHeader,
  classProgressBar,
  valueProgressBar,
  onClickEarphonesIcon,
  onClickGoBackIcon,
}: ITop) => {
  const navigate = useNavigate();
  const onClickEarphones = () => {
    alert("Llamar");
  };

  return (
    <div className="rounded-bottom-3" style={{ backgroundColor: "#00376F", padding: 16 }}>
      <Header
        style={styleHeader}
        class={classHeader ? classHeader : ""}
        title={titleHeader}
        onClickEarphonesIcon={() => navigate("/TelephoneContact")}
      />
      {displayProgressBar === "d-block" && (
        <ProgressBar
          text=""
          value={valueProgressBar ? valueProgressBar : ""}
          className={`${classProgressBar} ${displayProgressBar}`}
        />
      )}
      {subTitle && (
        <TitleAndImage
          srcImage={arrow_back}
          titleText={subTitle}
          displayLeftImageTitle={displayLeftImageTitle}
          classTitle={`fw-bold fs-10 ms-2 ${classTitleImage}`}
          styleTitle={{ color: "white" }}
          onClickGoBackIcon={onClickGoBackIcon}
          divClass={"mt-3 d-inline-flex"}
        />
      )}
    </div>
  );
};

export default Top;
