import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Title from "../../components/atoms/titleV2";
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/buttonV2";
interface IMessagesReactModal {
  show: boolean;
  onHide: () => void;
  onClickClose?: () => void;
  onClickAccept: () => void;
  acceptSpinner: boolean;
}

const MessagesModal = ({
  show,
  onHide,
  onClickClose,
  onClickAccept,
  acceptSpinner,
}: IMessagesReactModal) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <div style={{ padding: 23 }}>
          <div
            className="my-4"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <svg
              width="88"
              height="89"
              viewBox="0 0 88 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="88" height="88" rx="44" fill="#E2F0FF" />
              <path
                d="M44.0013 16.168C28.3613 16.168 15.668 28.8613 15.668 44.5013C15.668 60.1413 28.3613 72.8346 44.0013 72.8346C59.6413 72.8346 72.3346 60.1413 72.3346 44.5013C72.3346 28.8613 59.6413 16.168 44.0013 16.168ZM44.0013 67.168C31.5063 67.168 21.3346 56.9963 21.3346 44.5013C21.3346 32.0063 31.5063 21.8346 44.0013 21.8346C56.4963 21.8346 66.668 32.0063 66.668 44.5013C66.668 56.9963 56.4963 67.168 44.0013 67.168ZM57.0063 31.978L38.3346 50.6496L30.9963 43.3396L27.0013 47.3346L38.3346 58.668L61.0013 36.0013L57.0063 31.978Z"
                fill="#5A6172"
              />
            </svg>
          </div>
          <div
            className="mt-2 mx-2"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Title
              className=""
              text={"¡Ups!"}
              styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
            />
            <Paragraph
              text={
                "El teléfono que ingresaste no está asociado a un trámite en proceso. Por favor verifica que sea el correcto."
              }
              className={""}
              styleParagraph={{ fontSize: 16, color: "#00254A", fontWeight: "400", margin: 0 }}
            />
            <div style={{ width: "100%" }} className="">
              <Button
                text={"Entendido"}
                buttonClassName="button-enabled w-100 place-content-center align-self-center mt-3"
                spinner={acceptSpinner}
                onClick={onClickAccept}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MessagesModal;
