import Image from "../atoms/image";
import IInformationSection from "../../interfaces/information-section";
import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";

const InformationSection = ({
  srcImage,
  columnA,
  columnB,
  titleText,
  paragraphText,
  titleClass,
  paragraphClass,
  styleParagraph,
  classNameImg,
}: IInformationSection) => {
  return (
    <>
      <div className="row me-2 ms-0 pb-3">
        <div className={columnA}>
          <Image
            srcImage={srcImage}
            className={classNameImg}
            alt={""}
            dataBsDismiss={undefined}
            styleImg={undefined}
            onClick={undefined}
          />
        </div>
        <div className={columnB}>
          <Title text={titleText} className={titleClass} styleTitle={undefined} />
          <Paragraph
            text={paragraphText}
            className={paragraphClass}
            styleParagraph={styleParagraph}
          />
        </div>
      </div>
    </>
  );
};

export default InformationSection;
