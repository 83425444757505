/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import Button from "../../components/atoms/buttonV2";
import MessagesModal from "./messages-modal";
import ProcessContracts from "../../services/processContracts.service";
import { useApi } from "../../hooks/useApi";
import { IDataProcess } from "../../interfaces/api/IProcessContracts";
import { useRedux } from "../../hooks/useRedux";
import { useModalError } from "../../hooks/useModalError";
import { useFollowLater } from "../../hooks/useFollowLater";
import "./style.scss";

const { getDataProcess } = new ProcessContracts();

const FollowLater = () => {
  const navigate = useNavigate();

  const [isShow, setIsShow] = useState<boolean>(false);
  const { modaComponente, setIsModalError } = useModalError();
  const {
    onClickGoback,
    onClickGoOut,
    stateLocation,
    response: { isDataProcces, errorDataProcces, isLoadingDataProcces },
  } = useFollowLater();
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const {
    data: isDataProcess,
    error,
    execute,
    isLoading,
  } = useApi<IDataProcess, { processId: string }>(getDataProcess);

  useEffect(() => {
    execute({ processId: uuid });
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && !isDataProcess && error) {
      setIsModalError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  useEffect(() => {
    if (isDataProcces && !errorDataProcces && !isLoadingDataProcces) {
      setIsShow(true);
    } else if (!isDataProcces && errorDataProcces && !isLoadingDataProcces) {
      setIsModalError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataProcces, errorDataProcces, isLoadingDataProcces]);

  const okModal = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_uuid");
    localStorage.removeItem("access_reload");
    localStorage.removeItem("persist:root");
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "CLOSED_WEBVIEW", data: { processId: uuid } }),
      );
    }
    navigate("/");
    window.location.reload();
    setIsShow(false);
  };

  return (
    <>
      <Top
        titleHeader={"Apertura de cuenta"}
        subTitle={"Seguir más tarde"}
        displayLeftImageTitle={"d-none"}
        displayProgressBar={"d-none"}
      />

      <main className="container_confirmation-later-process">
        <h4>{"¡No te vayas!"}</h4>
        <p>
          {
            "Si decides abandonar tu proceso de apertura, todo tu progreso se perderá y tendrás que comenzar desde el principio para realizar una nueva solicitud."
          }
        </p>
        <p>{"Desliza la barra para confirmar que deseas abandonar el proceso."}</p>
        <div className="container_confirmation-later-process__date">
          <p>{"Fecha límite para continuar:"}</p>
          <h1>{moment(isDataProcess?.expireDate).format("LL")}</h1>
        </div>
      </main>

      <Footer
        activeSpinner={false}
        disabledButton={false}
        onClickButtonPrimary={onClickGoback}
        labelButtonPrimary={"Continuar proceso"}
        componentsBottom={
          <>
            <Button
              text={"Salir y continuar después"}
              buttonClassName={
                "toggle-btn-dark-blue bg-white btn-outline-btn-dark-blue w-100 fw-semibold place-content-center align-self-center align-items-center"
              }
              spinner={false}
              onClick={onClickGoOut}
              disabled={false}
            />
            <p className="container_confirmation-later-process__footer-text">
              {`Si quieres abandonar tu proceso completamente y no tienes planeado continuar, `}
              <a
                onClick={() => navigate("/FinishProcess", { state: { ...stateLocation } })}
                className="container_confirmation-later-process__footer-link"
              >{`presiona aquí. `}</a>
            </p>
          </>
        }
      />
      <MessagesModal
        acceptSpinner={false}
        onClickAccept={okModal}
        onClickClose={() => setIsShow(false)}
        onHide={() => setIsShow(false)}
        show={isShow}
      />
      {modaComponente}
    </>
  );
};

export default FollowLater;
