const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Valida tu identidad",
  },
  text_3: {
    id: "t3",
    mainText:
      "La validación no fue exitosa. No hemos podido obtener una validación completa, por favor vuelve a intentarlo.",
  },
  text_4: {
    id: "t4",
    mainText: "",
  },
  text_5: {
    id: "t5",
    mainText: "Llamar a un Ejecutivo",
  },
  text_6: {
    id: "t6",
    mainText: "Reintentar",
  },
  text_7: {
    id: "t7",
    mainText: "Seguir más tarde",
  },
  text_8: {
    id: "t8",
    mainText: "Valida tu identidad. Inicia videograbación.",
  },
  text_9: {
    id: "t9",
    mainText: "",
  },
  text_10: {
    id: "t10",
    mainText: "",
  },
};

export default listText;
