import IBeneficiariesService from "../../domain/services/IBeneficiaries.service";
import { fetchBeneficiaries } from "./api/apiBeneficiaries";
import { IBeneficiarie } from "../interfaces/api/IBeneficiaries";

export default class Beneficiaries implements IBeneficiariesService {
  async saveBeneficiaries({
    beneficiaries,
    processId,
  }: {
    beneficiaries: IBeneficiarie[];
    processId: string;
  }): Promise<{ message: string }> {
    return await fetchBeneficiaries(beneficiaries, processId).then((r) => r.data);
  }
}
