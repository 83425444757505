const listText = {
  text_1: {
    id: "t1",
    mainText: "Inicio proceso apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Para llevarte por el proceso adecuado a tu perfil, confirma lo siguiente:",
  },
  text_3: {
    id: "t3",
    mainText: "Si, soy cliente",
  },
  text_4: {
    id: "t4",
    mainText: "No soy cliente",
  },
  text_5: {
    id: "t5",
    mainText: "Información de contacto",
  },
  text_6: {
    id: "t6",
    mainText: "Ingresa los siguientes datos:",
  },
  text_7: {
    id: "t7",
    mainText: "Teléfono",
  },
  text_8: {
    id: "t8",
    mainText: "Correo electrónico",
  },
  text_9: {
    id: "t9",
    mainText:
      "Te enviamos un código de validación al correo registrado. Si no lo recibes espera 90 segundos y vuelve a solicitarlo.",
  },
  text_10: {
    id: "t10",
    mainText: "Solicitar código",
  },
  text_11: {
    id: "t11",
    mainText: "Apertura de cuenta",
  },
  text_12: {
    id: "t12",
    mainText: "Te enviamos un código de 6 dígitos",
  },
  text_13: {
    id: "t13",
    mainText: "Si no recibes el código, espera 60 segundos y vuelve a solicitarlo.",
  },
  text_14: {
    id: "t14",
    mainText: "Reenviar código en",
  },
  text_15: {
    id: "t15",
    mainText: "Volver a solicitar",
  },
  text_16: {
    id: "t16",
    mainText: "Continuar",
  },
  text_17: {
    id: "t17",
    mainText: "Regresar",
  },
  text_18: {
    id: "t18",
    mainText: "¿Soy cliente Consubanco?",
  },
  text_19: {
    id: "t19",
    mainText:
      "Soy cliente, aplica para aquellos usuarios que tienen o han tenido un producto con Consubanco ya sea de nómina y/o cuentas de ahorro.",
  },
};

export default listText;
