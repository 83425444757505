import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import { store } from "./infraestructure/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <div>
          <App />
        </div>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);


if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/cache-worker-ocr-d0ac2694c57be8795778_op.js")
    .then((registration) => {
      console.log("SW registered:", registration);
    })
    .catch((registrationError) => {
      console.log("SW registration failed:", registrationError);
    });
}
