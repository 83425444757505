const API_GATEWAY = process.env.REACT_APP_APIGATEWAY;
const API_PROTECT_ROUTE = process.env.REACT_APP_API_PROTECT_ROUTE;
const APIKEY = process.env.REACT_APP_APIKEY;
const LICENSEIDENTY = process.env.REACT_APP_LICENSEIDENTY || "";

const amplitudAPIKEY = process.env.REACT_APP_AMPLITUDAPIKEY;

const KEYSFACETECPROD = process.env.REACT_APP_KEYFACETECPROD;
const KEYSFACETECDEV = process.env.REACT_APP_KEYFACETECDEV;
const ENVIRONMENTS = process.env.NODE_ENV;

declare global {
    interface Window {
        ReactNativeWebView: any;
        'OCR_SDK_ASSETS': any
    }
}


export {
    API_GATEWAY,
    API_PROTECT_ROUTE,
    APIKEY,
    LICENSEIDENTY,
    amplitudAPIKEY,
    KEYSFACETECPROD,
    KEYSFACETECDEV,
    ENVIRONMENTS
};