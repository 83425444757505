const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Firma tu contrato",
  },
  text_3: {
    id: "t3",
    mainText:
      'Al firmar y seleccionar "continuar" aceptas los términos para tu apertura de cuenta bancaria. ',
  },
  text_4: {
    id: "t4",
    mainText: "¡Atención! asegúrate de firmar lo más parecido a tu INE.",
  },
  text_5: {
    id: "t5",
    mainText: "Corregir mi firma",
  },
  text_6: {
    id: "t6",
    mainText: "Continuar",
  },
  text_7: {
    id: "t7",
    mainText: "Seguir más tarde",
  },
  text_8: {
    id: "t8",
    mainText: "Confirmo el contrato y uso de mi firma.",
  },
  text_9: {
    id: "t9",
    mainText: "¡Ups lo sentimos!",
  },
  text_10: {
    id: "t10",
    mainText: "Reintentar",
  },
  text_11: {
    id: "t11",
    mainText:
      "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_12: {
    id: "t12",
    mainText: "Vuelve a intentar si el error persiste.",
  },
  text_13: {
    id: "t13",
    mainText:
      "Tu cuenta se esta creando. Es importante que no cierres la aplicación, por favor, espera unos segundos más.",
  },
};

export default listText;
