import Modal from "react-bootstrap/Modal";
import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";
import Button from "../atoms/button";
import Image from "../atoms/image";
import * as React from "react";

const MessagesReactModal = ({
  show,
  onHide,
  textTitle,
  classTitle,
  styleTitle,
  listButtonsModal,
  imageModal,
  onClickClose,
  imageOnlyClose,
  classContent,
  classImageModal,
  classImageCloseModal,
  backdrop,
  listParagraphs,
}: any) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={backdrop}
      >
        <div className="p-2">
          <div className={classImageCloseModal}>
            <Image
              srcImage={imageOnlyClose}
              className={""}
              alt={""}
              dataBsDismiss={"modal"}
              styleImg={{ cursor: "pointer" }}
              onClick={onClickClose}
            />
          </div>
          <div className={classImageModal}>
            <Image
              srcImage={imageModal}
              className={""}
              alt={""}
              dataBsDismiss={undefined}
              styleImg={{ cursor: "pointer" }}
              onClick={undefined}
            />
          </div>
          <div className={classContent}>
            <Title
              text={textTitle}
              className={`${classTitle}`}
              styleTitle={{ display: "inline", ...styleTitle }}
            />
            {listParagraphs.map((item: any, key: number) => {
              return (
                <Paragraph
                  key={key}
                  text={item.textParagraph}
                  className={item.classParagraph}
                  styleParagraph={item.styleParagraph}
                />
              );
            })}
            <div className="">
              {listButtonsModal.map((item: any) => {
                return (
                  <Button
                    key={item.text}
                    text={item.text}
                    divClassName={item.divClass}
                    buttonClassName={item.buttonClass}
                    spinner={item.spinner}
                    onClick={item.onClick}
                    disabled={item.disabled}
                    styleButton={item.style}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MessagesReactModal;
