import listText from "./dictionaries/dictionary-information-requirements";

export const linksItems = [
  {
    id: 1,
    text: listText.text_17.mainText,
    className: "fw-bold",
    styleTitle: { fontSize: "18px" },
    listItems: [
      {
        id: 1,
        text: listText.text_18.mainText,
        url: "TermsCondition",
        className: "fs-6",
        styleLink: { fontSize: "16px", color: "#004A94" },
        onClick: null,
        target: "",
        idDownload: false,
        state: {
          typeText: "avisoprivacidad",
          typeFormat: "newLine",
          titleText: "Aviso de privacidad",
        },
      },
      {
        id: 2,
        text: listText.text_19.mainText,
        url: "TermsCondition",
        className: "fs-6",
        styleLink: { fontSize: "16px", color: "#004A94" },
        onClick: null,
        target: "",
        idDownload: false,
        state: {
          typeText: "terminoscondiciones",
          typeFormat: "newLine",
          titleText: "Términos y Condiciones",
        },
      },
    ],
  },
];
