import { useEffect } from "react";
import Slider from "rc-slider";
import Text from "../../../utils/dictionaries/dictionary-use-account-management";
import Select from "../../../components/molecules/select";
import Title from "../../../components/atoms/titleV2";
import Textarea from "../../../components/molecules/textarea";
import Paragraph from "../../../components/atoms/paragraph";
import { useMaxMinByDeposit } from "../hooks/useMaxMinByDeposit";
import "rc-slider/assets/index.css";
import "./style.scss";
import { useModalError } from "../../../hooks/useModalError";

interface IAlertData {
  formik: any;
}

export const formartNumberPoint = (number: number) => {
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Use = ({ formik }: IAlertData) => {
  const { modaComponente, setIsModalError } = useModalError();
  const {
    dataMaxMinByDeposit: { amountMax, amountMin },
    errorMaxMinByDeposit,
    isLoadingMaxMinByDeposit,
  } = useMaxMinByDeposit();

  useEffect(() => {
    if (errorMaxMinByDeposit && !isLoadingMaxMinByDeposit) {
      setIsModalError(true);
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-4_3 mb-4_3">
        <Select
          value={formik.values.accountUse}
          placeholder={formik.values.surve[3]?.description}
          data={
            formik.values?.surve[3]?.answers?.map((key: { description: string }) => ({
              value: key.description,
              label: key.description,
            })) || []
          }
          onChange={(e: any) => {
            const i = e.nativeEvent.target.selectedIndex - 1
            formik.setFieldValue("accountUse", formik.values.surve[3]?.answers[i].description);
            formik.setFieldValue(`surve[3].answers[${formik.values.accountUse}].selection`, false);
            formik.setFieldValue(`surve[3].answers[${i}].selection`, true);
          }}
        />
        {formik.values.accountUse === "otro" && (
          <div className="mt-4_3 mb-4_3">
            <Title
              text={Text.accountUse.textArea.title}
              className={"container_form__title-inputs"}
              styleTitle={{ fontWeight: 400 }}
            />
            <Textarea
              style={{ height: 103 }}
              onChange={(e) => formik.setFieldValue("descriptionUse", e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="mt-4_3 mb-4_3">
        <Select
          value={formik.values.accountOrigin}
          placeholder={Text.accountOrigin.title}
          data={
            formik.values.surve[4]?.answers.map((key: { description: string }) => ({
              value: key.description,
              label: key.description,
            })) || []
          }
          onChange={(e: any) => {
            const i = e.nativeEvent.target.selectedIndex - 1
            formik.setFieldValue("accountOrigin", formik.values?.surve[4]?.answers[i].description);
            formik.setFieldValue(`surve[4].answers[${formik.values.accountOrigin}].selection`, false);
            formik.setFieldValue(`surve[4].answers[${i}].selection`, true);
          }}
        />
      </div>
      <div className="mt-4_3 mb-4_3">
        <Title text={Text.accountMoney.title} className={"container_form__title-inputs"} />
        <Paragraph text={Text.accountMoney.description} className={""} styleParagraph={undefined} />
      </div>
      <div className="mt-4_3 mb-4_3 ">
        <Slider
          min={amountMin}
          max={240000}
          step={1000}
          defaultValue={formik.values.accountMoney}
          onChange={(e: any) => {
            formik.setFieldValue("accountMoney", e);
            formik.setFieldValue(`surve[5].answers[0].freeText`, e);
            formik.setFieldValue(`surve[5].answers[0].selection`, true);
          }}
        />
        <div className="mt-4_3" style={{ width: "100%", textAlign: "center" }}>
          <Title
            text={`${formartNumberPoint(formik.values.accountMoney)}.00`}
            className={"container_form__money"}
          />
        </div>
        <div
          className={`${formik.values.accountMoney >= amountMax ? `separator_red` : `separator_blue`
            }`}
        />

        <div style={{ width: "100%", textAlign: "center" }}>
          <Title
            text={Text.accountMoney.bottomSheetMessage.title}
            className={"container_form__money-description"}
          />
        </div>
        {formik.values.accountMoney >= amountMax && (
          <div className="alert_message">
            <svg
              width="42"
              height="42"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22.002C14.6522 22.002 17.1957 20.9484 19.0711 19.073C20.9464 17.1977 22 14.6541 22 12.002C22 9.34979 20.9464 6.80625 19.0711 4.93089C17.1957 3.05552 14.6522 2.00195 12 2.00195C9.34784 2.00195 6.8043 3.05552 4.92893 4.93089C3.05357 6.80625 2 9.34979 2 12.002C2 14.6541 3.05357 17.1977 4.92893 19.073C6.8043 20.9484 9.34784 22.002 12 22.002ZM12.9375 7.00195V7.93945V12.3145V13.252H11.0625V12.3145V7.93945V7.00195H12.9375ZM11.0625 16.377V14.502H12.9375V16.377H11.0625Z"
                fill="#E42A23"
              />
            </svg>
            <p>{Text.accountMoney.alert.message}</p>
          </div>
        )}
      </div>
      {modaComponente}
    </>
  );
};

export default Use;
