const listTextBiometric = {
  text_1: {
    id: "t1",
    mainText:
      "La validación con el INE no fue exitosa. Puedes intentar de nuevo o llamar a nuestra línea de atención para que te apoyemos a continuar con el trámite.",
  },
  text_2: {
    id: "t2",
    mainText:
      "La validación con el INE no fue exitosa. Puedes llamar a nuestra línea de atención para te apoyemos a continuar con el trámite.",
  },
  text_3: {
    id: "t3",
    mainText:
      "Estamos teniendo problemas de conexión y la validación no ha podido ser realizada.  Puedes intentar de nuevo o llamar a nuestra línea de atención para te apoyemos a continuar con el trámite.",
  },
};

export default listTextBiometric;
