import IGeneralInformationTextService from "../../domain/services/IGeneralInformationText.service";
import {
  getInformation,
  getIneConsentData,
  getTermsAndConditionsData,
  getPrivacyNoticesData,
  getListContract,
} from "./api/apiContracts";

export default class InformationTextUtilService implements IGeneralInformationTextService {
  async getInformationText(): Promise<any> {
    return await getInformation();
  }
  async getIneConsent(): Promise<{ data: { content: string } }> {
    return await getIneConsentData();
  }
  async getTermsAndConditions(): Promise<{ data: { content: string } }> {
    return await getTermsAndConditionsData();
  }
  async getPrivacyNotices(): Promise<{ data: { content: string } }> {
    return await getPrivacyNoticesData();
  }
  async getListContractDocs(uuid: string): Promise<any> {
    return await getListContract(uuid);
  }
}
