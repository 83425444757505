import IProcessContracts from "../../domain/services/IProcessContracts.service";

export class BiometricValidation {
  constructor(private item: IProcessContracts) {}
  public async getBiometricIne(processId: string) {
    try {
      const response = await this.item.getBiometricIne(processId);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: "", error: `Ocurrio un error en el servicio ${error}` };
    }
  }
}
