import { IConfirmData } from "../../interfaces/api/IPersonalData";
import {
  IFiscalFormation,
  ILaborInformation,
  IUseAccountManagement,
} from "../../interfaces/api/IPersonData";
import { IVulnerabilityQuestionnaireData, IVulnerabilityQuestionnaireResp } from "../../interfaces/api/IUtilsCommons";
import fetchBxiApiGateway from "./fetchBxiApiGateway";

interface INumberOptions {
  [key: string]: boolean;
}

export const stateAddressMatch: INumberOptions = {
  no: false,
  yes: true,
};

export const fetchPersonalData = (payload: IConfirmData, processId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/confirm-data`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      ...payload,
    },
  });
};

export const fetchFiscalInformation = (fiscalFormation: IFiscalFormation, processId: string) => {
  return fetchBxiApiGateway({
    url: `/personal-data/person/fiscal-information`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      ...fiscalFormation,
      processId,
      fiscalRegime: fiscalFormation.regimenFiscal,
      address: fiscalFormation.confirmAddress,
      addressMatch: stateAddressMatch[fiscalFormation.addressMatch],
      rfc: fiscalFormation.valueRFC,
    },
  });
};

export const fetchLaborAndUseAccountInformation = (
  laborInformation: ILaborInformation,
  useAccountManagement: IUseAccountManagement,
  processId: string,
) => {
  return fetchBxiApiGateway({
    url: `/personal-data/person/laborAndUseAccount-information`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      laborInformation,
      useAccountManagement,
    },
  });
};

export const fetchGetDataINE = (processId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchGetBlackLists = (processId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/validate-blacklist/${processId}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchGetFederalEntity = (processId: string, curp: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/getFederalEntity/${curp}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const getFetchPersonByBpId = (bpId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/byBpId/${bpId}`,
    method: "GET"
  });
}

export const postVulnerabilityInformation = (processId?: string, body?: IVulnerabilityQuestionnaireData) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/vulnerability-information`,
    method: "POST",
    headers: {
      "process-id": processId
    },
    data: {
      ...body,
    },
  });
};