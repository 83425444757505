import { useState } from 'react';
import { UseCustomerPersonalDataReturn } from './interface';
import PersonalDataService from "../../services/personalData.service";
import { DataINEuseCase } from "../../../application/use-cases/dataINE.usecase";
import { useDispatch } from "react-redux";
import { changeConfirmData } from "../../redux/slices/confirmDataReducer";
import { onClientReducer } from "../../redux/slices/userReducer"
import { personByBpIdData } from "../../redux/slices/personByBpIdReducer";
import { store } from "../../redux/store";

const useCustomerPersonalData = () : UseCustomerPersonalDataReturn => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const {
    client: {
      clientPersist
   },
} = store.getState();

  const getDataPersonService = new PersonalDataService();
  const usecasegetDataPerson = new DataINEuseCase(getDataPersonService);

  const fetchPersonByBpId = async (bpId: string) => {
    const response = await usecasegetDataPerson.getFetchPersonByBpId(bpId);
    if (response.error !== null) {
      return;
    }
    const payload = {...response.data};
    const payloadProcesses = {
      uuid: clientPersist.uuid,
      token: clientPersist.token,
      bpId: response.data.bpId,
    };
    dispatch(changeConfirmData(payload));
    dispatch(personByBpIdData(payload));
    dispatch(onClientReducer(payloadProcesses));
    setIsLoading(false);
  }

  return {
    fetchPersonByBpId,
    isLoading,
    error
  };
};

export default useCustomerPersonalData;