import { useNavigate } from "react-router-dom";
import { useRedux } from "../../hooks/useRedux";
import moment from "moment";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";

import { BiometricValidation } from "../../../application/use-cases/biometric-validation.usecase";
import ProcessContracts from "../../services/processContracts.service";
import Loader from "../../components/atoms/loader";

import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
import { useEffect } from "react";


const getBiometricIneData = new ProcessContracts();
const useCaseGetBiometricIne = new BiometricValidation(getBiometricIneData);

const queryClient = new QueryClient();
export default function LiveLivenessCheckConfirm() {
  return (
    <QueryClientProvider client={queryClient}>
      <LivenessCheckConfirm />
    </QueryClientProvider>
  );
}

const LivenessCheckConfirm = () => {
  const navigate = useNavigate();
  const numberAttempts = localStorage.getItem("numberAttempts_livenessCheck") ? JSON.parse(localStorage.getItem("numberAttempts_livenessCheck") || "0") : 0;
  const {
    data: {
      clientPersist: { uuid },
      dataPersist: { isClient },
    },
  } = useRedux();

  const {
    isLoading,
    error,
    data: dataVideoWf,
  } = useQuery({
    queryKey: ["LinessWorkflows", uuid],
    queryFn: async (queryParams) => {
      if (uuid) {
        const dataQueryFn = await useCaseGetBiometricIne.getBiometricIne(uuid);
        return dataQueryFn;
      }
    },
    refetchInterval: 3000,
  });

  useEffect(() => {
    if (dataVideoWf?.data) {
      const { status, url, retries } = dataVideoWf?.data;

      if (status === "OK") {
        trackAnalyticsEvent(
          AmplitudEventName.Realizar_prueba_de_vida_N4,
          {
            "Captura_realizada": "Exitosa",
            "Folio_biometrico_INE": url,
            "Fecha": moment(new Date()).format('ll'),
            "Hora": moment(new Date()).format('LTS'),
            "No_de_intentos": retries,
          }
        )
        if (isClient) {
          navigate("/ContractCondition");
        } else {
          navigate("/IdentityValidation");
        }
      } else if (status !== "IN_PROGRESS") {
        trackAnalyticsEvent(
          AmplitudEventName.Realizar_prueba_de_vida_N4,
          {
            "Captura_realizada": "No exitosa",
            "Folio_biometrico_INE": "",
            "Fecha": moment(new Date()).format('ll'),
            "Hora": moment(new Date()).format('LTS'),
            "No_de_intentos": retries,
          }
        )
        navigate("/LivenessCheck");
      }
    }
  }, [dataVideoWf?.data, isClient, navigate]);

  return (
    <>
      <Loader />
    </>
  );
};
