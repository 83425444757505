import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Atoms
import Image from "../../components/atoms/image";
import Loader from "../../components/atoms/loader";
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
// Templates 
import MessagesReactModal from "../../components/templates/messages-react-modal";
import Top from "../../components/templates/top";
// Redux
import { store } from "../../redux/store";
import { changeNavigate } from "../../redux/slices/navigateReducer";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Utils 
import { dateISO } from "../../utils/helpers";
// Services
import OtpUtilService from "../../services/otpUtil.service";
import ProcessContracts from "../../services/processContracts.service";
// Use-Case
import { BiometricValidation } from "../../../application/use-cases/biometric-validation.usecase";
// Assets
import { ValidateCodeUseCase } from "../../../application/use-cases/validateCode.usecase";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_selfie_blue from "../../assets/icons/ic_selfie_blue.svg";
import ic_face_retouching_natural from "../../assets/icons/ic_face_retouching_natural.svg";
import ic_no_sunglasses_blue from "../../assets/icons/ic_no_sunglasses_blue.svg";
import ic_document_id_blue from "../../assets/icons/ic_document_id_blue.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
// Dictionaries 
import listText from "../../utils/dictionaries/dictionary-identity-validation";

const otpService = new OtpUtilService();
const usecaseOtp = new ValidateCodeUseCase(otpService);
const getBiometricIneData = new ProcessContracts();
const useCaseGetBiometricIne = new BiometricValidation(getBiometricIneData);

const IdentityValidation = () => {
  const { onClickContinue } = useFollowLater();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingStartVideo, setIsLoadingStartVideo] = useState<boolean>(false);
  const [, setSec] = useState<number>(0);

  const [toggle] = useState<boolean>(false);
  const ref = useRef<NodeJS.Timeout | null>(null);

  const [biometricIneStatus, setBiometricIneStatus] = useState("");
  const {
    client: {
      dataPersist,
      clientPersist: { uuid },
      videoRecordingPersist,
    },
  } = store.getState();

  const [retries, setRetries] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const getBiometricIne = async () => {
    const response = await useCaseGetBiometricIne.getBiometricIne(uuid);
    setBiometricIneStatus(response.data.status);
    setRetries(response.data.retries);
  };

  useEffect(() => {
    ref.current = setInterval(() => {
      if (toggle) setSec((state) => state + 1);

      if (localStorage.getItem("conditionLiveness") === "false" && biometricIneStatus !== null) {
        navigate("/LivenessCheck");
        localStorage.setItem("biometricValidation", biometricIneStatus);
        localStorage.setItem("retries", retries);
      }
    });

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, biometricIneStatus, retries]);

  useEffect(() => {
    getBiometricIne();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinue = async () => {
    setIsLoading(true);
    const responseOtp = await usecaseOtp.sendOtp(
      {
        phone: dataPersist.phone,
        email: dataPersist.email,
        channel: "SMS",
        date: dateISO(),
      },
      uuid,
    );
    if (responseOtp.error !== null) {
      setModalShow(true);
      setIsLoading(false);
    } else {
      dispatch(
        changeNavigate({
          progressBarPercentage: "90%",
          editableValue: dataPersist.phone,
          typeValue: "phone",
          textButton: "Continuar",
          nextContinue: "/VideoCaptureCondition",
          comeFrom: "IdentityValidation",
          isReturn: true,
          editableReceiverOtp: false,
        }),
      );
      onClickContinue({
        data: { dataPersist: dataPersist, videoRecordingData: { ...videoRecordingPersist } },
        processId: uuid,
        resource: "IdentityValidation",
        step: "CONTINUE",
      });
      navigate("/ValidateCode");
    }
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "IdentityValidation",
        step: "CONTINUE_AFTER",
        data: { dataPersist: dataPersist, videoRecordingData: { ...videoRecordingPersist } },
        processId: uuid,
      },
    });
  };

  const onReintentar = () => {
    setModalShow(false);
    onContinue();
    setIsLoading(false);
  };

  if (isLoading || isLoadingStartVideo) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={""}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"90%"}
          comeFrom={"/IdentityValidation"}
          onClickGoBackIcon={() => navigate("/")}
        />
        <div className="p-3">
          <div className="text-center mb-4_3 mt-4">
            <Image
              srcImage={ic_selfie_blue}
              className={"ic_selfie_blue"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            />
          </div>
          <div className="col-12 mb-4_9">
            <Paragraph
              text={listText.text_3.mainText}
              className={"fs-7 mb-0 lh-base dark-blue-title"}
              styleParagraph={undefined}
            />
          </div>
          <div className="d-flex align-items-center pb-3">
            <div className="col-1">
              <Image
                srcImage={ic_face_retouching_natural}
                className={"h-32 w-32"}
                alt={""}
                dataBsDismiss={""}
                styleImg={undefined}
                onClick={undefined}
              ></Image>
            </div>
            <div className="col-11 mt-2">
              <Paragraph
                text={listText.text_4.mainText}
                className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                styleParagraph={undefined}
              />
            </div>
          </div>
          <div className="d-flex align-items-center pb-3">
            <div className="col-1">
              <Image
                srcImage={ic_no_sunglasses_blue}
                className={"h-32 w-32"}
                alt={""}
                dataBsDismiss={""}
                styleImg={undefined}
                onClick={undefined}
              />
            </div>
            <div className="col-11 mt-2">
              <Paragraph
                text={listText.text_5.mainText}
                className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                styleParagraph={undefined}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-4_9">
            <div className="col-1">
              <Image
                srcImage={ic_document_id_blue}
                className={"h-32 w-32"}
                alt={""}
                dataBsDismiss={""}
                styleImg={undefined}
                onClick={undefined}
              />
            </div>
            <div className="col-11 mt-2">
              <Paragraph
                text={listText.text_6.mainText}
                className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                styleParagraph={undefined}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-4_9">
            <div className="col-12">
              <Paragraph
                text={listText.text_7.mainText}
                className={"fs-7 mb-0 lh-base light-gray-500"}
                styleParagraph={undefined}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <Button
          id={""}
          text={listText.text_8.mainText}
          divClassName={""}
          buttonClassName={"btn-bg-primary text-white w-100 place-content-center align-self-center"}
          spinner={isLoading}
          onClick={() => onContinue()}
          disabled={isLoading}
          dataBsDismiss={""}
        />
        <div className="text-center mt-3">
          <Link
            text={listText.text_9.mainText}
            url={null}
            styleLink={{
              color: "#5A6172",
              lineHeight: "16px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => followLaterAction()}
            className={""}
          />
        </div>
      </div>
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle="¡Ups lo sentimos!"
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              onReintentar();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default IdentityValidation;
