const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Toma de Selfie",
  },
  text_3: {
    id: "t3",
    mainText:
      "Para Consubanco la seguridad de sus clientes es lo más importante, por lo que es necesario que para este proceso se verifique que se trata de una persona real y no un intento de fraude.",
  },
  text_4: {
    id: "t4",
    mainText: "Mantén tu rostro visible y colócate en un lugar iluminado.",
  },
  text_5: {
    id: "t5",
    mainText: "No uses accesorios como lentes, gorras o cubrebocas.",
  },
  text_6: {
    id: "t6",
    mainText: "Continuar",
  },
  text_7: {
    id: "t7",
    mainText: "Seguir más tarde",
  },
};

export default listText;
