import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// Atoms
import Title from "../../components/atoms/titleV2";
import Loader from "../../components/atoms/loader";
// Templates 
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import MessagesReactModal from "../../components/templates/messages-react-modalV2";
// Services
import PERSONALDATA from "../../services/personalData.service";
// Hooks
import { useApi } from "../../hooks/useApi";
import { useMaxMinByDeposit } from "../../hooks/useMaxMinByDeposit";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useDinamicSurvey } from "./hooks/useDinamicSurvey";
// Redux
import { useRedux } from "../../hooks/useRedux";
import { onUseAccountManagement } from "../../redux/slices/clientInformation";
// Components 
import { Management } from "./management";
import { UseManagement } from "./use";
import validationSchema from "./schema";
// Dictionaries
import Text from "../../utils/dictionaries/dictionary-use-account-management";
// Styles
import "./style.scss";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";

const { postLaborAndUseAccountInformation } = new PERSONALDATA();

const UseAccountManagement = () => {
  const [isModaError, setIsModalError] = useState<boolean>(false);
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();
  const { onClickContinue } = useFollowLater();
  const { dataDinamicSurvey = [], errorDinamicSurvey, isLoadingDinamicSurvey } = useDinamicSurvey();
  const {
    dataMaxMinByDeposit: { amountMax },
  } = useMaxMinByDeposit();
  const {
    data: {
      clientInformation: { useAccountManagement, laborInformation, fiscalFormation },
      clientPersist: { uuid },
      dataPersist,
    },
    dispatch,
  } = useRedux();

  const {
    data: dataLaborAndUseAccountInformation = { message: undefined },
    error: errorLaborAndUseAccountInformation,
    execute: executeLaborAndUseAccountInformation,
    isLoading: isLoadingLaborAndUseAccountInformation,
  } = useApi(postLaborAndUseAccountInformation);

  const formik = useFormik({
    initialValues: {
      accountType: useAccountManagement.accountType,
      myAccount: useAccountManagement.myAccount,
      accountFamily: useAccountManagement.accountFamily,
      accountUse: useAccountManagement.accountUse,
      accountOrigin: useAccountManagement.accountOrigin,
      accountMoney: useAccountManagement.accountMoney,
      surve: useAccountManagement.surve.length ? useAccountManagement.surve : dataDinamicSurvey,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (
      formik.values.accountType &&
      formik.values.myAccount &&
      formik.values.accountFamily &&
      formik.values.accountUse &&
      formik.values.accountOrigin &&
      formik.values.accountMoney
    ) {
      if (formik.values.accountMoney === amountMax) {
        retVal = true;
      } else {
        retVal = false;
      }
    } else {
      retVal = true;
    }

    return retVal;
  };

  const onOk = () => {
    dispatch(onUseAccountManagement({ ...formik.values }));
    executeLaborAndUseAccountInformation({
      laborInformation: laborInformation,
      useAccountManagement: formik.values,
      processId: uuid,
    });
    trackAnalyticsEvent(
      AmplitudEventName.Capturar_uso_y_manejo_cuenta_N4,
      { "Pregunta_1": formik.values.accountType  === "yes" ? "Cuenta propia" : "tercero",
        "Pregunta_2": formik.values.myAccount  === "yes" ? "SI" : "NO",
        "Pregunta_3": formik.values.accountFamily  === "yes" ? "Confirmo" : "No confirmo",
        "Uso_de_la_cuenta" : formik.values.accountUse ,
        "Origen_de_recursos": formik.values.accountOrigin ,
        "Monto_depósitos_mensuales" :formik.values.accountMoney ,
      }
    );
  };
  useEffect(() => {
    if (
      dataLaborAndUseAccountInformation?.message &&
      !errorLaborAndUseAccountInformation &&
      !isLoadingLaborAndUseAccountInformation
    ) {
      switch (dataLaborAndUseAccountInformation.message) {
        case "FORM_ERROR":
          setLoadingRedirect(true);
          setTimeout(() => {
            navigate("/UseAccountManagement/ups", {
              state: {
                resource: "UseAccountManagement/ups",
                step: "CONTINUE",
                data: {
                  useAccountManagement: formik.values,
                  laborInformation,
                  fiscalFormation,
                  dataPersist,
                },
              },
            });
            setLoadingRedirect(false);
          }, 1000);
          break;
        case "MEDIUM_OR_HIGH_RISK_ERROR":
          setLoadingRedirect(true);
          setTimeout(() => {
            navigate("/UseAccountManagement/management", {
              state: {
                resource: "UseAccountManagement/management",
                step: "CONTINUE",
                data: {
                  useAccountManagement: formik.values,
                  laborInformation,
                  fiscalFormation,
                  dataPersist,
                },
              },
            });
            setLoadingRedirect(false);
          }, 1000);

          break;
        case "SAVE_OK":
          setLoadingRedirect(true);
          setTimeout(() => {
            navigate("/VulnerabilityQuestionnaire");
            onClickContinue({
              data: {
                useAccountManagement: formik.values,
                laborInformation,
                fiscalFormation,
                dataPersist,
              },
              processId: uuid,
              resource: "UseAccountManagement",
              step: "CONTINUE",
            });
            setLoadingRedirect(false);
          }, 1000);

          break;
      }
    } else if (
      !dataLaborAndUseAccountInformation?.message &&
      errorLaborAndUseAccountInformation &&
      !isLoadingLaborAndUseAccountInformation &&
      !isModaError
    ) {
      setIsModalError(true);
      setLoadingRedirect(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataLaborAndUseAccountInformation,
    isLoadingLaborAndUseAccountInformation,
    errorLaborAndUseAccountInformation,
    isModaError,
  ]);

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "UseAccountManagement",
        step: "CONTINUE_AFTER",
        data: {
          useAccountManagement: formik.values,
          laborInformation,
          fiscalFormation,
          dataPersist,
        },
      },
    });
    dispatch(onUseAccountManagement({ ...formik.values }));
  };

  if (isLoadingDinamicSurvey || isLoadingRedirect) {
    return <Loader />;
  }

  return (
    <>
      <Top
        titleHeader={Text.top.titleHeader}
        subTitle={Text.top.subTitle}
        displayLeftImageTitle={"d-block"}
        displayProgressBar={"d-block"}
        classHeader={"mt-2"}
        classProgressBar={"mt-3"}
        valueProgressBar={"50%"}
        onClickGoBackIcon={() => navigate("/LaborInformation")}
      />
      <div className="container_form">
        <div className=" mb-4">
          <Title text={Text.title.titleText} className={"container_form__title-inputs"} />
        </div>
        <div className="">
          <>
            <Management formik={formik} />
            <div className="mt-4_3 mb-4_3 ">
              <div className="separator_grey" />
            </div>
            <UseManagement formik={formik} />
          </>
        </div>
      </div>

      <Footer
        activeSpinner={isLoadingLaborAndUseAccountInformation}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => onOk()}
        onClickButtonLink={() => onClickButtonLink()}
        labelButtonPrimary={Text.button.labelButtonPrimary}
        labelButtonSecundary={Text.button.labelButtonSecundary}
      />
      <MessagesReactModal
        show={isModaError}
        onHide={() => {
          setTimeout(() => {
            setLoadingRedirect(true);
            setIsModalError(false);
            setLoadingRedirect(false);
          }, 1000);
          window.location.reload();
        }}
        textTitle={"¡Ups lo sentimos!"}
        listButtonsModal={[
          {
            text: "Reintentar",
            spinner: isLoadingRedirect,
            onClick: () => {
              setTimeout(() => {
                setLoadingRedirect(true);
                setIsModalError(false);
                setLoadingRedirect(false);
              }, 1000);
              window.location.reload();
            },
          },
        ]}
        onClickClose={() => {
          setTimeout(() => {
            setLoadingRedirect(true);
            setIsModalError(false);
            setLoadingRedirect(false);
          }, 1000);
          window.location.reload();
        }}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
          },
        ]}
      />
    </>
  );
};

export default UseAccountManagement;
