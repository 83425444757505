import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ProcessContracts from "../services/processContracts.service";
import { IFollowLater } from "../interfaces/api/IProcessContracts";
import { useRedux } from "./useRedux";
import { useApi } from "./useApi";

const { saveDataProcces } = new ProcessContracts();

export function useFollowLater() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    data: {
      clientPersist: { uuid },
    },
    dispatch,
  } = useRedux();
  const {
    data: isDataProcces,
    error: errorDataProcces,
    execute,
    isLoading: isLoadingDataProcces,
  } = useApi<any, { data: IFollowLater; processId: string }>(saveDataProcces);

  const onClickGoOut = useCallback(() => {
    execute({ data: state, processId: uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onClickContinue: (data: IFollowLater) => execute({ data, processId: uuid }),
    onClickGoback: () => navigate(-1),
    onClickGoOut,
    response: {
      errorDataProcces,
      isLoadingDataProcces,
      isDataProcces,
    },
    stateLocation: state,
  };
}
