import { ISelectData } from "../interfaces/select"

const monthNames: string[] = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const genderNames: ISelectData[] = [
  { value: "H", label: "Hombre" },
  { value: "M", label: "Mujer" },
  { value: "X", label: "No binario" },
];

const federalEntityNames: ISelectData[] = [
  { label: "NO ESPECIFICADO", value: "NOT_SPECIFIED", },
  { label: "AGUASCALIENTES", value: "AS",
  },
  {
    label: "BAJA CALIFORNIA",
    value: "BC",
  },
  {
    label: "BAJA CALIFORNIA SUR",
    value: "BS",
  },
  {
    label: "CAMPECHE",
    value: "CC",
  },
  {
    label: "COAHUILA DE ZARAGOZA",
    value: "CL",
  },
  {
    label: "COLIMA",
    value: "CM",
  },
  {
    label: "CHIAPAS",
    value: "CS",
  },
  {
    label: "CHIHUAHUA",
    value: "CH",
  },
  {
    label: "CIUDAD DE MÉXICO",
    value: "DF",
  },
  {
    label: "DURANGO",
    value: "DG",
  },
  {
    label: "GUANAJUATO",
    value: "GT",
  },
  {
    label: "GUERRERO",
    value: "GR",
  },
  {
    label: "HIDALGO",
    value: "HG",
  },
  {
    label: "JALISCO",
    value: "JC",
  },
  {
    label: "MÉXICO",
    value: "MC",
  },
  {
    label: "MICHOACÁN DE OCAMPO",
    value: "MN",
  },
  {
    label: "MORELOS",
    value: "MS",
  },
  {
    label: "NAYARIT",
    value: "NT",
  },
  {
    label: "NUEVO LEÓN",
    value: "NL",
  },
  {
    label: "OAXACA",
    value: "OC",
  },
  {
    label: "PUEBLA",
    value: "PL",
  },
  {
    label: "QUERÉTARO",
    value: "QT",
  },
  {
    label: "QUINTANA ROO",
    value: "QR",
  },
  {
    label: "SAN LUIS POTOSÍ",
    value: "SP",
  },
  {
    label: "SINALOA",
    value: "SL",
  },
  {
    label: "SONORA",
    value: "SR",
  },
  {
    label: "TABASCO",
    value: "TC",
  },
  {
    label: "TAMAULIPAS",
    value: "TS",
  },
  {
    label: "TLAXCALA",
    value: "TL",
  },
  {
    label: "VERACRUZ DE IGNACIO DE LA LLAVE",
    value: "VZ",
  },
  {
    label: "YUCATÁN",
    value: "YN",
  },
  {
    label: "ZACATECAS",
    value: "ZS",
  },
];

export function days() {
  const items: any[] = [];
  Array.from(new Array(31), (v, i) =>
    items.push({
      label: i + 1,
      value: i <= 8 ? `0${i + 1}` : i + 1,
    }),
  );
  return items;
}

export function months() {
  const items: any[] = [];
  Array.from(new Array(12), (v, i) =>
    items.push({
      label: monthNames[i],
      value: i <= 8 ? `0${i + 1}` : i + 1,
    }),
  );
  return items;
}

export function years() {
  const year = new Date().getFullYear();
  const memoArrays: any[] = [];
  Array.from(new Array(99), (v, i) =>
    memoArrays.push({
      label: year - i,
      value: year - i,
    }),
  );
  const items = memoArrays.splice(memoArrays.length - 81);
  return items.reverse();
}

const now = +new Date();
let index = 0;

export function makeid(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getUid() {
  return `${makeid(10)}-${now}-${++index}`;
}

export function removeFileItem(data: any, fileList: any[]): any[] {
  const matchKey = data.id !== undefined ? "id" : "name";
  const removed = fileList.filter((item) => item[matchKey] !== data[matchKey]);
  if (removed.length === fileList.length) {
    return fileList;
  }
  return removed;
}

export function updateFileList(data: any, fileList: any[]) {
  const nextFileList = [...fileList];
  const fileIndex = nextFileList.findIndex(({ id }: any) => id === data.id);

  if (fileIndex === -1) {
    nextFileList.push(data);
  } else {
    nextFileList[fileIndex] = data;
  }
  return nextFileList;
}

export function rotate(srcBase64: any, degrees: any, callback: any) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const image = new Image();

  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx?.translate(canvas.width / 2, canvas.height / 2);
    ctx?.rotate((degrees * Math.PI) / 180);
    ctx?.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };

  image.src = srcBase64;
}

export function genders() {
  const items: any[] = genderNames;
  return items;
}

export function federalEntity() {
  const items: any[] = federalEntityNames;
  return items;
}
