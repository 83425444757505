import { dateISO } from "../../utils/helpers";
import fetchBxiApiGateway from "./fetchBxiApiGateway";
import ISignatureData from "../../interfaces/api/ISignatureData";

const saveTermsConditionsAcceptAutorize = (uuid: string) => {
  return fetchBxiApiGateway({
    url: "contracts/contract-acceptances",
    method: "POST",
    data: {
      processId: uuid,
      acceptanceDate: dateISO(),
    },
  });
};

const saveIneConsenAcceptances = (request: any) => {
  return fetchBxiApiGateway({
    url: "contracts/ine-consent-acceptances",
    method: "POST",
    data: request,
  });
};

const saveDocumentationAcceptances = (request: any) => {
  return fetchBxiApiGateway({
    url: "contracts/cub-information-acceptances",
    method: "POST",
    data: request,
  });
};

const saveSignatureData = (request: ISignatureData, uuid: string) => {
  return fetchBxiApiGateway({
    url: "contracts/saveDigitalSignature",
    method: "POST",
    data: request,
    headers: {
      "process-id": uuid,
    },
  });
};

export {
  saveTermsConditionsAcceptAutorize,
  saveIneConsenAcceptances,
  saveDocumentationAcceptances,
  saveSignatureData,
};
