import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useRedux } from "../../hooks/useRedux";
import { Footer } from "../../components/templates";
import Text from "../../utils/dictionaries/dictionary-laborInformation-client";
import Title from "../../components/atoms/titleV2";
import ic_phone_dark_gray from "../../assets/icons/ic_phone_dark_gray.svg";
import UtilsCommonsService from "../../services/utilsCommons.service";
import Paragraph from "../../components/atoms/paragraph";
import Input from "../../components/molecules/input";
import { useApi } from "../../hooks/useApi";
import Image from "../../components/atoms/image";
import ic_csb_white from "../../assets/icons/ic_csb_white.png";
import close_x from "../../assets/icons/close_x.svg";
import MessagesModal from "./messages-modal";
import AuthenticationService from "../../services/Authentication.service";
import ProcessContracts from "../../services/processContracts.service";
import "./style.scss";
import { IAccount } from "../../interfaces/api/IAccount";
import { IDataProcess } from "../../interfaces/api/IProcessContracts";
import { onClientReducer } from "../../redux/slices/userReducer";
import { allownumber } from "../../utils/patterns";

const UTILSCOMMONSSERRVICE = new UtilsCommonsService();
const { getAuthOauthToken } = new AuthenticationService();
const { getDataProcess } = new ProcessContracts();

const RetakePhoneClient = () => {
  const Authorization = localStorage.getItem("access_token")
    ? JSON.parse(localStorage.getItem("access_token") || "")
    : undefined;
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState<boolean>(false);

  const { posUtilsRetakePhone } = UTILSCOMMONSSERRVICE;
  const {
    data: dataIsUtilsRetakePhone,
    error: errorIsUtilsRetakePhone,
    execute: excIsUtilsRetakePhone,
    isLoading: loadingIsUtilsRetakePhone,
  } = useApi<string, { processId: string; phone: string }>(posUtilsRetakePhone);

  const {
    data: dataAuthOauthToken,
    execute: executeSaveAuthOauthToken,
    isLoading: isAuthOauthToken,
    error: isErrorAuthOauthToken,
  } = useApi<IAccount, {}>(getAuthOauthToken);

  // Valida el
  const {
    data: isDataProcess,
    error: errorDataProcess,
    execute: execDataProcess,
    isLoading: isLoadingDataProcess,
  } = useApi<IDataProcess, { processId: string }>(getDataProcess);

  const {
    data: {
      clientPersist: { uuid },
    },
    dispatch,
  } = useRedux();

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: yup.object({
      phone: yup
        .string()
        .required("Campo requerido")
        .min(10, "Debe contener 10 dígitos")
        .max(10, "Debe contener 10 dígitos"),
    }),
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    executeSaveAuthOauthToken({});
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataAuthOauthToken && !isAuthOauthToken && !isErrorAuthOauthToken && !Authorization) {
      localStorage.setItem("access_token", JSON.stringify(dataAuthOauthToken.access_token));
      window.location.reload();
    }
  }, [dataAuthOauthToken, isAuthOauthToken, isErrorAuthOauthToken, Authorization]);

  useEffect(() => {
    if (dataIsUtilsRetakePhone && !errorIsUtilsRetakePhone && !loadingIsUtilsRetakePhone) {
      execDataProcess({ processId: dataIsUtilsRetakePhone });
      localStorage.setItem("access_uuid", JSON.stringify(dataIsUtilsRetakePhone));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIsUtilsRetakePhone, errorIsUtilsRetakePhone, loadingIsUtilsRetakePhone]);

  useEffect(() => {
    if (isDataProcess && !errorDataProcess && !isLoadingDataProcess) {
      dispatch(
        onClientReducer({
          token: dataAuthOauthToken,
          uuid: dataIsUtilsRetakePhone,
        }),
      );
    } else if (!isDataProcess && errorDataProcess && !isLoadingDataProcess) {
      navigate("/DateExpire");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataProcess, errorDataProcess, isLoadingDataProcess]);

  const onOk = () => {
    if (formik.values.phone) {
      excIsUtilsRetakePhone({ phone: formik.values.phone, processId: uuid });
    }
    setIsShow(true);
  };

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (formik.values.phone) {
      retVal = false;
    } else {
      retVal = true;
    }
    return retVal;
  };

  return (
    <>
      <div className="rounded-bottom-3 p-2" style={{ backgroundColor: "#00376F" }}>
        <header className={`webkit-fill-available position-relative mt-2 mb-3`} style={{}}>
          <Image
            srcImage={ic_csb_white}
            className={"me-4"}
            alt={"ic_csb"}
            dataBsDismiss={undefined}
            styleImg={{ width: "3.5rem" }}
            onClick={undefined}
          />
          <strong
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              fontStyle: " normal",
              lineHeight: "24px",
            }}
          >
            {"Apertura de cuenta"}
          </strong>
          <Image
            srcImage={close_x}
            className={"position-absolute top-0 end-0"}
            alt={"headset_mic"}
            dataBsDismiss={undefined}
            styleImg={{ cursor: "pointer" }}
            onClick={() => console.log("")}
          />
        </header>
        <Title
          text={"Continuar trámite de apertura"}
          className={"fw-bold fs-10 ms-2"}
          styleTitle={{ color: "white" }}
        />
      </div>

      <main className="container_retake-phone">
        <Title text={"¡Es bueno tenerte de vuelta!"} className={"container_form__title"} />
        <Paragraph
          text={"Para continuar tu trámite por favor captura el número telefónico que registraste."}
          className={""}
          styleParagraph={undefined}
        />
        <>
          <div className="">
            <Input
              className={`form-control ${formik.errors.phone ? "is-invalid" : ""}`}
              type="number"
              id="phone"
              name="phone"
              placeholder=""
              value={formik.values.phone || ""}
              error={formik.errors.phone ? formik.errors.phone : ""}
              text={"Teléfono"}
              onChange={(e: any) => {
                if (allownumber.test(e.target.value)) {
                  formik.setFieldValue("phone", e.target.value);
                }
              }}
              onBlur={formik.handleBlur}
              src={ic_phone_dark_gray}
              disabled={false}
              style={undefined}
              displayLabel=""
              htmlFor="phone"
            />
          </div>
        </>
      </main>
      <Footer
        activeSpinner={false}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={onOk}
        labelButtonPrimary={Text.button.labelButtonPrimary}
      />
      <MessagesModal
        show={isShow}
        acceptSpinner={false}
        onClickAccept={() => setIsShow(false)}
        onClickClose={() => setIsShow(false)}
        onHide={() => setIsShow(false)}
      />
    </>
  );
};

export default RetakePhoneClient;
