/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import Title from "../../components/atoms/title";
import Image from "../../components/atoms/image";
import Paragraph from "../../components/atoms/paragraph";
import listText from "../../utils/dictionaries/dictionary-telephone-contact";
import TitleAndImage from "../../components/molecules/title-and-image";
import ic_csb_white from "../../assets/icons/ic_csb_white.png";
import close_x from "../../assets/icons/close_x.svg";
import blue_phone from "../../assets/icons/blue_phone.svg";
import withe_phone from "../../assets/icons/withe_phone.svg";

const TelephoneContact = () => {
  const navigate = useNavigate();
  const state = store.getState();

  return (
    <>
      <div className="content-container">
        <div className="rounded-bottom-3 p-2" style={{ backgroundColor: "#00376F" }}>
          <header className={`webkit-fill-available position-relative mt-2 mb-3`} style={{}}>
            <Image
              srcImage={ic_csb_white}
              className={"me-4"}
              alt={"ic_csb"}
              dataBsDismiss={undefined}
              styleImg={{ width: "3.5rem" }}
              onClick={undefined}
            />
            <strong
              style={{
                color: "white",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: " normal",
                lineHeight: "24px",
              }}
            >
              {listText.text_1.mainText}
            </strong>
            <Image
              srcImage={close_x}
              className={"position-absolute top-0 end-0"}
              alt={"headset_mic"}
              dataBsDismiss={undefined}
              styleImg={{ cursor: "pointer" }}
              onClick={() => navigate(state.client.contactNavigatePersist.comeFrom)}
            />
          </header>
          <Title
            text={listText.text_2.mainText}
            className={"fw-bold fs-10 ms-2"}
            styleTitle={{ color: "white" }}
          />
        </div>
        <div className="p-3">
          <Title
            text={listText.text_3.mainText}
            className={""}
            styleTitle={{
              color: "#00254A",
              fontWeight: 600,
            }}
          />
          <Paragraph
            text={listText.text_4.mainText}
            className={"mt-4"}
            styleParagraph={undefined}
          />
          <Paragraph
            text={listText.text_5.mainText}
            className={"pb-5"}
            styleParagraph={undefined}
          />
          <div
            className="text-center py-3"
            style={{ backgroundColor: "#F7F7F7", cursor: "pointer" }}
            onClick={() => {
              window.open(`tel:8008003224`, "_blank");
            }}
          >
            <Title
              text={listText.text_6.mainText}
              className={""}
              styleTitle={{
                color: "#363A44",
                fontWeight: 400,
                fontSize: 16,
              }}
            />
            <TitleAndImage
              srcImage={blue_phone}
              titleText={listText.text_7.mainText}
              displayLeftImageTitle={"pb-2"}
              classTitle={`fw-bold fs-10 ms-2`}
              styleTitle={{ color: "#00254A", fontSize: 32 }}
              onClickGoBackIcon={undefined}
              divClass={"mt-2"}
            />
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <a
        
          target="_blank"
          rel=""
          href="tel:8008003224"
          className={
            "d-grid gap-2 btn-bg-primary text-white w-100 place-content-center align-self-center rounded-3 pt-3"
          }
        >
          <p>
            <img src={"" + withe_phone} alt="" /> {listText.text_8.mainText}
          </p>
        </a>
      </div>
    </>
  );
};

export default TelephoneContact;
