import { track } from "@amplitude/analytics-browser";
//Types
import { AmplitudEventName } from "./types";

export function trackAnalyticsEvent(
  eventName: AmplitudEventName,
  properties?: Record<string, any>,
): void {
  track(eventName, properties);
}
