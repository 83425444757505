import * as React from "react";
import IRadioToggleButton from "../../interfaces/radio-toggle-button";
import "../../styles/input.scss";

export const RadioToggleButton = (props: IRadioToggleButton) => {
  return (
    <>
      <div className={props.className} style={props.divStyle} onClick={props.onClick}>
        <input
          type={props.type}
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          checked={props.isSelected}
          disabled={props.disabledInput}
          style={props.styleInput ? props.styleInput : {}}
        />
        <label htmlFor={props.text} style={props.labelStyle}>
          <img
            src={props.icon}
            alt=""
            style={{ marginRight: "6px" }}
            className={props.displayImg}
          />
          {props.text}
        </label>
      </div>
    </>
  );
};
