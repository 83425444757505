import { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import "../../styles/swipeable-button.scss";

const dirTypes = ["Left", "Down", "Up"];

const findLeft = (element: any) => {
  var rec = element.getBoundingClientRect();
  return rec.left + window.scrollX;
};
interface ISlideProps {
  mainText: any;
  overlayText: any;
  onSlideDone: Function;
  reset?: number;
  delta?: number;
  minSlideWidth?: number;
  minSlideVelocity?: number;
  caret?: any;
  customCaretWidth?: number;
}

const SlideButton = ({
  mainText,
  overlayText,
  onSlideDone,
  reset,
  delta = 10,
  minSlideWidth = 0.6,
  minSlideVelocity = 0.6,
  caret = null,
  customCaretWidth = 40,
}: ISlideProps) => {
  const [overlayWidth, setOverlayWidth] = useState<number>(customCaretWidth);
  const [slideComplete, setSlideComplete] = useState<boolean>(false);
  const buttonRef = useRef<any>();

  useEffect(() => {
    if (reset === 100) {
      setSlideComplete(false);
      setOverlayWidth(customCaretWidth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const handlers = useSwipeable({
    onSwipedRight: (data: any) => {
      if (slideComplete) return;
      const butWidth = buttonRef.current.offsetWidth;
      if (data.velocity > minSlideVelocity) {
        setOverlayWidth(butWidth);
        setSlideComplete(true);
        setTimeout(() => onSlideDone(), 100);
      } else {
        const offsetLeft = findLeft(buttonRef.current);
        const startPos = Math.abs(data.initial[0] - offsetLeft);
        if (
          startPos <= 100 + customCaretWidth &&
          (data.event.type === "touchend"
            ? data.event.changedTouches[0].clientX - offsetLeft
            : data.event.offsetX) >
            minSlideWidth * butWidth
        ) {
          setOverlayWidth(butWidth);
          setSlideComplete(true);
          setTimeout(() => onSlideDone(), 100);
        } else setOverlayWidth(customCaretWidth);
      }
    },
    onSwiping: (data: any) => {
      if (slideComplete || dirTypes.includes(data.dir)) return;
      const offsetLeft = findLeft(buttonRef.current);
      const startPos = Math.abs(data.initial[0] - offsetLeft);
      if (startPos <= 100 + customCaretWidth) {
        if (data.event.type && data.event.type === "touchmove")
          setOverlayWidth(data.event.touches[0].clientX - offsetLeft);
        else {
          if (data.event.offsetX > customCaretWidth) {
            setOverlayWidth(data.event.offsetX);
          }
        }
      }
    },
    delta,
    trackMouse: true,
  });

  return (
    <div
      className={`slide-but`}
      {...handlers}
      ref={(t) => {
        handlers.ref(t);
        buttonRef.current = t;
      }}
    >
      <div className={`slide-overlay`} style={{ width: overlayWidth }}>
        <div className="slide-overlay-wrapper">
          <div
            style={{
              width: customCaretWidth,
              maxWidth: customCaretWidth,
            }}
            className={`slide-caret-wrapper `}
          >
            {caret ? (
              caret
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7507 11.2454L13.5031 11.9978L12.7507 12.7502L4.25323 21.2476L3.50085 22L2.00052 20.4952L2.7529 19.7429L10.498 11.9978L2.74847 4.25271L1.99609 3.50033L3.50085 2L4.25323 2.74795L12.7507 11.2454ZM21.2481 11.2454L22.0005 11.9978L21.2481 12.7502L12.7507 21.2476L11.9983 22L10.498 20.4952L11.2504 19.7429L18.9954 11.9978L11.2459 4.25271L10.4935 3.50033L11.9983 2L12.7507 2.75238L21.2481 11.2454Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div className="slide-overlay-txt" style={{ width: overlayWidth }}>
            {overlayText}
          </div>
        </div>
      </div>
      <span>{mainText}</span>
    </div>
  );
};

export default SlideButton;
