import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  comeFrom: "",
  urlPdf: "",
  title: "",
};

export const pdfViewerSlice = createSlice({
  name: "pdfViewerData",
  initialState,
  reducers: {
    pdfViewerData: (state, action) => {
      const { comeFrom, urlPdf, title } = action.payload;
      state.comeFrom = comeFrom;
      state.urlPdf = urlPdf;
      state.title = title;
    },
  },
});

export const { pdfViewerData } = pdfViewerSlice.actions;
export default pdfViewerSlice.reducer;
