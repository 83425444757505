import { useRef } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Atoms
import Loader from "../../components/atoms/loader";
import { useFollowLater } from "../../hooks/useFollowLater";
import Link from "../../components/atoms/link";
import Image from "../../components/atoms/image";
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Facetec
import { SampleApp } from "../../facetec/sample-app/sampleAppController";
// Redux
import { store } from "../../redux/store";
// Assets
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import credentialImg from "../../assets/images/img_credential.svg";
import ic_settings_blue from "../../assets/icons/ic_settings_blue.svg";
import ic_document_blue from "../../assets/icons/ic_document_blue.svg";
import ic_document_id_blue from "../../assets/icons/ic_document_id_blue.svg";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-capture-ine";
// Styles
import "../../styles/styleFacetec.scss";



// - Exitosa/No exitosa
// - No de intentos
const dataAmplitudeObject: {[key: string] : string | number }  = {
  Captura_realizada: "No exitosa",
  No_de_intentos: 0,
}

const CaptureINE = () => {
  const { onClickContinue } = useFollowLater();
  const state = store.getState();
  const navigate = useNavigate();
  const [, setSec] = useState<number>(0);
  const [numberAttempts, setnumberAttempts] = useState<number>(1);
  const [toggle] = useState<boolean>(false);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [amplitudeObject , setAmplitudeObject]  = useState({...dataAmplitudeObject});

  useEffect (() => {
    localStorage.removeItem("facetecStatus");
    localStorage.removeItem("condition");
    localStorage.removeItem("addressZipCode");
    localStorage.removeItem("numberAttempts");
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref.current = setInterval(() => {
      if (toggle) setSec((state) => state + 1);

      if (
        localStorage.getItem("condition") === "false" ||
        localStorage.getItem("condition") === null
      ) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }

      if (localStorage.getItem("facetecStatus") === "true") {
        setShowLoader(true);
      }
    });

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  }, [toggle]);

  const next = () => {
    SampleApp.onPhotoIDScanPressed();
    onClickContinue({
      data: { dataPersist: { ...state.client.dataPersist } },
      processId: state.client.clientPersist.uuid,
      resource: "CaptureINE",
      step: "CONTINUE",
    });
    setnumberAttempts( numberAttempts +  1)
    localStorage.setItem("numberAttempts", JSON.stringify(numberAttempts));
    setTimeout(() => {
      localStorage.setItem("condition", JSON.stringify(true));
      setShowLoader(true);
    }, 3000);
  };

  const functionProcessModal = () => {
    setModalShow(false);
    window.location.reload();
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        processId: state.client.clientPersist.uuid,
        resource: "CaptureINE",
        step: "CONTINUE_AFTER",
        data: { dataPersist: { ...state.client.dataPersist } }, // Agregar data del formulario
      },
    });
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={""}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"30%"}
          comeFrom={"/CaptureINE"}
          onClickGoBackIcon={() => navigate("/")}
        />
        <div className={"text-center mb-4_3 mt-4 "}>
          <Image
            srcImage={credentialImg}
            className={"img-capture-ine"}
            alt={""}
            dataBsDismiss={""}
            styleImg={undefined}
            onClick={undefined}
          ></Image>
        </div>
        <div className="d-flex align-items-center ps-3 pb-3">
          <div className="col-1">
            <Image
              srcImage={ic_settings_blue}
              className={"h-32 w-32"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            ></Image>
          </div>
          <div className="col-11">
            <Paragraph
              text={listText.text_4.mainText}
              className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
              styleParagraph={undefined}
            />
          </div>
        </div>
        <div className="d-flex align-items-center ps-3 pb-3">
          <div className="col-1">
            <Image
              srcImage={ic_document_blue}
              className={"h-32 w-32"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            ></Image>
          </div>
          <div className="col-11">
            <Paragraph
              text={listText.text_5.mainText}
              className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
              styleParagraph={undefined}
            />
          </div>
        </div>
        <div className="d-flex align-items-center ps-3 pb-3">
          <div className="col-1">
            <Image
              srcImage={ic_document_id_blue}
              className={"h-32 w-32"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            ></Image>
          </div>
          <div className="col-11">
            <Paragraph
              text={listText.text_6.mainText}
              className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
              styleParagraph={undefined}
            />
          </div>
        </div>
      </div>
      <div className="footer--pin">
        <Button
          text={listText.text_7.mainText}
          divClassName={""}
          buttonClassName={"btn-bg-primary text-white w-100 place-content-center align-self-center"}
          spinner={false}
          onClick={() => next()}
          disabled={false}
          dataBsDismiss={""}
        ></Button>

        <div className="text-center mt-3">
          <Link
            text={"Seguir más tarde"}
            url={null}
            styleLink={{
              color: "#5A6172",
              lineHeight: "16px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => followLaterAction()}
            className={""}
          />
        </div>
      </div>
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={"¡Ups lo sentimos!"}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default CaptureINE;
