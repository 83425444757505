import { Footer } from "../../../components/templates";
import "./style.scss";

interface IAlertData {
  setView: () => void;
  isLoadingFiscalInformation: boolean;
}

const ValidateSATAlertData = ({ setView, isLoadingFiscalInformation }: IAlertData) => {
  return (
    <>
      <main className="container_fiscal_formation__confirmation ">
        <svg
          width="88"
          height="89"
          viewBox="0 0 88 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.00195312" width="88" height="88" rx="44" fill="#E2F0FF" />
          <g clip-path="url(#clip0_1256_8164)">
            <path
              d="M44.0035 29.5254C44.5011 29.5254 44.9083 29.9326 44.9083 30.4302V48.526C44.9083 49.0236 44.5011 49.4307 44.0035 49.4307C43.5058 49.4307 43.0987 49.0236 43.0987 48.526V30.4302C43.0987 29.9326 43.5058 29.5254 44.0035 29.5254ZM45.8131 54.8595C45.8131 55.8547 44.9987 56.669 44.0035 56.669C43.0082 56.669 42.1939 55.8547 42.1939 54.8595C42.1939 53.8642 43.0082 53.0499 44.0035 53.0499C44.9987 53.0499 45.8131 53.8642 45.8131 54.8595ZM53.7299 18.668C56.5687 18.668 59.2152 20.1496 60.6855 22.5767L71.1471 39.7721C72.7305 42.3734 72.7305 45.6306 71.1471 48.2319L60.6855 65.4229C59.2152 67.8545 56.5687 69.3361 53.7299 69.3361H34.1752C31.4382 69.3361 28.7917 67.8545 27.3215 65.4229L16.8553 48.2319C15.2722 45.6306 15.2722 42.3734 16.8553 39.7721L27.3215 22.5767C28.7917 20.1496 31.4382 18.668 34.1752 18.668H53.7299ZM18.4014 40.7109C17.1697 42.7353 17.1697 45.2687 18.4014 47.2932L28.8596 64.4841C30.0132 66.3729 32.0603 67.5265 34.1752 67.5265H53.7299C55.9467 67.5265 57.9938 66.3729 59.136 64.4841L69.609 47.2932C70.8417 45.2687 70.8417 42.7353 69.609 40.7109L59.136 23.5176C57.9938 21.63 55.9467 20.4775 53.7299 20.4775H34.1752C32.0603 20.4775 30.0132 21.63 28.8596 23.5176L18.4014 40.7109Z"
              fill="#5A6172"
            />
          </g>
          <defs>
            <clipPath id="clip0_1256_8164">
              <rect width="68" height="68" fill="white" transform="translate(10 10.002)" />
            </clipPath>
          </defs>
        </svg>
        <h3>¡Importante!</h3>
        <h4>Los datos proporcionados no coinciden con la información registrada en el SAT.</h4>
        <p>
          <b>¡No te preocupes!</b> Puedes avanzar en el proceso, sin embargo, es necesario que en un
          plazo menor a un mes hagas la actualización de tu información, de lo contrario tu cuenta
          será bloqueada y no se podrán emitir estados de cuenta.
        </p>
      </main>
      <Footer
        activeSpinner={isLoadingFiscalInformation}
        disabledButton={false}
        onClickButtonPrimary={() => setView()}
        labelButtonPrimary={"Entendido"}
      />
    </>
  );
};

export default ValidateSATAlertData;
