import IOTPService from "../../domain/services/IOtp.service";
import { generateOtp, validateOtp } from "./api/apiOtp";

export default class OtpUtilService implements IOTPService {
  async generate(request: any, processId: string): Promise<any> {
    return await generateOtp(request, processId);
  }
  async validate(request: any, processId: string): Promise<any> {
    return await validateOtp(request, processId);
  }
}
