const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Valida tu identidad",
  },
  text_3: {
    id: "t3",
    mainText:
      "Por requisito regulatorio de la Comisión Nacional Bancaria de Valores (CNBV) es necesario realizar la validación de tu identidad, la cual consta de comparar tu rostro contra la fotografía en tu INE.",
  },
  text_4: {
    id: "t4",
    mainText: "Mantén tu rostro visible y colócate en un lugar iluminado.",
  },
  text_5: {
    id: "t5",
    mainText: "No uses accesorios como lentes, gorras o cubrebocas.",
  },
  text_6: {
    id: "t6",
    mainText:
      "Muestra tu identificación oficial (INE) por el Anverso y Reverso, ésta no debe cubrir tu rostro",
  },
  text_7: {
    id: "t7",
    mainText:
      "Para iniciar la validación te enviaremos un código de 6 dígitos al teléfono registrado.",
  },
  text_8: {
    id: "t8",
    mainText: "Continuar",
  },
  text_9: {
    id: "t9",
    mainText: "Seguir más tarde",
  },
};

export default listText;
