import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Atoms
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
import Loader from "../../components/atoms/loader";
// organisms
import Accordion from "../../components/organisms/accordion";
// Moleculas
import Checkbox from "../../components/molecules/checkbox";
// Templates
import LinkList from "../../components/templates/link-list";
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Service
import InformationTextUtilService from "../../services/informationTextUtil.service";
import DocsUtilService from "../../services/docsUtil.service";
import AcceptAuthorizeUtilService from "../../services/acceptAuthorizeUtil.service";
// Use-Case
import { GeneralInformationTextUseCase } from "../../../application/use-cases/generalInformationText.usecase";
import { DocsUseCase } from "../../../application/use-cases/docs.usecase";
import { classAcceptAuthorizeUseCase } from "../../../application/use-cases/acceptAuthorize.usecase";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-information-requirements";
// Utils
import { linksItems } from "../../utils/ir-functions";
import { AmplitudEventName } from "../../utils/amplitude/types";
import { trackAnalyticsEvent } from "../../utils/amplitude";
// Redux
import { store } from "../../redux/store";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
import useCustomerPersonalData from "../../hooks/useCustomerPersonalData/useCustomerPersonalData";
// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";

const caseService = new InformationTextUtilService();
const useCase = new GeneralInformationTextUseCase(caseService);

const caseServiceAutorice = new AcceptAuthorizeUtilService();
const useCaseAutorize = new classAcceptAuthorizeUseCase(caseServiceAutorice);

const caseDocs = new DocsUtilService();
const useCaseDocs = new DocsUseCase(caseDocs);


const dataAmplitudeObject: {[key: string] : "SI" | "NO"}  = {
  Reviso_Beneficios: "NO",
  Reviso_Requisitos: "NO",
  Reviso_TyC: "NO",
  Reviso_Aviso_de_privacidad: "NO",
}

const InformationRequirements = () => {
  const { onClickContinue } = useFollowLater();
  const state = store.getState();

  const [btnColor, setBtnColor] = useState("button-disabled");
  const [checked, setChecked] = useState(false);
  const [enabledButton, setEnabledButton] = useState(true);
  const [incompleteData, setIncompleteData] = useState(true);
  const [informationList, setInformationList] = useState([]);
  const [somethingWentWrong, setSomethingWentWrong] = useState(true);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [amplitudeObject , setAmplitudeObject]  = useState({...dataAmplitudeObject});

  const {
    fetchPersonByBpId,
    error: errorPersonalData,
    isLoading: loadingPersonalData,
  } = useCustomerPersonalData();

  const acceptAuthorize = (value: boolean) => {
    if (!somethingWentWrong) {
      setChecked(!value);
      setBtnColor(value ? "button-disabled" : "button-enabled");
      setEnabledButton(value);
    }
  };

  const navigate = useNavigate();

  const onClickLevelRout = async () => {
    setActiveSpinner(true);
    const response = await useCaseAutorize.termsConditionsAutorize(state.client.clientPersist.uuid);
    if (response.error !== null) {
      setModalShow(true);
    } else {
      onClickContinue({
        data: {},
        processId: state.client.clientPersist.uuid,
        resource: "InformationRequirements",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Beneficios_y_requisitos_N4,
        { ...amplitudeObject }
      )
      navigate("/AccountOpening");
    }
    setActiveSpinner(false);
  };

  const functionProcessModal = () => {
    setModalShow(false);
    window.location.replace("");
  };

  const getInformation = async () => {
    setIncompleteData(true);
    const response = await useCase.informationText();
    if (response.error === null) {
      setSomethingWentWrong(false);
    } else {
      setModalShow(true);
    }
    setInformationList(response.data);
    setIncompleteData(false);
  };

  useEffect(() => {
    getInformation();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocumentsUrl = async () => {
    const resDocDigitalCOntract = await useCaseDocs.getDigitalContractDoc();
    const resDocCaratylaInversion = await useCaseDocs.getCaratulaInversionesDoc();
    if (resDocDigitalCOntract.error != null || resDocCaratylaInversion.error != null) {
      setModalShow(true);
    }
  };

  useEffect(() => {
    getDocumentsUrl();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.client.clientPersist.bpId !== null) {
      acceptAuthorize(checked);
      if(state.client.clientPersist.bpId) {
        fetchPersonByBpId(state.client.clientPersist.bpId);
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [somethingWentWrong]);

  const eventTraAmplitud  = (key: string )  => {
    switch (key) {
      case 'Beneficios':
        setAmplitudeObject( { ...amplitudeObject , "Reviso_Beneficios"  : "SI"})
      break;
      case 'Requisitos':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Requisitos"  : "SI"})
      break;
      case 'Aviso de privacidad':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Aviso_de_privacidad"  : "SI"})
      break;
      case 'Términos y Condiciones':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_TyC"  : "SI"})
      break;
    }
  }

  return (
    <>
      {incompleteData ? (
        <Loader />
      ) : (
        <>
          <div className="content-container">
            <Top
              titleHeader={"Apertura de cuenta"}
              subTitle={"¡Gracias por elegirnos!"}
              displayProgressBar={"d-none"}
              displayLeftImageTitle={"d-none"}
              classTitleImage={""}
              classHeader={"mt-2 mb-3"}
              styleHeader={{}}
              classProgressBar={"mt-3"}
              styleProgressBar={{}}
              classTitleAndImage={""}
              styleTitleAndImage={{}}
              valueProgressBar={""}
              comeFrom={"/InformationRequirements"}
              onClickGoBackIcon={undefined}
            />
            <div className="p-3">
              <div className="my-2">
                <Paragraph
                  text={listText.text_2.mainText}
                  className={"fs-6"}
                  styleParagraph={undefined}
                />
              </div>
              <div className="mb-3">
                <Accordion
                  accordionItems={informationList}
                  listType={"information"}
                  onClick={(e) => eventTraAmplitud(`${e.title}`)}
                />
              </div>
              {state.client.clientPersist.bpId === null ? (
                <>
                  <div className={`mt-4`}>
                    <LinkList dataItems={linksItems} onClick={(e) => eventTraAmplitud(`${e.text}`)} />
                  </div>
                  <div className={`mt-5 `}>
                    <Checkbox
                      text={listText.text_22.mainText}
                      htmlFor={"flexCheckDefault"}
                      classNameLabel={"fs-6"}
                      classCheckbox={"form-check my-4_2"}
                      checked={checked}
                      onChange={() => {
                        acceptAuthorize(checked);
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="footer--pin">
            <Button
              text={listText.text_23.mainText}
              divClassName={"d-grid gap-2 "}
              buttonClassName={`${btnColor} ${
                activeSpinner ? "pe-none" : ""
              } w-100 place-content-center align-self-center`}
              spinner={activeSpinner}
              onClick={() => onClickLevelRout()}
              disabled={enabledButton}
              dataBsDismiss={undefined}
            />
          </div>
        </>
      )}
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_24.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_25.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_26.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_27.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default InformationRequirements;
