/* eslint-disable no-unused-vars */
export enum AmplitudEventName {
  Beneficios_y_requisitos_N4 = "Beneficios_y_requisitos_N4",
  Información_contacto_N4 = "Información_contacto_N4",
  Confirmar_OTP_correo_N4 = "Confirmar_OTP_correo_N4",
  Confirmar_OTP_teléfono_N4 = 'Confirmar_OTP_teléfono_N4',
  Aceptar_consentimiento_INE_N4 = "Aceptar_consentimiento_INE_N4",
  Capturar_INE_N4 = "Capturar_INE_N4",
  Confirmar_dirección_INE_N4 = "Confirmar_dirección_INE_N4",
  Confirmar_datos_personales_N4 ="Confirmar_datos_personales_N4",
  Identificar_cliente_CSB_N4 = "Identificar_cliente_CSB_N4", // Pendiente
  Capturar_RFC_N4= "Capturar_RFC_N4",
  Identificar_información_fiscal_no_coincide_SAT = "Identificar_información_fiscal_no_coincide_SAT",
  Capturar_Información_laboral_N4 = "Capturar_Información_laboral_N4",
  Capturar_uso_y_manejo_cuenta_N4 ="Capturar_uso_y_manejo_cuenta_N4",
  Capturar_beneficiarios_N4 = "Capturar_beneficiarios_N4",
  Realizar_prueba_de_vida_N4 = "Realizar_prueba_de_vida_N4",
  Realizar_videograbación_N4 = "Realizar_videograbación_N4",
  Contestar_Cuestionario_Identificación_N4 ="Contestar_Cuestionario_Identificación_N4",
  Revisar_contrato_si_soy_cliente_N4 = "Revisar_contrato_si_soy_cliente_N4",
  Firmar_con_OTP_N4 = "Firmar_con_OTP_N4",
  Revisar_contrato_noy_soy_cliente = "Revisar_contrato_noy_soy_cliente",
  Firmar_manualmente_contrato_N4 = "Firmar_manualmente_contrato_N4",
  Terminar_proceso_N4 = "Terminar_proceso_N4",
  Confirmar_OTP_videograbación_N4= "Confirmar_OTP_videograbación_N4"
}
