import * as React from "react";

const Spinner = () => {
  return (
    <>
      <div
        className="spinner-border text-light"
        role="status"
        style={{ width: "30px", height: "30px" }}
      >
        <span className="sr-only"></span>
      </div>
    </>
  );
};

export default Spinner;
