import * as Yup from "yup";

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>
  Yup.object({
    valueRFC: Yup.string()
      .required("")
      .max(10, "Máximo 10 caracteres.")
      .matches(/^[a-zA-Z]{4}[0-9]{6}/, "RFC no válido"),
    homoclave: Yup.string()
      .required("")
      .max(3, "Máximo 3 caracteres.")
      .matches(/^[A-Z0-9]{3}/, "Homoclave no válida"),
    addressMatch: Yup.string().required(""),
    regimenFiscal: Yup.string().required(""),
  });
