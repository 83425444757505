import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pdfsContractCondition: [],
  pdfIdentificationQuestionnaire: "",
};

export const pdfsListSlice = createSlice({
  name: "pdfsListData",
  initialState,
  reducers: {
    pdfsListData: (state, action) => {
      const { pdfsContractCondition, pdfIdentificationQuestionnaire } = action.payload;
      state.pdfsContractCondition = pdfsContractCondition;
      state.pdfIdentificationQuestionnaire = pdfIdentificationQuestionnaire;
    },
  },
});

export const { pdfsListData } = pdfsListSlice.actions;
export default pdfsListSlice.reducer;
