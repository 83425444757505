import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  token: "",
  bpId: null
};

export const userSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    onClientReducer: (state, action) => {
      const { uuid, token, bpId } = action.payload;
      state.uuid = uuid;
      state.token = token;
      state.bpId = bpId;
    },
  },
});

export const { onClientReducer } = userSlice.actions;
export default userSlice.reducer;
