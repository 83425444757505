import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import blue_left_arrow from "../../assets/icons/blue-left-arrow.svg";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { store } from "../../redux/store";

const PdfViewer = () => {
  const navigate = useNavigate();
  const state = store.getState();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  return (
    <>
      <div style={{ backgroundColor: "#D9D9D9" }}>
        <header className="p-3 webkit-fill-available" style={{ background: "white" }}>
          <img
            src={"" + blue_left_arrow}
            alt="ic_csb"
            className="me-4"
            style={{ width: "1.5rem", cursor: "pointer" }}
            onClick={() => navigate(state.client.pdfViewerPersist.comeFrom)}
          />
          <strong style={{ color: "#00254A", fontSize: "20px" }}>
            {state.client.pdfViewerPersist.title}
          </strong>
        </header>
        <div className="p-2">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div
              className="rpv-core__viewer"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <ZoomOutButton />
                <ZoomPopover />
                <ZoomInButton />
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <Viewer
                  fileUrl={state.client.pdfViewerPersist.urlPdf}
                  plugins={[zoomPluginInstance]}
                />
              </div>
            </div>
          </Worker>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
