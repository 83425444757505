import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { TransactionMode } from "@identy/identy-ocr";
import { LICENSEIDENTY } from "../../../env";
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
import Paragraph from "../../components/atoms/paragraph";
import { RadioToggleButton } from "../../components/molecules/radio-toggle-buttons";
import blue_camera_alt from "../../assets/icons/blue_camera_alt.svg";
import blue_upgrade from "../../assets/icons/blue_upgrade.svg";
import circleInfoSharpRegular from "../../assets/icons/circle-info-sharp-regular.svg";
import TitleAndImage from "../../components/molecules/title-and-image";
import BottomSheetMessage from "../../components/templates/bottom-sheet-message";
import SelectTypeFile from "./select-type-file";
import greenCircleCheckSharpSolid from "../../assets/icons/green-circle-check-sharp-solid.svg";
import listText from "../../utils/dictionaries/dictionary-proof-address-condition";
import { CardOcrSDK } from "@identy/identy-ocr";
import { runCaptureOCR } from "../../utils/sdk-identy/sdk-config";
import { IContextMenuSelection } from "../../utils/sdk-identy/sdk-config.interface";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useModalError } from "../../hooks/useModalError";
import { useRedux } from "../../hooks/useRedux";
import { useApi } from "../../hooks/useApi";
import "./style.scss";
import UtilsCommonsService from "../../services/utilsCommons.service";
import {
  addConfirmData,
  credentialData,
  federalEntity,
  principalAddress,
} from "../../redux/slices/confirmDataReducer";
import Loader from "../../components/atoms/loader";
import { DataINEuseCase } from "../../../application/use-cases/dataINE.usecase";
import PersonalDataService from "../../services/personalData.service";
import { store } from "../../redux/store";

const { posUtilsOcrReceiptAddress } = new UtilsCommonsService();

const getDataINEService = new PersonalDataService();
const usecasegetDataINE = new DataINEuseCase(getDataINEService);

const getFederalEntityData = new PersonalDataService();
const useCaseFederalEntityData = new DataINEuseCase(getFederalEntityData);

const ProofAddressCondition = () => {
  const navigate = useNavigate();
  const state = store.getState();
  const { isModaError, modaComponente, setIsModalError } = useModalError();
  const {
    data: {
      clientPersist: { uuid },
      confirmData,
      dataPersist,
    },
    dispatch,
  } = useRedux();
  const {
    data: dataUtilsOcrReceiptAddress,
    error: errorUtilsOcrReceiptAddress,
    execute: executeUtilsOcrReceiptAddress,
    isLoading: isLoadingUtilsOcrReceiptAddress,
  } = useApi(posUtilsOcrReceiptAddress);
  const { onClickContinue } = useFollowLater();
  const [isOpenBottonSheet, setOpenBottonSheet] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [isSelectType, setSelectType] = useState<boolean>(false);
  const [isSelectFile, setSelectFile] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      file: undefined,
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    CardOcrSDK.preInitialize(LICENSEIDENTY).catch((err) => {
      if (err.code === 506) {
        alert(err.message);
      }
    });
    getDataINE();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataINE = async () => {
    const response = await usecasegetDataINE.getDataINE(state.client.clientPersist.uuid);
    if (response.error === null) {
      if (response.data) {
        const dateString = response.data.dateBirth;
        const dateObj = dateString?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3");
        /// Si el verifica cual de los datos de array tiene un codigo de  zip para cargar mejor la data
        const address = response.data.addresses.find((key: any) => key.zipCode !== "");

        let responseFederalEntity: any;
        if (response.data.curp) {
          responseFederalEntity = await useCaseFederalEntityData.getFederalEntity(
            state.client.clientPersist.uuid,
            state.client.confirmData.curp,
          );
          dispatch(federalEntity({ ...response.data.federalEntity }));
        }

        dispatch(
          addConfirmData({
            ...response.data,
            dateBirth: dateObj,
            email: state.client.dataPersist.email,
            phone: state.client.dataPersist.phone,
            placeBirth: "MX",
            nationality: "MX",
          }),
        );

        dispatch(principalAddress({ ...address, suburbs: address.town, country: "MX" }));
        dispatch(credentialData({ ...response.data.credentialData }));

        onClickContinue({
          data: {
            confirmData: {
              ...response.data,
              dateBirth: dateObj,
              email: state.client.dataPersist.email,
              phone: state.client.dataPersist.phone,
              placeBirth: "MX",
              federalEntity: responseFederalEntity?.data,
              nationality: "MX",
              principalAddress: { ...address, suburbs: address.town, country: "MX" },
              ...response.data.credentialData,
            },
            dataPersist: { ...state.client.dataPersist },
          },
          processId: state.client.clientPersist.uuid,
          resource: "AddressCondition",
          step: "CONTINUE",
        });
      }
    }
  };

  const onOk = async () => {
    executeUtilsOcrReceiptAddress({ processId: uuid, dataFile: formik.values.file });
  };

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (formik.values.file && formik.values.file) {
      retVal = false;
    } else {
      retVal = true;
    }

    return retVal;
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "ProofAddressCondition",
        step: "CONTINUE_AFTER",
        data: {
          confirmData: {
            ...confirmData,
            principalAddress: { ...dataUtilsOcrReceiptAddress },
            ...confirmData.credentialData,
          },
          dataPersist: { ...dataPersist },
        },
      },
    });
  };

  const capture = (contextMenuSelection: IContextMenuSelection, isSelectFile: boolean) => {
    return new Promise((resolve, reject) => {
      runCaptureOCR(TransactionMode.CAPTURE, contextMenuSelection, isSelectFile)
        .then((response: any) => {
          const out = response.data;

          if (out && response.code === 200) {
            setIsLoadingFile(true);
            if (response.data.FRONT.debug.error !== "NO_ERROR") {
              setTimeout(() => {
                navigate("/SuggestionCaptureReceipt", { state: { ...contextMenuSelection } });
                setIsLoadingFile(false);
              }, 1000);
            } else if (
              contextMenuSelection.card_type.full_name !== response.data.FRONT.debug.poaType
            ) {
              setTimeout(() => {
                navigate("/SuggestionCaptureReceipt", { state: { ...contextMenuSelection } });
                setIsLoadingFile(false);
              }, 1000);
            } else {
              setTimeout(() => {
                formik.setFieldValue("file", response.data);
                setIsLoadingFile(false);
              }, 1000);
            }
            return resolve({
              ocr_output: response.data,
            });
          }
        })
        .catch((err) => {
          return resolve({
            ocr_output: err,
          });
        });
    });
  };

  useEffect(() => {
    if (
      dataUtilsOcrReceiptAddress &&
      !errorUtilsOcrReceiptAddress &&
      !isLoadingUtilsOcrReceiptAddress
    ) {
      if (dataUtilsOcrReceiptAddress) {
        setIsLoadingFile(true);

        setTimeout(() => {
          navigate("/DataConfirmation");
          dispatch(
            principalAddress({
              zipCode: dataUtilsOcrReceiptAddress.zipCode,
              state: dataUtilsOcrReceiptAddress.state,
              stateId: dataUtilsOcrReceiptAddress.stateId,
              stateCRM: dataUtilsOcrReceiptAddress.stateCrm,
              street: dataUtilsOcrReceiptAddress.street,
              externalNumber: dataUtilsOcrReceiptAddress.externalNumber,
              internalNumber: dataUtilsOcrReceiptAddress.internalNumber,
              townshipId: dataUtilsOcrReceiptAddress.townshipId,
              township: dataUtilsOcrReceiptAddress.township,
              colony: dataUtilsOcrReceiptAddress.town,
              country: "MX",
            }),
          );
          onClickContinue({
            data: {
              confirmData: {
                ...confirmData,
                principalAddress: { ...dataUtilsOcrReceiptAddress },
                ...confirmData.credentialData,
              },
              dataPersist: { ...dataPersist },
            },
            processId: uuid,
            resource: "ProofAddressCondition",
            step: "CONTINUE",
          });
          setIsLoadingFile(false);
        }, 2000);
      }
    } else if (
      !dataUtilsOcrReceiptAddress &&
      errorUtilsOcrReceiptAddress &&
      !isLoadingUtilsOcrReceiptAddress
    ) {
      setIsModalError(true);
    }
  }, [dataUtilsOcrReceiptAddress, isLoadingUtilsOcrReceiptAddress, errorUtilsOcrReceiptAddress, navigate, dispatch, onClickContinue, confirmData, dataPersist, uuid, setIsModalError]);

  if (isLoadingUtilsOcrReceiptAddress) {
    return <Loader />;
  }

  if (isLoadingFile) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_0.mainText}
          subTitle={listText.text_1.mainText}
          displayProgressBar={"d-block"}
          displayLeftImageTitle={"d-block"}
          classTitleImage={"ms-2"}
          classHeader={"mt-2 mb-3"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"45%"}
          onClickGoBackIcon={() => navigate("/AddressCondition")}
        />
        <div className="p-3">
          <Paragraph text={listText.text_2.mainText} className={""} styleParagraph={undefined} />
          <div
            className="row text-center"
            style={{ display: "flex", flexDirection: "column", gap: 24, marginTop: 40 }}
          >
            <div className={`text-center ${formik.values.file ? "" : "d-none"}`}>
              <TitleAndImage
                srcImage={greenCircleCheckSharpSolid}
                titleText={`Comprobante ${!isSelectFile ? `capturado ` : `subido`} con éxito`}
                displayLeftImageTitle={""}
                classTitle={"ms-2"}
                styleTitle={{ color: "#33CD5E", fontSize: "16px", cursor: "", fontWeight: 700 }}
                onClickTitleImgeDiv={undefined}
                divClass={"mb-2"}
              />
            </div>
            <RadioToggleButton
              id={""}
              text={listText.text_5.mainText}
              value={undefined}
              type={""}
              name={""}
              onChange={() => {
                setSelectType(!isSelectType);
                setSelectFile(false);
              }}
              isSelected={undefined}
              icon={blue_camera_alt}
              className={"dynamic-style-button"}
              displayImg={""}
              disabledInput={false}
              divStyle={{}}
              styleInput={{ display: "none" }}
              labelStyle={formik.values.file ? { borderColor: "#FFF" } : {}}
              onClick={() => {
                setSelectType(!isSelectType);
                setSelectFile(false);
              }}
            />
            <RadioToggleButton
              id={listText.text_6.mainText}
              text={listText.text_6.mainText}
              value={undefined}
              type={""}
              styleInput={{ display: "none" }}
              name={listText.text_6.mainText}
              onChange={() => {
                setSelectType(true);
                setSelectFile(true);
              }}
              isSelected={undefined}
              icon={blue_upgrade}
              className={"dynamic-style-button"}
              displayImg={""}
              disabledInput={false}
              divStyle={undefined}
              labelStyle={undefined}
              onClick={() => {
                setSelectType(true);
                setSelectFile(true);
              }}
            />
          </div>
          <div className="mt-4">
            <TitleAndImage
              srcImage={circleInfoSharpRegular}
              titleText={listText.text_7.mainText}
              displayLeftImageTitle={""}
              classTitle={"m-2"}
              styleTitle={{
                color: "#005CB9",
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 400,
              }}
              onClickTitleImgeDiv={() => setOpenBottonSheet(true)}
              divClass={"mt-3 mb-3"}
            />
          </div>
        </div>
      </div>
      <Footer
        activeSpinner={isLoadingUtilsOcrReceiptAddress}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => onOk()}
        labelButtonPrimary={"Continuar"}
        labelButtonSecundary={listText.text_24.mainText}
        onClickButtonLink={() => followLaterAction()}
      />
      <SelectTypeFile
        isSelectFile={isSelectFile}
        onSelectTypeFile={capture}
        isOpen={isSelectType}
        onClickClose={() => setSelectType(false)}
      />

      <BottomSheetMessage
        imageInside={undefined}
        title={listText.text_7.mainText}
        text={listText.text_8.mainText}
        isOpen={isOpenBottonSheet}
        onClickClose={() => setOpenBottonSheet(false)}
        styleMainDiv={{ height: "auto" }}
      />
      {modaComponente}
    </>
  );
};

export default ProofAddressCondition;
