import { useEffect } from "react";
import Text from "../../../utils/dictionaries/dictionary-confirm-address";
import Select from "../../../components/molecules/select";
import Input from "../../../components/molecules/inputV2";
import { useApi } from "../../../hooks/useApi";
import { useRedux } from "../../../hooks/useRedux";
import UtilsCommonsService from "../../../services/utilsCommons.service";
import { IZipCode } from "../../../interfaces/api/IUtilsCommons";
import { allownumber } from "../../../utils/patterns";

interface IAlertData {
  formik: any;
  i: number;
  codePostRedux: string;
}

const { getZipCode } = new UtilsCommonsService();

const ConfirmAddress = ({ formik, i, codePostRedux }: IAlertData) => {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();

  const {
    data: dataGetZipCode = [],
    execute: executeGetZipCode,
    isLoading: isLoadingGetZipCode,
    error: isErrorZipCode,
  } = useApi<IZipCode[], { zipCode: number; processId: string }>(getZipCode);

  useEffect(() => {
    if (!isLoadingGetZipCode && !isErrorZipCode) {
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.state`, dataGetZipCode[0]?.state);
      formik.setFieldValue(
        `beneficiaries[${i}].confirmAddress.stateId`,
        dataGetZipCode[0]?.stateId,
      );
      formik.setFieldValue(
        `beneficiaries[${i}].confirmAddress.stateCRM`,
        dataGetZipCode[0]?.stateCRM,
      );
      formik.setFieldValue(
        `beneficiaries[${i}].confirmAddress.township`,
        dataGetZipCode[0]?.township,
      );
      formik.setFieldValue(
        `beneficiaries[${i}].confirmAddress.townshipId`,
        dataGetZipCode[0]?.townshipId,
      );
    } else {
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.state`, "");
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.stateId`, "");
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.stateCRM`, "");
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.township`, "");
      formik.setFieldValue(`beneficiaries[${i}].confirmAddress.townshipId`, "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorZipCode, isLoadingGetZipCode]);

  useEffect(() => {
    if (codePostRedux && formik.values.beneficiaries[i]?.confirmAddress.postalCode) {
      executeGetZipCode({
        zipCode: formik.values.beneficiaries[i]?.confirmAddress.postalCode,
        processId: uuid,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codePostRedux]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 mb-4">
          <Input
            name="postalCodes"
            value={formik.values.beneficiaries[i]?.confirmAddress.postalCode}
            text={Text.inputs.inputTextPostalCode}
            onChange={(e: any) => {
              if (allownumber.test(e.target.value)) {
                formik.setFieldValue(
                  `beneficiaries[${i}].confirmAddress.postalCode`,
                  e.target.value,
                );
              }
            }}
            onBlur={(e: any) => {
              if (e.isTrusted && formik.values.beneficiaries[i]?.confirmAddress.postalCode) {
                executeGetZipCode({
                  zipCode: formik.values.beneficiaries[i]?.confirmAddress.postalCode,
                  processId: uuid,
                });
              }
              formik.setFieldTouched(`beneficiaries[${i}].confirmAddress.postalCode`, e.isTrusted);
            }}
            error={
              !dataGetZipCode.length && !isLoadingGetZipCode && isErrorZipCode
                ? "Código postal no encontrado"
                : ""
            }
            className={"form-control"}
            placeholder={Text.inputs.inputTextPostalCode}
            type="text"
            maxLength={6}
            id="postalCode"
          />
        </div>
        <div className="col-12 mb-4">
          <Input
            text={Text.inputs.inputTextState}
            value={
              dataGetZipCode.length
                ? dataGetZipCode[0]?.state
                : formik.values.beneficiaries[i]?.confirmAddress.state
            }
            disabled={true}
            className={"form-control"}
            placeholder={Text.inputs.inputTextState}
            type="text"
            id="state"
          />
        </div>
        <div className="col-12 mb-2">
          <Input
            text={Text.inputs.inputTextMunicipality}
            value={
              dataGetZipCode.length
                ? dataGetZipCode[0]?.township
                : formik.values.beneficiaries[i]?.confirmAddress.township
            }
            disabled={true}
            className={"form-control"}
            placeholder={Text.inputs.inputTextMunicipality}
            type="text"
            id="municipality"
          />
        </div>
        <div className="col-12 mb-4">
          <Select
            name="suburbs"
            onChange={(e: any) =>
              formik.setFieldValue(
                `beneficiaries[${i}].confirmAddress.suburbs`,
                e.target.value.toUpperCase(),
              )
            }
            value={formik.values.beneficiaries[i]?.confirmAddress.suburbs}
            placeholder={Text.inputs.inputTextCologne}
            data={
              dataGetZipCode.length
                ? dataGetZipCode[0]?.suburbs.map((key) => ({ label: key, value: key }))
                : []
            }
          />
        </div>
        <div className="col-12 mb-4">
          <Input
            text={Text.inputs.inputTextStreet}
            value={formik.values.beneficiaries[i]?.confirmAddress.street}
            onChange={(e: any) =>
              formik.setFieldValue(
                `beneficiaries[${i}].confirmAddress.street`,
                e.target.value.toUpperCase(),
              )
            }
            className={"form-control"}
            placeholder={Text.inputs.inputTextStreet}
            type="text"
            id="street"
          />
        </div>
        <div className="col-6 ">
          <Input
            text={Text.inputs.inputTextOutsideNumber}
            value={formik.values.beneficiaries[i]?.confirmAddress.outsideNumber}
            onChange={(e: any) =>
              formik.setFieldValue(
                `beneficiaries[${i}].confirmAddress.outsideNumber`,
                e.target.value.toUpperCase(),
              )
            }
            className={"form-control"}
            placeholder={Text.inputs.inputTextOutsideNumber}
            type="text"
            id="street"
          />
        </div>
        <div className="col-6 ">
          <Input
            text={Text.inputs.inputTextInternalNumber}
            value={formik.values.confirmAddress?.internalNumber}
            onChange={(e: any) =>
              formik.setFieldValue(
                `beneficiaries[${i}].confirmAddress.internalNumber`,
                e.target.value.toUpperCase(),
              )
            }
            className={"form-control"}
            placeholder={Text.inputs.inputTextInternalNumber}
            type="text"
            id="street"
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmAddress;
