import { Label } from "../atoms/label";
import "../../styles/input.scss";
import IInput from "../../interfaces/input";

export default function Input(props: IInput) {
  return (
    <>
      <form className="form-floating" autoComplete="off">
        <input
          id={props.id}
          className={props.className}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          maxLength={props.maxLength}
          style={{
            background: `url(${props.src}) no-repeat scroll right center content-box`,
            backgroundSize: "24px 24px",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
            if (
              props.type === "number" &&
              (e.key === "ArrowLeft" ||
                e.key === "ArrowRight" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown" ||
                e.key == "." ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "e")
            ) {
              e.preventDefault();
            }
          }}
        />
        <Label text={props.text} htmlFor={props.htmlFor} className={props.displayLabel}></Label>
        {props.error && <small className="block dangerous-red">{props.error}</small>}
      </form>
    </>
  );
}
