const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Ayuda",
  },
  text_3: {
    id: "t3",
    mainText: "¿Necesitas apoyo?",
  },
  text_4: {
    id: "t4",
    mainText:
      "Llama a nuestra línea de ayuda Consubanco y un Ejecutivo te apoyará a aclarar cualquier duda que se presente durante tu proceso de apertura.",
  },
  text_5: {
    id: "t5",
    mainText: "¡Estamos para ti!",
  },
  text_6: {
    id: "t6",
    mainText: "Número Consulínea:",
  },
  text_7: {
    id: "t7",
    mainText: "800 800 32 24",
  },
  text_8: {
    id: "t8",
    mainText: "Llamar a un Ejecutivo",
  },
};

export default listText;
