import fetchBxiApiGateway from "./fetchBxiApiGateway";

const getInformation = () => {
  return fetchBxiApiGateway({
    url: "contracts/benefits-requirements",
    method: "GET",
  });
};

const getIneConsentData = () => {
  return fetchBxiApiGateway({
    url: "contracts/ine-consent",
    method: "GET",
  });
};

const getTermsAndConditionsData = () => {
  return fetchBxiApiGateway({
    url: "contracts/terms-and-conditions",
    method: "GET",
  });
};

const getPrivacyNoticesData = () => {
  return fetchBxiApiGateway({
    url: "contracts/privacy-notice",
    method: "GET",
  });
};

const getListContract = (uuid: string) => {
  return fetchBxiApiGateway({
    url: "contracts/list-contracts",
    method: "GET",
  });
};

export {
  getInformation,
  getIneConsentData,
  getTermsAndConditionsData,
  getPrivacyNoticesData,
  getListContract,
};
