import { useState } from "react";
import MessagesReactModal from "../components/templates/messages-react-modalV2";

export function useModalError() {
  const [isModaError, setIsModalError] = useState<boolean>(false);

  return {
    isModaError,
    setIsModalError,
    modaComponente: (
      <MessagesReactModal
        show={isModaError}
        onHide={() => setIsModalError(!isModaError)}
        textTitle={"¡Ups lo sentimos!"}
        listButtonsModal={[
          {
            text: "Reintentar",
            spinner: false,
            onClick: () => {
              setIsModalError(false);
            },
          },
        ]}
        onClickClose={() => setIsModalError(false)}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
          },
        ]}
      />
    ),
  };
}
