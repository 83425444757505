import { useEffect, useState } from "react";
import { Footer } from "../../../components/templates";
// Molecule 
import { RadioToggleButton } from "../../../components/molecules/radio-toggle-buttons";
// Atoms
import Input from "../../../components/molecules/inputV2";
import Select from "../../../components/molecules/select";
import Paragraph from "../../../components/atoms/paragraph";
import Title from "../../../components/atoms/titleV2";
import Loader from "../../../components/atoms/loader";
// Templates
import BottomSheetMessage from "../../../components/templates/bottom-sheet-message";
// Hooks
import { useRegimenFiscal } from "../../../hooks/useRegimenFiscal";
import { useModalError } from "../../../hooks/useModalError";
// Dictionaries
import listText from "../../../utils/dictionaries/dictionary-fiscal-information-client";
// Styes
import "./style.css";
export type TTypeTab = "confirm-address" | "alert-data";

interface IFiscalInformationClient {
  formik: any;
  onOk: () => void;
  onClickButtonLink: () => void;
  isLoadingFiscalInformation: boolean;
}

const FiscalInformationClient = ({
  formik,
  onOk,
  onClickButtonLink,
  isLoadingFiscalInformation,
}: IFiscalInformationClient) => {
  const { modaComponente, setIsModalError } = useModalError();
  const [isOpenTooltips, setIsOpenTooltips] = useState<boolean>(false);
  const { dataRegimenFiscal, errorRegimenFiscal, isLoadingRegimenFiscal } = useRegimenFiscal();

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (
      formik.values.valueRFC &&
      formik.values.homoclave &&
      formik.values.fiscalRegimeKey !== "EMPTY" &&
      formik.values.addressMatch
    ) {
      if (formik.isValid) {
        retVal = false;
      }
    } else {
      retVal = true;
    }

    return retVal;
  };

  useEffect(() => {
    if (!isLoadingRegimenFiscal && errorRegimenFiscal) {
      setIsModalError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRegimenFiscal, errorRegimenFiscal]);

  return (
    <>
      <main className="container_form mb-4_3">
        <div className=" mb-4">
          <Title text={listText.title.titleText} className={"container_form__title"} />
        </div>
        <>
          <Title text={listText.inputs.title} className={"container_form__title-inputs"} />
          <div className="row mt-3">
            <div className="col-6">
              <Input
                name="valueRFC"
                value={formik.values.valueRFC}
                text={listText.inputs.inputTextRFC}
                onChange={(e: any) =>
                  formik.setFieldValue("valueRFC", e.target.value.toUpperCase())
                }
                className={"form-control"}
                placeholder={"RFC"}
                type="text"
                id="rfc"
                maxLength={10}
                error={formik.errors.valueRFC}
              />
            </div>
            <div className="col-6">
              <Input
                value={formik.values.homoclave}
                text={listText.inputs.inputTextHomeClave}
                onChange={(e: any) =>
                  formik.setFieldValue("homoclave", e.target.value.toUpperCase())
                }
                className={"form-control"}
                placeholder={listText.inputs.inputTextHomeClave}
                type="text"
                id="homoclave"
                maxLength={3}
                error={formik.errors.homoclave}
              />
            </div>
          </div>
          <div className="mt-4">
            <Title text={listText.select.title} className={"container_form__title-inputs"} />
            <Select
              defaultValue={formik.values.fiscalRegimeKey}
              placeholder={"Selecciona una opción"}
              data={dataRegimenFiscal?.map((key) => ({
                value: key.key,
                label: key.description,
              }))}
              value={formik.values.fiscalRegimeKey}
              onChange={(e: any) => {
                if (e.target.value !== "EMPTY") {
                  const regimenFiscal = dataRegimenFiscal.find(
                    (data) => data.key === e.target.value,
                  );
                  formik.setFieldValue("regimenFiscal", regimenFiscal?.description);
                }
                formik.setFieldValue("fiscalRegimeKey", e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <Title text={listText.radio.title} className={"container_form__radio_title"} />
            <Paragraph
              text={listText.radio.description}
              className={"mt-3"}
              styleParagraph={undefined}
            />
            <div className="mb-4_3">
              <RadioToggleButton
                id={listText.radio.radioToggleButtons.toggleButtonsYes.id}
                text={listText.radio.radioToggleButtons.toggleButtonsYes.text}
                value={"Si"}
                type={"radio"}
                name={"addressMatch"}
                onChange={() => formik.setFieldValue("addressMatch", "yes")}
                isSelected={formik.values.addressMatch === "yes"}
                className="radioToggleButton me-3"
                icon={undefined}
                displayImg="d-none"
                disabledInput={undefined}
                divStyle={undefined}
                labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
                onClick={undefined}
              />
              <RadioToggleButton
                id={listText.radio.radioToggleButtons.toggleButtonsNo.id}
                text={listText.radio.radioToggleButtons.toggleButtonsNo.text}
                value={"No"}
                type={"radio"}
                name={"addressMatch"}
                onChange={() => formik.setFieldValue("addressMatch", "no")}
                isSelected={formik.values.addressMatch === "no"}
                className="radioToggleButton"
                icon={undefined}
                displayImg="d-none"
                disabledInput={undefined}
                divStyle={undefined}
                labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
                onClick={undefined}
              />
            </div>
          </div>
        </>
      </main>
      <Footer
        activeSpinner={isLoadingFiscalInformation}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => onOk()}
        labelButtonPrimary={listText.button.labelButtonPrimary}
        labelButtonSecundary={listText.button.labelButtonSecundary}
        onClickButtonLink={() => onClickButtonLink()}
      />
      <BottomSheetMessage
        imageInside={undefined}
        title={listText.infoToltipsText.bottomSheetMessage.title}
        text={listText.infoToltipsText.bottomSheetMessage.description}
        isOpen={isOpenTooltips}
        onClickClose={() => setIsOpenTooltips(!isOpenTooltips)}
        styleMainDiv={{ height: "auto" }}
      />
      {modaComponente}
    </>
  );
};

export default FiscalInformationClient;
