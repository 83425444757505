import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// Atoms
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import Paragraph from "../../components/atoms/paragraph";
import Title from "../../components/atoms/title";
import Loader from "../../components/atoms/loader";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import BottomSheetFieldModification from "../../components/templates/bottom-sheet-field-modification";
// Moleculas
import TitleAndImage from "../../components/molecules/title-and-image";
// Utils
import { dateISO } from "../../utils/helpers";
// Redux
import { addOTP } from "../../redux/slices/otpReducer";
import { store } from "../../redux/store";
import { changeEditableValue } from "../../redux/slices/navigateReducer";
import { changeEmail, changePhone, changeVerifyEmail } from "../../redux/slices/dataReducer";
import { videoRecordingData } from "../../redux/slices/videoRecordingReducer";
// Organisms
import InputDiamondComponent from "../../components/organisms/input-diamond";
// Styles
import "../../styles/input.scss";
import "../../styles/otpInput.scss";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Services
import contactInfoUtilService from "../../services/contactInfoUtil.service";
import ProcessContracts from "../../services/processContracts.service";
import AcceptAuthorizeUtilService from "../../services/acceptAuthorizeUtil.service";
import VideoCaptureUtilService from "../../services/viedoCaptureUtil.service";
import OtpUtilService from "../../services/otpUtil.service";
// Use-Case
import { ContactInfoUseCase } from "../../../application/use-cases/contactInfo.usecase";
import { classAcceptAuthorizeUseCase } from "../../../application/use-cases/acceptAuthorize.usecase";
import { ProcessContractsUseCase } from "../../../application/use-cases/processContracts.usecase";
import { VideoCaptureUseCase } from "../../../application/use-cases/videoCapture.usecase";
import { ValidateCodeUseCase } from "../../../application/use-cases/validateCode.usecase";
// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import penSharpRegular from "../../assets/icons/pen-sharp-regular.svg";
// Dictionaries
import { listText, objListText } from "../../utils/dictionaries/dictionary-validate-code";
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
import moment from "moment";


const otpService = new OtpUtilService();
const usecaseOtp = new ValidateCodeUseCase(otpService);

const contactInfoService = new contactInfoUtilService();
const usecaseContactInfo = new ContactInfoUseCase(contactInfoService);

const caseServiceAutorice = new AcceptAuthorizeUtilService();
const useCaseAutorize = new classAcceptAuthorizeUseCase(caseServiceAutorice);

const casePorcessContract = new ProcessContracts();
const useCaseProcessContract = new ProcessContractsUseCase(casePorcessContract);

const caseVideo = new VideoCaptureUtilService();
const useCaseVideo = new VideoCaptureUseCase(caseVideo);

const ValidateCode = () => {
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : undefined;

  const { onClickContinue } = useFollowLater();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = store.getState();
  const [modalShow, setModalShow] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);

  const [seconds, setSeconds] = useState(90);
  const [showValidateCode, setShowValidateCode] = useState(false);
  const [isLoadingStartVideo, setIsLoadingStartVideo] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>("");
  const [editableValueText, setEditableValueText] = useState<string | undefined>("");
  const [conditionShowInputs, setConditionShowInputs] = useState({
    phoneValidate: false,
    mailValidate: false,
  });
  const [displayErrorByCode, setDisplayErrorByCode] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [isOpenBottonSheet, setOpenBottonSheet] = useState(false);
  const [showValue, setShowValue] = useState(state.client.navigatePersist.editableValue);
  const [otpLength, setOtpLength] = useState(0);
  const [otp, setOtp] = useState("");
  const [showFooterText, setShowFooterText] = useState(false);

  const onChange = (value: string) => {
    const otpL = value.replace(/[^0-9]/g, "").trim().length;
    setDisplayErrorByCode(false)
    setOtpLength(otpL);
    setOtp(value);
    setDisabledButton(false);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setActiveSpinner(true);
    setShowValidateCode(true);
    setDisplayErrorByCode(false);

    const responseValidateOtp = await usecaseOtp.validateOtp(
      {
        registerCode: otp,
        phone: state.client.dataPersist.phone,
      },
      state.client.clientPersist.uuid,
    );

    if (responseValidateOtp.error !== null) {
      setDisplayErrorByCode(true);
      setDisabledButton(true);
      setActiveSpinner(false);
    } else {
      dispatch(
        addOTP({
          registerCode: otp,
        }),
      );
      if (state.client.navigatePersist.comeFrom !== "IdentityValidation") {
        switch (state.client.navigatePersist.typeValue) {
          case 'mail':
            trackAnalyticsEvent(
              AmplitudEventName.Confirmar_OTP_correo_N4,
              {
                "OTP Ingresado": otp,
                "Fecha": moment(new Date()).format('ll'),
                "Hora": moment(new Date()).format('LTS'),
                "Geolocalización": access_geoposition
              }
            )
            break;
          case 'phone':
            trackAnalyticsEvent(state.client.navigatePersist.nextContinue === '/Congratulation' ?
              AmplitudEventName.Firmar_con_OTP_N4 : AmplitudEventName.Confirmar_OTP_teléfono_N4,
              {
                "OTP Ingresado": otp,
                "Fecha": moment(new Date()).format('ll'),
                "Hora": moment(new Date()).format('LTS'),
                "Geolocalización": access_geoposition,
              }
            )
            break
          default:
        }
      }

      switch (state.client.navigatePersist.comeFrom) {
        case 'AccountOpening':
          const responseSendContactInfo = await usecaseContactInfo.sendContactInf({
            processId: state.client.clientPersist.uuid,
            isClient: state.client.dataPersist.isClient,
            phone: state.client.dataPersist.phone,
            email: state.client.dataPersist.email,
            emailVerify: true,
            acceptanceDate: dateISO(),
          });
          if (responseSendContactInfo.error === null) {
            dispatch(changeVerifyEmail(true));
            navigate(state.client.navigatePersist.nextContinue);
          }
          break;
        case 'IneConsent':

          const saveAcceptance = await useCaseAutorize.ineConsenAcceptances({
            processId: state.client.clientPersist.uuid,
            phone: state.client.dataPersist.phone,
            latitude: "",
            longitude: "",
            registerCode: otp,
            acceptanceDate: dateISO(),
          });

          if (saveAcceptance.error !== null) {
            setModalShow(true);
            setActiveSpinner(false);
          } else {
            navigate("/CaptureINE");
          }
          break;
        case 'ContractCondition':
          setShowFooterText(true);
          const saveSignatureData = await useCaseAutorize.saveSignatureData(
            {
              latitude: "",
              longitude: "",
              registerCode: otp,
              acceptanceDate: dateISO(),
              digitalSignatureB64: "",
            },
            state.client.clientPersist.uuid,
          );
          if (saveSignatureData.error !== null) {
            setModalShow(true);
            setShowValidateCode(false);
            setActiveSpinner(false);
            setShowFooterText(false);
            return;
          } else {
            const responseGenerateAccount = await useCaseProcessContract.getGenerateAccount(
              state.client.clientPersist.uuid,
            );
            if (responseGenerateAccount.error != null) {
              setModalShow(true);
              setActiveSpinner(false);
              setShowFooterText(false);
              return;
            } else {
              const responseCreateAccount = await useCaseProcessContract.createAccountClient(
                state.client.clientPersist.uuid,
              );
              if (responseCreateAccount.error != null) {
                setModalShow(true);
                setActiveSpinner(false);
                setShowFooterText(false);
                return;
              }
            }
          }
          navigate(state.client.navigatePersist.nextContinue);
          break;
        case 'IdentityValidation':
          onGenerateVideoUrlFunction();
          trackAnalyticsEvent(
            AmplitudEventName.Confirmar_OTP_videograbación_N4,
            {
              "OTP Ingresado": otp,
              "Fecha": moment(new Date()).format('ll'),
              "Hora": moment(new Date()).format('LTS'),
              "Geolocalización": access_geoposition,
            }
          )
          break;
        default:
          setShowValidateCode(true);
          setActiveSpinner(false);
          navigate("/IdentificationQuestionnaire");
      }
    }
    setShowValidateCode(false);
  };

  const onGenerateVideoUrlFunction = async () => {
    setIsLoadingStartVideo(true);
    const responseVideoRecording = await useCaseVideo.postVideoRecording(
      state.client.clientPersist.uuid,
    );
    //condition retoma
    if (responseVideoRecording.error !== null) {
      onClickContinue({
        data: {
          dataPersist: { ...state.client.dataPersist },
          videoRecordingData: { ...responseVideoRecording.data },
        },
        processId: state.client.clientPersist.uuid,
        resource: "VideoCaptureCondition",
        step: "CONTINUE",
      });
      dispatch(videoRecordingData({ ...responseVideoRecording.data, resource: "" }));
      navigate(state.client.navigatePersist.nextContinue);
    }
    setShowValidateCode(false);
  };

  useEffect(() => {
    const dataText = listText.find(
      (e: { id: string }) => e.id === state.client.navigatePersist.typeValue,
    );
    setTitle(dataText?.texts.text_1.mainText);
    setEditableValueText(dataText?.texts.text_2.mainText);
    if (state.client.navigatePersist.typeValue === "phone") {
      setConditionShowInputs({ phoneValidate: true, mailValidate: false });
    } else if (state.client.navigatePersist.typeValue === "mail") {
      setConditionShowInputs({ phoneValidate: false, mailValidate: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const resendOTP = async (value: any) => {
    setSeconds(90);
    setDisplayErrorByCode(false)
    setOtp("");
    await usecaseOtp.sendOtp(
      {
        phone:
          state.client.navigatePersist.typeValue === "phone"
            ? value
            : state.client.dataPersist.phone,
        email:
          state.client.navigatePersist.typeValue === "mail"
            ? value
            : state.client.dataPersist.email,
        channel: state.client.navigatePersist.typeValue === "mail" ? "EMAIL" : "SMS",
        date: dateISO(),
      },
      state.client.clientPersist.uuid,
    );
  };

  const changeValueFunction = (value: any) => {
    setShowValue(value);
    dispatch(changeEditableValue({ editableValue: value }));

    if (state.client.navigatePersist.typeValue === "phone") {
      dispatch(changePhone(value));
    } else {
      dispatch(changeEmail(value));
    }

    resendOTP(value);
    setOpenBottonSheet(false);
  };

  const goBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    state.client.navigatePersist.isReturn
      ? navigate(`/${state.client.navigatePersist.comeFrom}`)
      : null;
  };

  if (isLoadingStartVideo) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={"Apertura de cuenta"}
          subTitle={"Código OTP"}
          displayProgressBar={""}
          displayLeftImageTitle={!state.client.navigatePersist.isReturn ? "d-none" : ""}
          classTitleImage={state.client.navigatePersist.isReturn ? "ms-2" : ""}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={state.client.navigatePersist.progressBarPercentage}
          comeFrom={"/ValidateCode"}
          onClickGoBackIcon={() => goBack()}
        />

        {showValidateCode ? (
          <Loader />
        ) : (
          <div className="p-3">
            <Title
              text={title}
              className={"fs-6 fw-bold lh-base dark-blue-title"}
              styleTitle={undefined}
            />
            <div
              style={{ textAlign: "center", backgroundColor: "#F7F7F7" }}
              className="my-4 py-3 rounded-2 w-100"
            >
              <strong>{showValue}</strong>
              <TitleAndImage
                srcImage={penSharpRegular}
                titleText={editableValueText}
                displayLeftImageTitle={""}
                classTitle={"m-2"}
                styleTitle={{
                  color: "#5A97D5",
                  lineHeight: "16px",
                  textDecoration: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                onClickTitleImgeDiv={() => setOpenBottonSheet(true)}
                divClass={`mt-3 pb-2 ${state.client.navigatePersist.editableReceiverOtp ? "" : "d-none"
                  }`}
              />
            </div>
            <Paragraph
              text={"Si no recibes el código, espera 90 segundos y vuelve a solicitarlo."}
              className={"fs-6 mb-4_3"}
              styleParagraph={undefined}
            />
            <div className="d-flex justify-content-center">
              <InputDiamondComponent
                autoFocus
                isNumberInput
                length={6}
                classError={displayErrorByCode ? "border-dangerous-red" : ""}
                className="otpContainer"
                inputClassName="otpInput"
                value={otp}
                onChangeOTP={(otp) => onChange(otp)}
              />
            </div>
            <div className={`text-center ${displayErrorByCode ? 'pt-2' : 'py-4'}`}>

              <p style={{ color: "#C94444" }} className={displayErrorByCode ? "" : "d-none"}>
                {objListText.text_6.mainText}
              </p>

              {seconds > 0 ? (
                <p className="fs-6">
                  {"Reenviar código en"}
                  <span className="orange-color"> {seconds} </span>seg.
                </p>
              ) : (
                <></>
              )}
              <Link
                text={"Volver a solicitar"}
                url={null}
                styleLink={{
                  color: seconds > 0 ? "#CBCFD6" : "#004A94",
                  fontWeight: "600",
                  lineHeight: "20px",
                  pointerEvents: seconds > 0 ? "none" : "auto",
                  cursor: "pointer",
                }}
                onClick={() =>
                  resendOTP(
                    state.client.navigatePersist.typeValue === "mail"
                      ? state.client.dataPersist.email
                      : state.client.dataPersist.phone,
                  )
                }
                className={""}
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer--pin">
        {showValidateCode ? (
          <div className="text-center mt-3">
            {showFooterText ? <p>{objListText.text_5.mainText}</p> : <></>}
          </div>
        ) : (
          <>
            <Button
              text={state.client.navigatePersist.textButton}
              divClassName={""}
              buttonClassName={
                "btn-bg-primary text-white w-100 place-content-center align-self-center"
              }
              spinner={activeSpinner}
              onClick={(d: any) => onSubmit(d)}
              disabled={otpLength !== 6 || disabledButton}
              dataBsDismiss={undefined}
            />
          </>
        )}
      </div>
      <BottomSheetFieldModification
        title={editableValueText}
        textButton={editableValueText}
        conditionShowInputs={conditionShowInputs}
        isOpen={isOpenBottonSheet}
        onClickClose={() => setOpenBottonSheet(false)}
        changeValueFunction={(value: any) => changeValueFunction(value)}
      />
      <MessagesReactModal
        show={modalShow}
        onHide={() => {
          setActiveSpinner(false);
          setShowValidateCode(false);
          setModalShow(false);
        }}
        textTitle={objListText.text_1.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: objListText.text_2.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              // eslint-disable-next-line no-lone-blocks
              {
                setActiveSpinner(false);
                setShowValidateCode(false);
                setModalShow(false);
              }
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => {
          setActiveSpinner(false);
          setShowValidateCode(false);
          setModalShow(false);
        }}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: objListText.text_3.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: objListText.text_4.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default ValidateCode;