import { useState, useEffect } from "react";
import Top from "../../components/templates/top";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import listText from "../../utils/dictionaries/dictionary-customer-signature-show";
import { store } from "../../redux/store";
import Checkbox from "../../components/molecules/checkbox";
import AcceptAuthorizeUtilService from "../../services/acceptAuthorizeUtil.service";
import { classAcceptAuthorizeUseCase } from "../../../application/use-cases/acceptAuthorize.usecase";
import { dateISO } from "../../utils/helpers";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import ProcessContracts from "../../services/processContracts.service";
import { ProcessContractsUseCase } from "../../../application/use-cases/processContracts.usecase";
import { useFollowLater } from "../../hooks/useFollowLater";
import Loader from "../../components/atoms/loader";


// Amplitud
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
import moment from "moment";

const CustomerSignatureShow = () => {
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : "";
  const { onClickContinue } = useFollowLater();
  const navigate = useNavigate();
  const state = store.getState();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [checked, setChecked] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const caseServiceAutorice = new AcceptAuthorizeUtilService();
  const useCaseAutorize = new classAcceptAuthorizeUseCase(caseServiceAutorice);

  const casePorcessContract = new ProcessContracts();
  const useCaseProcessContract = new ProcessContractsUseCase(casePorcessContract);

  const continueProcess = async (checked: boolean) => {
    setActiveSpinner(true);
    setChecked(!checked);
    setShowLoader(true);
    let imgB64 = "";
    if (state.client.contractSignaturePersist.signatureImageBase64 != null) {
      imgB64 = state.client.contractSignaturePersist.signatureImageBase64;
      imgB64 = imgB64.replace("data:image/png;base64,", "");
    }
    const saveSignatureData = await useCaseAutorize.saveSignatureData(
      {
        latitude: "",
        longitude: "",
        registerCode: "",
        acceptanceDate: dateISO(),
        digitalSignatureB64: imgB64,
      },
      state.client.clientPersist.uuid,
    );
    if (saveSignatureData.error !== null) {
      setModalShow(true);
      setActiveSpinner(false);
      setChecked(!checked);
      setShowLoader(false);
      return;
    } else {
      const responseGenerateAccount = await useCaseProcessContract.getGenerateAccount(
        state.client.clientPersist.uuid,
      );
      if (responseGenerateAccount.error != null) {
        setModalShow(true);
        setActiveSpinner(false);
        setChecked(!checked);
        setShowLoader(false);
        return;
      } else {
        const responseCreateAccount = await useCaseProcessContract.createAccountClient(
          state.client.clientPersist.uuid,
        );
        if (responseCreateAccount.error !== null) {
          setModalShow(true);
          setActiveSpinner(false);
          setChecked(!checked);
          setShowLoader(false);
          return;
        }
      }
    }
    onClickContinue({
      data: {},
      processId: state.client.clientPersist.uuid,
      resource: "LivenessCheck",
      step: "CONTINUE",
    });
    trackAnalyticsEvent(
      AmplitudEventName.Firmar_manualmente_contrato_N4      ,
      { "Firma": imgB64 ,
        "Fecha": moment(new Date()).format('ll'),
        "Hora": moment(new Date()).format('LTS'),
        "Geolocalizacion":access_geoposition
      }
    )
    navigate("/Congratulation");
  };
  const acceptAuthorize = (value: boolean) => {
    setChecked(!value);
  };

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={""}
          classTitleImage={"ms-2"}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"85%"}
          comeFrom={"/CustomerSignatureShow"}
          onClickGoBackIcon={() => navigate("/ContractCondition")}
        />
        {showLoader ? (
          <Loader />
        ) : (
          <div className="p-3">
            <div className={`text-center my-5`} style={{ backgroundColor: "#F7F7F7" }}>
              {state.client.contractSignaturePersist.signatureImageBase64 ? (
                <img
                  className={"signature-image rounded-3"}
                  src={state.client.contractSignaturePersist.signatureImageBase64}
                  alt="user generated signature"
                />
              ) : null}
            </div>
            <div
              className="text-center mt-4"
              style={{ color: "#004A94", fontWeight: 500 }}
              onClick={() => navigate("/CustomerSignatureAction")}
            >
              {listText.text_5.mainText}
            </div>
            <Checkbox
              text={listText.text_8.mainText}
              htmlFor={"flexCheckDefault"}
              classNameLabel={"fs-6"}
              classCheckbox={"form-check my-4_2"}
              checked={checked}
              onChange={() => {
                acceptAuthorize(checked);
              }}
            />
          </div>
        )}
      </div>

      <div className="footer--pin">
        {showLoader ? (
          <div className="text-center mt-3">
            <p>{listText.text_13.mainText}</p>
          </div>
        ) : (
          <>
            <Button
              text={listText.text_6.mainText}
              divClassName={"d-grid gap-2"}
              buttonClassName={`${
                checked ? `button-enabled` : `button-disabled`
              } w-100 place-content-center align-self-center`}
              spinner={activeSpinner}
              onClick={() => {
                continueProcess(checked);
              }}
              disabled={!checked}
              dataBsDismiss={undefined}
            />
            <div className="text-center mt-3">
              <Link
                text={listText.text_7.mainText}
                url={null}
                styleLink={{
                  color: "#5A6172",
                  lineHeight: "16px",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => alert("Seguir más tarde")}
                className={""}
              />
            </div>
          </>
        )}
      </div>

      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_9.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_10.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => setModalShow(false),
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_11.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_12.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default CustomerSignatureShow;
