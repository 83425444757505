import { createSlice } from "@reduxjs/toolkit";
import { getUid } from "../../utils/utils";

const initialState = {
  data: [
    {
      id: getUid(),
      name: "",
      fatherLastName: "",
      motherLastName: "",
      relationship: "",
      relationshipKey: "",
      date: {
        day: "",
        dayError: false, 
        month: "",
        monthError: false, 

        year: "",
      },
      dateBirth: "",
      profitPercentage: "",
      sameAddress: "",
      confirmAddress: {
        postalCode: "",
        state: "",
        stateId: "",
        suburbs: "",
        stateCRM: "",
        township: "",
        townshipId: "",
        street: "",
        outsideNumber: "",
        internalNumber: "",
      },
    },
  ],
};

export const dataSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    onBeneficiaries: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { onBeneficiaries } = dataSlice.actions;

export default dataSlice.reducer;
