const listText = {
  text_1: {
    id: "t1",
    mainText: "Firma tu contrato",
  },
  text_2: {
    id: "t2",
    mainText: "Gira tu teléfono",
  },
  text_3: {
    id: "t3",
    mainText: "Por favor gira tu teléfono para facilitar la firma de tu contrato.",
  },
  text_4: {
    id: "t4",
    mainText: "Recuerda que tu firma debe ser lo más parecido a la de tu identificación. ",
  },
  text_5: {
    id: "t5",
    mainText: "Coloca tu firma en esta dirección.",
  },
};

export default listText;
