const listText = [
  {
    id: "mail",
    texts: {
      text_1: {
        id: "t1",
        mainText: "Te enviamos un código de 6 dígitos al correo:",
      },
      text_2: {
        id: "t2",
        mainText: "Cambiar correo electrónico",
      },
      text_3: {
        id: "t3",
        mainText: "Continuar",
      },
    },
  },
  {
    id: "phone",
    texts: {
      text_1: {
        id: "t1",
        mainText: "Te enviamos un código de 6 dígitos al número:",
      },
      text_2: {
        id: "t2",
        mainText: "Cambiar número",
      },
      text_3: {
        id: "t3",
        mainText: "Continuar",
      },
    },
  },
];

const objListText = {
  text_1: {
    id: "t1",
    mainText: "¡Ups lo sentimos!",
  },
  text_2: {
    id: "t2",
    mainText: "Reintentar",
  },
  text_3: {
    id: "t3",
    mainText:
      "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_4: {
    id: "t4",
    mainText: "Vuelve a intentar si el error persiste.",
  },
  text_5: {
    id: "t5",
    mainText:
      "Tu cuenta se esta creando. Es importante que no cierres la aplicación, por favor, espera unos segundos más.",
  },
  text_6: {
    id: "t6",
    mainText: "El código ingresado no es válido."
  },
  text_7: {
    id: "t7",
    mainText: "Revisa tu código y vuelve a ingresarlo.",
  },
};

export { listText, objListText };