import IsendContactInformationService from "../../domain/services/IContactInformation.service";

export class ContactInfoUseCase {
  constructor(private contactInfo: IsendContactInformationService) {}
  public async sendContactInf(request: any) {
    try {
      const response = await this.contactInfo.sendContactInformation(request);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error al generar el OTP");
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
}
