import "./App.scss";
import "./index.scss";
import { AppRoute } from "./infraestructure/routes";
import moment from "moment";
import * as amplitude from "@amplitude/analytics-browser";
import { amplitudAPIKEY } from "./env";

import packagejson from "../package.json";
import { useEffect } from "react";

function App() {
  moment.locale("es");
  useEffect(() => {
    amplitude.init(amplitudAPIKEY || "", {
      defaultTracking: false,
     }
    );
  }, []);

  return (
    <div>
      <AppRoute />
      <p style={{ width: "100%", textAlign: "center", color: "rgb(90, 97, 114)", fontSize: 9 }}>
        {`${packagejson.version}`}
      </p>
    </div>
  );
}

export default App;
