
import { useDispatch, } from "react-redux";
import { store } from "../redux/store";

export function useRedux() {
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  type AppDispatch = typeof store.dispatch;
  const useAppDispatch: () => AppDispatch = useDispatch;
  const dispatch = useAppDispatch();
  const state = store.getState();
  return {
    dispatch,
    useAppDispatch,
    data: state.client,
  };
}
