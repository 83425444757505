import IVideoCaptureService from "../../domain/services/IVideoCapture.service";

interface IDataVideoRecording {
  uuid: string;
  token: string;
  token_encoded: string;
  public_url: string;
}

export class VideoCaptureUseCase {
  constructor(private videoCaptureSrv: IVideoCaptureService) {}

  public async postVideoRecording(
    processId: string,
  ): Promise<{ data: IDataVideoRecording; error: string }> {
    try {
      const response = await this.videoCaptureSrv.videoRecording(processId);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return {
          data: { uuid: "", token: "", token_encoded: "", public_url: "" },
          error: `Ocurrio un error, status: ${response.status}`,
        };

      return { data: response.data, error: "" };
    } catch (error) {
      console.error("Error al obtener url video recording");
      return {
        data: { uuid: "", token: "", token_encoded: "", public_url: "" },
        error: `Ocurrio un error ${error}`,
      };
    }
  }

  public async postVideoRecordingRedirect(processId: string, urlRedirect: string) {
    try {
      const response = await this.videoCaptureSrv.videoRecordingRedirect(processId, urlRedirect);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error al obtener url video recording");
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }

  public async getVideoRecordingWorkflows(
    processId: string,
    uuidVideoCapture: string,
    methodType: string,
  ) {
    try {
      const response = await this.videoCaptureSrv.videoRecordingWorkflows(
        processId,
        uuidVideoCapture,
        methodType,
      );
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error al obtener url video recording");
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
}
