import React from "react";

export const TelmexIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3949 50.1161V48.7748H60.6534V47.076H52.1591V55.5252H60.6534V53.8264H54.3949V51.7696L59.7144 51.8149V50.1161H54.3949ZM71.83 55.5252L67.9453 51.0204L71.4728 47.076H68.7008L66.5843 49.4427L64.5435 47.076H61.7707L65.0864 51.1184L61.1456 55.5252H63.917L66.4124 52.7349L68.7008 55.5252H71.83ZM29.7607 47.076V55.4807H38.2108V53.8264H32.0409V47.076H29.7607ZM10 47.076V48.7748H13.3534V55.5252H15.5885V48.7748H18.9416V47.076H10ZM39.0602 47.076V55.5252H41.2953V48.7748L44.0674 55.5252H45.6766L48.538 48.7748V55.5252H50.8184V47.076H46.8393L44.8718 51.9492L42.9941 47.076H39.0602ZM22.1604 50.1161V48.7748H28.3749V47.076H19.9251V55.5252H28.3749V53.8264H22.1604V51.7696L27.4361 51.8149V50.1161H22.1604Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3463 29.5153L36.1501 29.4523V36.4544H45.9282V29.5153H51.6056L58.7345 42.2582H52.9941L49.3346 36.4544L41.0079 42.2582L32.7435 36.3919L29.0219 42.2582H23.2812L30.3463 29.5153ZM58.7344 29.4523V23.5222H23.2812V29.4523H29.0216L29.7153 28.1272H37.4752V35.0666H44.6031V28.1272H52.2364L52.9937 29.4523H58.7344Z"
        fill="#0084C0"
      />
      <path
        d="M76.1421 55.9494C75.7911 56.302 75.3671 56.478 74.8694 56.478C74.3718 56.478 73.9486 56.302 73.5994 55.9494C73.2519 55.5983 73.0781 55.1736 73.0781 54.6739C73.0781 54.1798 73.2537 53.7569 73.6042 53.406C73.9534 53.0568 74.375 52.8821 74.8694 52.8821C75.3671 52.8821 75.7911 53.0568 76.1421 53.406C76.4927 53.7552 76.6681 54.1779 76.6681 54.6739C76.6683 55.1717 76.4927 55.5972 76.1421 55.9494ZM73.7781 53.5814C73.4781 53.8836 73.3281 54.2487 73.3281 54.6766C73.3281 55.1077 73.4771 55.4748 73.7757 55.7787C74.0756 56.0821 74.4402 56.2342 74.869 56.2342C75.2978 56.2342 75.6621 56.0821 75.9622 55.7787C76.2621 55.4746 76.4123 55.1076 76.4123 54.6766C76.4123 54.2487 76.2621 53.8834 75.9622 53.5814C75.6605 53.278 75.2962 53.1259 74.869 53.1259C74.4433 53.1259 74.0798 53.278 73.7781 53.5814ZM74.8351 53.6831C75.0802 53.6831 75.2598 53.7069 75.374 53.7543C75.5781 53.8389 75.6801 54.0055 75.6801 54.2537C75.6801 54.43 75.616 54.5598 75.4879 54.643C75.4196 54.6869 75.3245 54.719 75.2029 54.7384C75.3576 54.7629 75.471 54.8273 75.5426 54.9317C75.6143 55.0361 75.6501 55.1381 75.6501 55.2375V55.3818C75.6501 55.4275 75.6517 55.4765 75.6547 55.5286C75.6581 55.5806 75.6634 55.6148 75.6715 55.6312L75.6832 55.6556H75.3598C75.3582 55.649 75.3565 55.6428 75.355 55.6362C75.3531 55.6289 75.3516 55.6216 75.3504 55.6142L75.343 55.5507V55.3941C75.343 55.166 75.2803 55.0155 75.1558 54.9421C75.0818 54.8997 74.9522 54.8787 74.7665 54.8787H74.4905V55.6555H74.1442V53.6831H74.8351ZM75.2113 53.9946C75.1232 53.9456 74.9825 53.9212 74.7888 53.9212H74.4907V54.6348H74.806C74.9538 54.6348 75.0646 54.6201 75.1387 54.591C75.2749 54.5373 75.343 54.4344 75.343 54.2829C75.3431 54.1395 75.299 54.0433 75.2113 53.9946Z"
        fill="black"
      />
    </svg>
  );
};
