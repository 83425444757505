import React from "react";
import "../../styles/button.scss";
import Spinner from "./spinner";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  divClassName?: string;
  buttonClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dataBsDismiss?: string;
  img?: string | any;
  classNameImg?: string;
  spinner?: boolean;
  text?: string;
  styleButton?: React.CSSProperties;
}

const Button: React.FC<IButton> = (props) => {
  return (
    <div className={props.divClassName}>
      <button
        className={props.buttonClassName}
        type="button"
        onClick={props.onClick}
        disabled={props.disabled}
        data-bs-dismiss={props.dataBsDismiss}
        style={props.styleButton}
      >
        {props.img && <img className={props.classNameImg} src={props.img} alt="" />}
        {props.spinner ? <Spinner></Spinner> : props.text}
      </button>
    </div>
  );
};

export default Button;
