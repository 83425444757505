import * as Yup from "yup";

 const Schema =  () =>
  Yup.object({
    valueRFC: Yup.string()
      .required("")
      .max(13, "Máximo 13 caracteres.")
      .matches(
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        "RfC invalidó",
      ),
    homoclave: Yup.string()
      .required("")
      .max(3, "Máximo 3 caracteres.")
      .matches(/^[A-Z0-9]{3}/, "Homoclave invalidó"),
    addressMatch: Yup.string().required(""),
    regimenFiscal: Yup.string().required(""),
  });

  export default Schema;