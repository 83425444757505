import blue_left_arrow from "../../assets/icons/blue-left-arrow.svg";
import { useNavigate } from "react-router-dom";
import * as React from "react";

const DynamicHeader = (props: any) => {
  const navigate = useNavigate();
  const levelRout = () => {
    navigate("/InformationRequirements");
  };
  return (
    <header className="p-3 webkit-fill-available" style={{ background: "white" }}>
      <img
        src={"" + blue_left_arrow}
        alt="ic_csb"
        className="me-4"
        style={{ width: "1.5rem", cursor: "pointer" }}
        onClick={() => levelRout()}
      />
      <strong style={{ color: "#00254A", fontSize: "20px" }}>{props.titleText}</strong>
    </header>
  );
};

export default DynamicHeader;
