import IDocsService from "../../domain/services/IDocs.service";

export class DocsUseCase {
  constructor(private docsService: IDocsService) {}
  public async getDigitalContractDoc() {
    try {
      const response = await this.docsService.getDigitalContract();
      if (!response.data) return { data: null, error: "Ocurrio un error" };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }
  public async getCaratulaInversionesDoc() {
    try {
      const response = await this.docsService.getCaratulaInversiones();
      if (!response.data) return { data: null, error: "Ocurrio un error" };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }
  public async getIdentificationQuestionnaireDoc(processId: string) {
    try {
      const response = await this.docsService.getidentificationQuestionnaire(processId);
      if (!response.data) return { data: null, error: "Ocurrio un error" };

      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }
}
