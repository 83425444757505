const listText = {
  top: {
    id: "top-component",
    titleHeader: "Apertura de cuenta",
    subTitle: "Información del cliente",
  },
  title: {
    id: "title",
    titleText: "Información laboral",
  },
  selectOccupation: {
    id: "t5",
    title: "Profesión u ocupación",
    placeholder: "Selecciona una profesión u ocupación",
  },
  selectActivities: {
    id: "t5",
    title: "Giro y actividades de alto riesgo",
    placeholder: "Selecciona una opción giro y actividades de alto riesgo",
  },
  selectSector: {
    id: "t5",
    title: "Sector",
    placeholder: "Selecciona una opción",
  },
  button: {
    labelButtonPrimary: "Continuar",
    labelButtonSecundary: "Seguir más tarde",
  },
};

export default listText;
