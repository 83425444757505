import * as React from "react";

interface ILabel
  extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  text: string;
}

export const Label = (props: ILabel) => {
  return (
    <>
      <label {...props} className={props.className}>
        {props.text}
      </label>
    </>
  );
};
