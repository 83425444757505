import * as React from "react";
import Image from "../atoms/image";
import Title from "../atoms/title";

const TitleAndImage = ({
  srcImage,
  titleText,
  displayLeftImageTitle,
  classTitle,
  styleTitle,
  onClickGoBackIcon,
  onClickTitleImgeDiv,
  divClass,
}: any) => {
  return (
    <div className={divClass} onClick={onClickTitleImgeDiv}>
      <Image
        srcImage={srcImage}
        className={srcImage ? displayLeftImageTitle + "" : "d-none"}
        alt={""}
        dataBsDismiss={undefined}
        styleImg={{ cursor: "pointer" }}
        onClick={onClickGoBackIcon}
      />
      <Title text={titleText} className={classTitle} styleTitle={styleTitle} />
    </div>
  );
};

export default TitleAndImage;
