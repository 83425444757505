const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Cuestionario de identificación",
  },
  text_3: {
    id: "t3",
    mainText: "Para finalizar tu trámite de apertura es necesario confirmar tus datos.",
  },
  text_4: {
    id: "t4",
    mainText:
      "Por favor consulta el siguiente cuestionario para confirmar que la información capturada y la INE incluida son correctas.",
  },
  text_5: {
    id: "t5",
    mainText: "Cuestionario de identificación",
  },
  text_6: {
    id: "t6",
    mainText: "Acepto documentación y confirmo envío",
  },
  text_7: {
    id: "t7",
    mainText: "Continuar",
  },
  text_8: {
    id: "t8",
    mainText: "Seguir más tarde",
  },
  text_24: {
    id: "t24",
    mainText: "¡Ups lo sentimos!",
  },
  text_25: {
    id: "t25",
    mainText: "Reintentar",
  },
  text_26: {
    id: "t26",
    mainText:
      "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_27: {
    id: "t27",
    mainText: "Vuelve a intentar si el error persiste.",
  },
};

export default listText;
