//
// This is an example self-contained class to perform Photo ID Scans with the FaceTec SDK.
// You may choose to further componentize parts of this in your own Apps based on your specific requirements.
import { trackAnalyticsEvent } from "../../../utils/amplitude";
import type {
  FaceTecIDScanProcessor,
  FaceTecIDScanResult,
  FaceTecIDScanResultCallback,
} from "../../../assets/core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { FaceTecSDK } from "../../../assets/core-sdk/FaceTecSDK.js/FaceTecSDK";
import { Config } from "../../Config";
import { SampleAppControllerReference } from "../../sampleAppControllerReference/SampleAppControllerReference";
import { AmplitudEventName } from "../../../utils/amplitude/types";

export class PhotoIDScanProcessor implements FaceTecIDScanProcessor {
  latestNetworkRequest: XMLHttpRequest = new XMLHttpRequest();
  endCapture = false;
  public latestIDScanResult: FaceTecIDScanResult | null;
  //
  // DEVELOPER NOTE:  These properties are for demonstration purposes only so the Sample App can get information about what is happening in the processor.
  // In the code in your own App, you can pass around signals, flags, intermediates, and results however you would like.
  //
  success: boolean;
  sampleAppControllerReference: SampleAppControllerReference;

  constructor(sessionToken: string, sampleAppControllerReference: any) {
    //
    // DEVELOPER NOTE:  These properties are for demonstration purposes only so the Sample App can get information about what is happening in the processor.
    // In the code in your own App, you can pass around signals, flags, intermediates, and results however you would like.
    //

    this.success = false;
    this.sampleAppControllerReference = sampleAppControllerReference;
    this.latestIDScanResult = null;

    // In v9.2.2+, configure the messages that will be displayed to the User in each of the possible cases.
    // Based on the internal processing and decision logic about how the flow gets advanced, the FaceTec SDK will use the appropriate, configured message.
    FaceTecSDK.FaceTecCustomization.setIDScanUploadMessageOverrides(
      "Cargando<br/>Encriptando<br/>Escaneo de anverso de la identificación", // Upload of ID front-side has started.
      "Subiendo...<br/>Conexión lenta", // Upload of ID front-side is still uploading to Server after an extended period of time.
      "Carga completa", // Upload of ID front-side to the Server is complete.
      "Procesando<br/>Escaneo de la identificación", // Upload of ID front-side is complete and we are waiting for the Server to finish processing and respond.
      "Cargando<br/>Encriptando<br/>Escaneo de reverso de la identificación", // Upload of ID back-side has started.
      "Subiendo...<br/>Conexión lenta", // Upload of ID back-side is still uploading to Server after an extended period of time.
      "Carga completa", // Upload of ID back-side to Server is complete.
      "Procesando<br/>Escaneo de reverso de la identificación", // Upload of ID back-side is complete and we are waiting for the Server to finish processing and respond.
      "Cargando<br/>Tu información confirmada", // Upload of User Confirmed Info has started.
      "Subiendo...<br/>Conexión lenta", // Upload of User Confirmed Info is still uploading to Server after an extended period of time.
      "Carga completa", // Upload of User Confirmed Info to the Server is complete.
      "Procesando", // Upload of User Confirmed Info is complete and we are waiting for the Server to finish processing and respond.
    );

    //
    // Parte 1: Iniciar la sesión de escaneo de identificación con fotografía de FaceTec
    //
    // Parámetros requeridos:
    // - FaceTecIDScanProcessor: una clase que implementa FaceTecIDScanProcessor, que maneja el escaneo de identificación con fotografía cuando el usuario completa una sesión. En este ejemplo, "esto" implementa la clase.
    // - sessionToken: un token de sesión válido que acaba de crear llamando a su API para obtener un token de sesión del SDK del servidor.
    //
    new FaceTecSDK.FaceTecSession(this, sessionToken);
  }

  //
  // Parte 2: Manejo del resultado de un escaneo de identificación con fotografía
  //
  public processIDScanResultWhileFaceTecSDKWaits = (
    idScanResult: FaceTecIDScanResult,
    idScanResultCallback: FaceTecIDScanResultCallback,
  ): void => {
    this.latestIDScanResult = idScanResult;

    //
    // Parte 3: Maneja escenarios de salida anticipada donde no hay ningún resultado de escaneo de identificación con fotografía que manejar, es decir, cancelación de usuario, tiempos de espera, etc.
    //
    if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
      console.log("El escaneo de la identificación no se completó correctamente, cancelado.");
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_INE_N4,
        { Captura_realizada: "No exitosa", No_de_intentos: JSON.parse(localStorage.getItem("numberAttempts") || "0") }
      )
      localStorage.setItem("condition", JSON.stringify(false));
      this.latestNetworkRequest.abort();
      this.latestNetworkRequest = new XMLHttpRequest();
      idScanResultCallback.cancel();
      return;
    }

    // IMPORTANTE: FaceTecSDK.FaceTecIDScanStatus.Success NO significa que el escaneo de identificación con foto fue exitoso.
    // Simplemente significa que el Usuario completó la Sesión. Aún debe realizar las verificaciones de resultados del escaneo de identificación con fotografía en sus servidores.

    //
    // Parte 4: Obtener datos esenciales de FaceTecIDScanResult
    //
    var parameters: any = {
      idScan: idScanResult.idScan,
    };
    //
    // El envío de imágenes del frente y del reverso no es esencial, pero es útil para fines de auditoría y es necesario para que el panel del servidor FaceTec se represente correctamente.
    //
    if (idScanResult.frontImages && idScanResult.frontImages[0]) {
      parameters.idScanFrontImage = idScanResult.frontImages[0];
    }

    if (idScanResult.backImages && idScanResult.backImages[0]) {
      parameters.idScanBackImage = idScanResult.backImages[0];
    }

    //
    // Parte 5: Realice la llamada de red a sus servidores. A continuación se muestra un código de ejemplo, que puede personalizar según cómo funciona su propia API.
    //
    this.latestNetworkRequest = new XMLHttpRequest();
    this.latestNetworkRequest.open("POST", Config.BaseURL + "/idscan-only");
    this.latestNetworkRequest.setRequestHeader("Content-Type", "application/json");
    this.latestNetworkRequest.setRequestHeader("process-id", Config.ProcessID);
    this.latestNetworkRequest.setRequestHeader("Authorization", "Bearer " + Config.AuthToken);
    this.latestNetworkRequest.setRequestHeader("X-GeoPosition", Config.GeoPosition);
    this.latestNetworkRequest.setRequestHeader("X-CallerIP", Config.CallerIP);

    this.latestNetworkRequest.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
    this.latestNetworkRequest.setRequestHeader(
      "X-User-Agent",
      FaceTecSDK.createFaceTecAPIUserAgentString(idScanResult.sessionId as string),
    );

    this.latestNetworkRequest.onreadystatechange = (): void => {
      //
      // Parte 6: En nuestro ejemplo, evaluamos una respuesta booleana y tratamos verdadero como si se procesara exitosamente y deberíamos continuar con el siguiente paso.
      // y manejar todas las demás respuestas cancelando.
      // Puede tener diferentes paradigmas en su propia API y puede personalizarlos en función de ellos.
      //
      if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        try {
          const responseJSON = JSON.parse(this.latestNetworkRequest.responseText);
          const scanResultBlob = responseJSON.scanResultBlob;
          localStorage.setItem("CaptureIneData", JSON.stringify(responseJSON));

          if (responseJSON.wasProcessed) {
            FaceTecSDK.FaceTecCustomization.setIDScanResultScreenMessageOverrides(
              "Escaneo completo de anverso de la identificación", // Successful scan of ID front-side (ID Types with no back-side).
              "Anverso de la identificación<br/>escaneado", // Successful scan of ID front-side (ID Types that do have a back-side).
              "Escaneo completo de reverso de la identificación", // Successful scan of the ID back-side.
              "Escaneo completo", // Successful scan of a Passport
              "Escaneo de identificación con foto<br/>completo", // Successful upload of final Photo ID Scan result containing User-Confirmed ID Text.
              "El rostro no coincide<br/>lo suficiente", // Case where a Retry is needed because the Face on the Photo ID did not Match the User's Face highly enough.
              "Documento de identidad<br/>no es visible completamente", // Case where a Retry is needed because a Full ID was not detected with high enough confidence.
              "Texto de la identificación no legible", // Case where a Retry is needed because the OCR did not produce good enough results and the User should Retry with a better capture.
              "<h2 style=margin-top:24px;font-size:20px;font-weight:700;line-height:normal;color:#00376F;>¡Ups!</h2> <p style=margin-top:17px;font-size:16px;font-weight:400;line-height:24px;color:#363A44;>Tu INE no fue capturada correctamente, vuelve a intentarlo.</p>", // Case where there is likely no OCR Template installed for the document the User is attempting to scan.
            );

            if (responseJSON.data.photoIDBackCrop !== '') {
              this.endCapture = true
            }
            // En v9.2.0+, simplemente pase scanResultBlob a la función procederToNextStep para avanzar en el flujo de usuarios.
            // scanResultBlob es un blob cifrado y propietario que controla la lógica de lo que sucede a continuación para el usuario.
            // Casos:
            // 1. El usuario debe volver a escanear el mismo lado de la identificación que acaba de intentar.
            // 2. El usuario logró escanear el anverso de la identificación, no hay reverso y ahora se envía al usuario a la interfaz de usuario de confirmación de OCR del usuario.
            // 3. El usuario logró escanear el anverso de la identificación, hay un reverso y el usuario es enviado a la interfaz de usuario de captura automática para el reverso de su identificación.
            // 4. El usuario logró escanear la parte posterior de la identificación y ahora se envía al usuario a la interfaz de usuario de confirmación de OCR del usuario.
            // 5. Todo el proceso está completo. Esto ocurre después de enviar el resultado final del escaneo de identificación con fotografía que contiene los datos de OCR del usuario.
            idScanResultCallback.proceedToNextStep(scanResultBlob);
          } else {
            // CASE:  UNEXPECTED response from API.  Our Sample Code keys off a wasProcessed boolean on the root of the JSON object --> You define your own API contracts with yourself and may choose to do something different here based on the error.
            console.log("Respuesta de API inesperada, cancelado.");
            trackAnalyticsEvent(
              AmplitudEventName.Capturar_INE_N4,
              { Captura_realizada: "No exitosa", No_de_intentos: JSON.parse(localStorage.getItem("numberAttempts") || "0") }
            )
            localStorage.setItem("condition", JSON.stringify(false));
            idScanResultCallback.cancel();
          }
        } catch {
          // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself and may choose to do something different here based on the error.  Solid server-side code should ensure you don't get to this case.
          console.log("Excepción al manejar la respuesta del API, cancelado.");
          trackAnalyticsEvent(
            AmplitudEventName.Capturar_INE_N4,
            { Captura_realizada: "No exitosa", No_de_intentos: JSON.parse(localStorage.getItem("numberAttempts") || "0") }
          )
          localStorage.setItem("condition", JSON.stringify(false));
          idScanResultCallback.cancel();
        }
      }
    };

    this.latestNetworkRequest.onerror = (): void => {
      // CASE:  Network Request itself is erroring --> You define your own API contracts with yourself and may choose to do something different here based on the error.
      localStorage.setItem("condition", JSON.stringify(false));
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_INE_N4,
        { Captura_realizada: "No exitosa", No_de_intentos: JSON.parse(localStorage.getItem("numberAttempts") || "0") }
      )
      console.log("XHR error, cancelado.");
      idScanResultCallback.cancel();
    };

    //
    // Parte 7: demuestra la actualización de la barra de progreso según el evento de progreso.
    //
    this.latestNetworkRequest.upload.onprogress = (event: ProgressEvent): void => {
      var progress = event.loaded / event.total;
      idScanResultCallback.uploadProgress(progress);
    };

    //
    // Part 8:  Actually send the request.
    //
    var jsonStringToUpload = JSON.stringify(parameters);
    this.latestNetworkRequest.send(jsonStringToUpload);
  };

  //
  // Parte 9: Esta función se llama después de que FaceTec SDK esté completamente terminado. No hay parámetros porque ya se le han pasado todos los datos en la función ProcessSessionWhileFaceTecSDKWaits y ya ha manejado todos sus propios resultados.
  //
  public onFaceTecSDKCompletelyDone = (): void => {
    //
    // DEVELOPER NOTE:  onFaceTecSDKCompletelyDone() is called after the Session has completed or you signal the FaceTec SDK with cancel().
    // Calling a custom function on the Sample App Controller is done for demonstration purposes to show you that here is where you get control back from the FaceTec SDK.
    //

    // If the Photo ID Scan was processed get the success result from isCompletelyDone
    if (this.latestIDScanResult !== null) {
      this.success = this.latestIDScanResult!.isCompletelyDone;
    }

    this.sampleAppControllerReference.onComplete(
      null,
      this.latestIDScanResult,
      this.latestNetworkRequest.status,
    );

    localStorage.setItem("condition", JSON.stringify(this.success));
    localStorage.setItem("facetecStatus", JSON.stringify(this.success));

    if (this.success) {
      const person = new XMLHttpRequest();
      person.open("GET", Config.urlGeneral + "/personal-data/person/" + Config.ProcessID, true);
      person.setRequestHeader("Content-Type", "application/json");
      person.setRequestHeader("process-id", Config.ProcessID);
      person.setRequestHeader("Authorization", "Bearer " + Config.AuthToken);
      person.setRequestHeader("X-GeoPosition", Config.GeoPosition);
      person.setRequestHeader("X-CallerIP", Config.CallerIP);
      person.send();

      person.onreadystatechange = () => {
        if (person.responseText) {
          var responseText = JSON.parse(person.responseText);
          if (responseText.addresses[0].zipCode === "") {
            localStorage.setItem("addressZipCode", JSON.stringify(false));
            window.location.pathname = "/ProofAddressCondition";
          } else {
            localStorage.setItem("addressZipCode", JSON.stringify(true));
            window.location.pathname = "/AddressCondition";
          }
        }
      };
    }
  };

  //
  // DEVELOPER NOTE:  This public convenience method is for demonstration purposes only so the Sample App can get information about what is happening in the processor.
  // In your code, you may not even want or need to do this.
  //
  public isSuccess = (): boolean => {
    if (this.endCapture) {
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_INE_N4,
        { Captura_realizada: "Exitosa", No_de_intentos: JSON.parse(localStorage.getItem("numberAttempts") || "0") }
      )
    }
    return this.success;
  };
}
