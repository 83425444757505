import IVideoCaptureService from "../../domain/services/IVideoCapture.service";
import {
  apiPostVideoCapture,
  apiPostVideoCaptureRedirect,
  getVideoRecordingWorkflows,
} from "./api/apiVideoCapture";

export default class VideoCaptureUtilService implements IVideoCaptureService {
  async videoRecording(processId: string): Promise<any> {
    return await apiPostVideoCapture(processId);
  }
  async videoRecordingRedirect(processId: string, urlRedirect: string): Promise<any> {
    return await apiPostVideoCaptureRedirect(processId, urlRedirect);
  }
  async videoRecordingWorkflows(
    processId: string,
    uuidVideoCapture: string,
    methodType: string,
  ): Promise<any> {
    return await getVideoRecordingWorkflows(processId, uuidVideoCapture, methodType);
  }
}
