/* eslint-disable jsx-a11y/anchor-is-valid */
import ILink from "../../interfaces/link";
import React from "react";

const Link = ({ text, url, className, styleLink, onClick }: ILink) => {
  return (
    <>
      <p>
        <a ref={url} className={className} style={styleLink} onClick={onClick}>
          {text}
        </a>
      </p>
    </>
  );
};

export default Link;
