import { IDataProcess, IFollowLater } from "../interfaces/api/IProcessContracts";
import IProcessContracts from "../../domain/services/IProcessContracts.service";
import {
  fetchCancelProcess,
  fetchDataProcess,
  fetchFinishProcess,
  fetchGetBiometricIneData,
  fetchSaveDataProcess,
  fetchPostCreateAccount,
  fetchGetGenerateAccount,
} from "./api/apiProcessContracts";

export default class ProcessContracts implements IProcessContracts {
  async getBiometricIne(processId: string): Promise<any> {
    return await fetchGetBiometricIneData(processId);
  }
  async getCancelProcess({ processId }: { processId: string }): Promise<any> {
    return await fetchCancelProcess(processId).then((r) => r.data);
  }
  async getDataProcess({ processId }: { processId: string }): Promise<IDataProcess> {
    return await fetchDataProcess(processId).then((r) => r.data);
  }
  async getFinishProcess({ processId }: { processId: string }): Promise<any> {
    return await fetchFinishProcess(processId).then((r) => r.data);
  }
  async saveDataProcces({
    data,
    processId,
  }: {
    data: IFollowLater;
    processId: string;
  }): Promise<IDataProcess> {
    return await fetchSaveDataProcess(data, processId).then((r) => r.data);
  }
  async postCreateAccount(processId: string): Promise<any> {
    return await fetchPostCreateAccount(processId);
  }
  async getGenerateAccount(processId: string): Promise<any> {
    return await fetchGetGenerateAccount(processId);
  }
}
