import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// Atoms
import Loader from "../../components/atoms/loader";
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import Title from "../../components/atoms/title";
import Paragraph from "../../components/atoms/paragraph";
// Templates
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
// Molecules
import TitleAndImage from "../../components/molecules/title-and-image";
import Checkbox from "../../components/molecules/checkbox";
// Utils
import { dateISO } from "../../utils/helpers";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
// Services
import OtpUtilService from "../../services/otpUtil.service";
import InformationTextUtilService from "../../services/informationTextUtil.service";
// Use-Cases
import { ValidateCodeUseCase } from "../../../application/use-cases/validateCode.usecase";
import { GeneralInformationTextUseCase } from "../../../application/use-cases/generalInformationText.usecase";
// Assets
import circleInfoSharpRegular from "../../assets/icons/circle-info-sharp-regular.svg";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
// Reudx
import { store } from "../../redux/store";
import { changeNavigate } from "../../redux/slices/navigateReducer";
import { pdfViewerData } from "../../redux/slices/pdfViewerReducer";
import { pdfsListData } from "../../redux/slices/pdfsListReducer";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-contract-signature";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
import moment from "moment";


const otpService = new OtpUtilService();
const usecaseOtp = new ValidateCodeUseCase(otpService);

const caseService = new InformationTextUtilService();
const useCase = new GeneralInformationTextUseCase(caseService);

const dataAmplitudeObject: {[key: string] : "SI" | "NO"}  = {
  Reviso_Contrato: "NO",
  Reviso_Anexo_disposiciones_legales: "NO",
  Reviso_Caratula_consucuenta_digital: "NO",
  Reviso_Caratula_inversiones_CEDES: "NO",
  Reviso_Caratula_inversiones_Pagaré:"NO",
}

const ContractCondition = () => {
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : "";
  const { onClickContinue } = useFollowLater();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = store.getState();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [checked, setChecked] = useState(false);
  const [incompleteData, setIncompleteData] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [informationList, setInformationList] = useState([]);
  const [amplitudeObject , setAmplitudeObject]  = useState({...dataAmplitudeObject});

  const continueProcess = async () => {
    setActiveSpinner(true);
    if (state.client.dataPersist.isClient) {
      const responseOtp = await usecaseOtp.sendOtp(
        {
          phone: state.client.dataPersist.phone,
          email: state.client.dataPersist.email,
          channel: "SMS",
          date: dateISO(),
        },
        state.client.clientPersist.uuid,
      );
      if (responseOtp.error === null) {
        onClickContinue({
          data: {
            dataPersist: state.client.dataPersist,
          },
          processId: state.client.clientPersist.uuid,
          resource: "ContractCondition",
          step: "CONTINUE",
        });
        dispatch(
          changeNavigate({
            progressBarPercentage: "85%",
            editableValue: state.client.dataPersist.phone,
            typeValue: "phone",
            textButton: "Continuar",
            nextContinue: "/Congratulation",
            comeFrom: "ContractCondition",
            isReturn: true,
            editableReceiverOtp: false,
          }),
        );
        if(state.client.dataPersist.isClient) {
          trackAnalyticsEvent(
            AmplitudEventName.Revisar_contrato_si_soy_cliente_N4,
            { ...amplitudeObject,
              "Fecha": moment(new Date()).format('ll'),
              "Hora": moment(new Date()).format('LTS'),
              "Geolocalizacion": access_geoposition,
            }
          )
        } else  {
          trackAnalyticsEvent(
            AmplitudEventName.Revisar_contrato_noy_soy_cliente,
            { ...amplitudeObject,
              "Fecha": moment(new Date()).format('ll'),
              "Hora": moment(new Date()).format('LTS'),
              "Geolocalizacion": access_geoposition,
            }
          )
        }
        navigate("/ValidateCode");
      } else {
        setModalShow(true);
      }
    } else {
      onClickContinue({
        data: {
          dataPersist: state.client.dataPersist,
        },
        processId: state.client.clientPersist.uuid,
        resource: "ContractCondition",
        step: "CONTINUE",
      });
      if(state.client.dataPersist.isClient) {
        trackAnalyticsEvent(
          AmplitudEventName.Revisar_contrato_si_soy_cliente_N4,
          { ...amplitudeObject,
            "Fecha": moment(new Date()).format('ll'),
            "Hora": moment(new Date()).format('LTS'),
            "Geolocalizacion": access_geoposition,
          }
        )
      } else  {
        trackAnalyticsEvent(
          AmplitudEventName.Revisar_contrato_noy_soy_cliente,
          { ...amplitudeObject,
            "Fecha": moment(new Date()).format('ll'),
            "Hora": moment(new Date()).format('LTS'),
            "Geolocalizacion": access_geoposition,
          }
        )
      }
      navigate("/CustomerSignatureAction");
    }
    setActiveSpinner(false);
  };
  const acceptAuthorize = (value: boolean) => {
    setChecked(!value);
  };

  useEffect(() => {
    if (!state.client.dataPersist.isClient) {
      setChecked(true);
    }
    if (informationList.length === 0) {
      setChecked(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationList]);

  const getDocumentsData = async () => {
    setIncompleteData(true);
    if (
      state.client.pdfsListPersist.pdfsContractCondition === undefined ||
      state.client.pdfsListPersist.pdfsContractCondition.length === undefined
    ) {
      await callDocumentsServices();
    } else if (state.client.pdfsListPersist.pdfsContractCondition.length === 0) {
      await callDocumentsServices();
    } else {
      setInformationList(state.client.pdfsListPersist.pdfsContractCondition);
    }

    setIncompleteData(false);
  };
  const callDocumentsServices = async () => {
    const response = await useCase.listContractDocs(state.client.clientPersist.uuid);
    if (response.error !== null) {
      setModalShow(true);
    } else {
      setInformationList(response.data[0].items);
      dispatch(
        pdfsListData({
          pdfIdentificationQuestionnaire: "",
          pdfsContractCondition: response.data[0].items,
        }),
      );
    }
  };
  useEffect(() => {
    getDocumentsData();
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        resource: "ContractCondition",
        step: "CONTINUE_AFTER",
        data: { dataPersist: state.client.dataPersist },
      },
    });
    dispatch(
      changeNavigate({
        progressBarPercentage: "85%",
        editableValue: state.client.dataPersist.phone,
        typeValue: "phone",
        textButton: "Continuar",
        nextContinue: "/Congratulation",
        comeFrom: "ContractCondition",
        isReturn: true,
        editableReceiverOtp: false,
      }),
    );
  };

  const showPdf = (url: string, title: string) => {
    console.log();
    switch (title) {
      case 'Contrato':
        setAmplitudeObject( { ...amplitudeObject , "Reviso_Contrato"  : "SI"})
      break;
      case 'Anexo disposiciones legales':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Anexo_disposiciones_legales"  : "SI"})
      break;
      case 'Carátula consucuenta digital':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Caratula_consucuenta_digital"  : "SI"})
      break;
      case 'Carátula inversiones - CEDES':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Caratula_inversiones_CEDES"  : "SI"})
      break;
      case 'Carátula inversiones - Pagaré':
        setAmplitudeObject( { ...dataAmplitudeObject , "Reviso_Caratula_inversiones_Pagaré"  : "SI"})
      break;
    }
    dispatch(
      pdfViewerData({
        urlPdf: url,
        comeFrom: "/ContractCondition",
        title: title,
      }),
    );
    navigate("/PdfViewer");
  };

  return (
    <>
      {incompleteData ? (
        <Loader />
      ) : (
        <>
          <div className="content-container">
            <Top
              titleHeader={listText.text_1.mainText}
              subTitle={listText.text_2.mainText}
              displayProgressBar={""}
              displayLeftImageTitle={"d-none"}
              classTitleImage={"ms-2"}
              classHeader={"mt-2"}
              styleHeader={{}}
              classProgressBar={"mt-3"}
              styleProgressBar={{}}
              classTitleAndImage={""}
              styleTitleAndImage={{}}
              valueProgressBar={"80%"}
              comeFrom={"/ContractCondition"}
              onClickGoBackIcon={undefined}
            />
            <div className="p-3">
              <Title
                text={listText.text_3.mainText}
                className={""}
                styleTitle={{
                  color: "#00254A",
                  fontWeight: 500,
                }}
              />
              <Paragraph
                text={listText.text_4.mainText}
                className={"mt-3"}
                styleParagraph={undefined}
              />
              <Paragraph
                text={listText.text_5.mainText}
                className={""}
                styleParagraph={undefined}
              />
              {informationList.map((item: any, key: any) => {
                return (
                  <p
                    key={key}
                    className={"mb-3"}
                    style={{
                      fontSize: "16px",
                      color: "#004A94",
                      textDecorationLine: "underline",
                    }}
                    onClick={() => showPdf(item.url, item.title)}
                  >
                    {item.title}
                  </p>
                );
              })}
              <Paragraph
                text={
                  state.client.dataPersist.isClient
                    ? listText.text_6.mainText
                    : listText.text_10.mainText
                }
                className={"pt-4"}
                styleParagraph={undefined}
              />
              <div className="">
                {state.client.dataPersist.isClient ? (
                  <Checkbox
                    text={listText.text_7.mainText}
                    htmlFor={"flexCheckDefault"}
                    classNameLabel={"fs-7"}
                    classCheckbox={"form-check my-4_2"}
                    checked={checked}
                    onChange={() => {
                      acceptAuthorize(checked);
                    }}
                  />
                ) : (
                  <div className={`text-center`} style={{ backgroundColor: "#F7F7F7" }}>
                    <TitleAndImage
                      srcImage={circleInfoSharpRegular}
                      titleText={listText.text_11.mainText}
                      displayLeftImageTitle={""}
                      classTitle={"m-2"}
                      styleTitle={{
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                      }}
                      onClickTitleImgeDiv={undefined}
                      divClass={"py-3"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="footer--pin">
            <Button
              text={listText.text_8.mainText}
              divClassName={"d-grid gap-2"}
              buttonClassName={`${
                !checked ? `button-disabled` : `button-enabled`
              } w-100 place-content-center align-self-center`}
              spinner={activeSpinner}
              onClick={() => {
                continueProcess();
              }}
              disabled={!checked}
              dataBsDismiss={undefined}
            />
            <div className="text-center mt-3">
              <Link
                text={listText.text_9.mainText}
                url={null}
                styleLink={{
                  color: "#5A6172",
                  lineHeight: "16px",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => onClickButtonLink()}
                className={""}
              />
            </div>
          </div>
        </>
      )}
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_24.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_25.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => window.location.reload(),
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_26.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_27.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default ContractCondition;
