const listText = {
  text_1: {
    id: "t1",
    mainText: "Apertura de cuenta",
  },
  text_2: {
    id: "t2",
    mainText: "Contrato",
  },
  text_3: {
    id: "t3",
    mainText: "¡Muy bien!",
  },
  text_4: {
    id: "t4",
    mainText: "Estas a un paso de concluir el proceso.",
  },
  text_5: {
    id: "t5",
    mainText:
      'Para continuar, revisa las condiciones del producto que vas a contratar "cuenta digital nivel de riesgo 4 (N4).',
  },
  text_6: {
    id: "t6",
    mainText:
      "Selecciona la siguiente casilla para aceptar las condiciones y te enviaremos un código de 6 dígitos al teléfono registrado, el cual funcionará como tu firma para el contrato.",
  },
  text_7: {
    id: "t7",
    mainText:
      "Acepto las condiciones presentadas y solicito el código que funcionará como mi firma.",
  },
  text_8: {
    id: "t8",
    mainText: "Firmar contrato",
  },
  text_9: {
    id: "t9",
    mainText: "Seguir más tarde",
  },
  text_10: {
    id: "t10",
    mainText: "Si estás de acuerdo con las condiciones, debes firmar desde tu dispositivo móvil.",
  },
  text_11: {
    id: "t11",
    mainText: "Recuerda que tu firma debe ser la misma que aparece en tu identificación oficial.",
  },
  text_24: {
    id: "t24",
    mainText: "¡Ups lo sentimos!",
  },
  text_25: {
    id: "t25",
    mainText: "Reintentar",
  },
  text_26: {
    id: "t26",
    mainText:
      "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
  },
  text_27: {
    id: "t27",
    mainText: "Vuelve a intentar si el error persiste.",
  },
};

export default listText;
