import fetchBxiApiGateway from "./fetchBxiApiGateway";

const generateOtp = (request: any, processId: string) => {
  return fetchBxiApiGateway({
    url: "utils-commons/otp/generate",
    method: "POST",
    data: {
      ...request,
    },
    headers: {
      "process-id": processId,
    },
  });
};

const validateOtp = (request: any, processId: string) => {
  return fetchBxiApiGateway({
    url: "utils-commons/otp/validate",
    method: "POST",
    data: {
      ...request,
    },
    headers: {
      "process-id": processId,
    },
  });
};

export { generateOtp, validateOtp };
