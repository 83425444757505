//import { IAuthentication} from "../../interfaces/api/IBeneficiaries.interface";
import { APIKEY } from "../../../env";
import fetchBxiApiGateway from "./fetchBxiApiGateway";

export const fetchAuthentication = () => {
  return fetchBxiApiGateway({
    url: `/auth/oauth/token`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${APIKEY}`,
      "X-GeoPosition": "MX",
      "X-CallerIP": "1.0.2.3",
    },
    data: {
      grant_type: "client_credentials",
      scope: "read write",
    },
  });
};

export const fetchProcessesValidate = (token: string, BearerToken: string) => {
  return fetchBxiApiGateway({
    url: `/processes/validate?token=${token}`,
    method: "GET",
    headers: {
      "X-GeoPosition": "MX",
      "X-CallerIP": "1.0.2.3",
      Authorization: `Bearer ${BearerToken}`,
    },
  });
};

export const fetchProcessesCurrentProcessId = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/processes/current/process-id/${processId}`,
    method: "GET",
  });
};
