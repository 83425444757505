import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Atoms
import Image from "../../components/atoms/image";
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
import Loader from "../../components/atoms/loader";
import Link from "../../components/atoms/link";
// Templates
import Top from "../../components/templates/top";
// Facetec
import { SampleApp } from "../../facetec/sample-app/sampleAppController";
// Hooks
import { useFollowLater } from "../../hooks/useFollowLater";
import { useRedux } from "../../hooks/useRedux";
// Redux
import { store } from "../../redux/store";
// Assets
import redCircleExclamationSharpSolid from "../../assets/icons/red-circle-exclamation-sharp-solid.svg";
import ic_light_blue_phone from "../../assets//icons/ic_light_blue_phone.svg";
import ic_phone_white from "../../assets/icons/ic_phone_white.svg";
import ic_selfie_blue from "../../assets/icons/ic_selfie_blue.svg";
import ic_face_retouching_natural from "../../assets/icons/ic_face_retouching_natural.svg";
import ic_no_sunglasses_blue from "../../assets/icons/ic_no_sunglasses_blue.svg";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-liveness-check";
import listTextBiometric from "../../utils/dictionaries/dictionary-biometric-validation";



const LivenessCheck = () => {
  const { onClickContinue } = useFollowLater();
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const state = store.getState();
  const navigate = useNavigate();
  const [client, setClient] = useState(false);
  const [, setSec] = useState<number>(0);
  const [toggle] = useState<boolean>(false);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const [spinner, setSpinner] = useState(false);
  const [biometricValidation, setBiometricValidation] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(true);
  const numberAttempts = localStorage.getItem("numberAttempts_livenessCheck") ? JSON.parse(localStorage.getItem("numberAttempts_livenessCheck") || "0") : 0;

  useEffect(() => {
    setClient(state.client.dataPersist.isClient);
    localStorage.removeItem("livenessCheckStatus");
    localStorage.removeItem("conditionLiveness");
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref.current = setInterval(() => {
      if (toggle) setSec((state) => state + 1);
      setBiometricValidation(localStorage.getItem("biometricValidation"));
      if (
        localStorage.getItem("conditionLiveness") === "false" ||
        localStorage.getItem("conditionLiveness") === null
      ) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }

      if (localStorage.getItem("livenessCheckStatus") === "true") {
        setShowLoader(true);
      }
    });

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  }, [toggle]);

  const next = () => {
    SampleApp.onEnrollUserPressed();
    setTimeout(() => {
      localStorage.setItem("conditionLiveness", JSON.stringify(true));
      setShowLoader(false);
    }, 1000);
    onClickContinue({
      data: { dataPersist: { ...state.client.dataPersist } },
      processId: uuid,
      resource: "LivenessCheck",
      step: "CONTINUE",
    });
    localStorage.setItem("numberAttempts_livenessCheck", JSON.stringify(numberAttempts +  1));
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "LivenessCheck",
        processId: uuid,
        step: "CONTINUE_AFTER",
        data: { dataPersist: { ...state.client.dataPersist } },
      },
    });
  };

  const end = () => {
    window.open(`tel:8008003224`, "_blank");
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={""}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"80%"}
          comeFrom={"/LivenessCheck"}
          onClickGoBackIcon={() => navigate("/")}
        />
        <div className="p-3">
          <div className="text-center mb-4_3 mt-4">
            <Image
              srcImage={ic_selfie_blue}
              className={"ic_selfie_blue"}
              alt={""}
              dataBsDismiss={""}
              styleImg={undefined}
              onClick={undefined}
            ></Image>
          </div>
          {biometricValidation !== "OK" && biometricValidation !== null ? (
            <>
              <div
                style={{ textAlign: "left", backgroundColor: "#F7F7F7" }}
                className="my-4 pt-3 rounded-2 w-100 pe-none fw-normal d-flex"
              >
                <Image
                  srcImage={redCircleExclamationSharpSolid}
                  className="ms-2 mt-2 h-100"
                  alt={""}
                  dataBsDismiss={undefined}
                  styleImg={{ cursor: "auto" }}
                  onClick={undefined}
                />
                <Paragraph
                  text={
                    biometricValidation === "FAIL"
                      ? listTextBiometric.text_1.mainText
                      : biometricValidation === "MAX_ATTEMPTS_BIOMETRIC_VALIDATION"
                      ? listTextBiometric.text_2.mainText
                      : listTextBiometric.text_3.mainText
                  }
                  className="m-2"
                  styleParagraph={{
                    color: "#363A44",
                    lineHeight: "24px",
                    textDecoration: "none",
                    cursor: "auto",
                    fontSize: "16px",
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-12 mb-4_9">
                <Paragraph
                  text={listText.text_3.mainText}
                  className={"fs-7 mb-0 lh-base dark-blue-title"}
                  styleParagraph={undefined}
                />
              </div>
              <div className="d-flex align-items-center pb-3">
                <div className="col-1">
                  <Image
                    srcImage={ic_face_retouching_natural}
                    className={"h-32 w-32"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={undefined}
                    onClick={undefined}
                  ></Image>
                </div>
                <div className="col-11 mt-2">
                  <Paragraph
                    text={listText.text_4.mainText}
                    className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                    styleParagraph={undefined}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-4_3">
                <div className="col-1">
                  <Image
                    srcImage={ic_no_sunglasses_blue}
                    className={"h-32 w-32"}
                    alt={""}
                    dataBsDismiss={""}
                    styleImg={undefined}
                    onClick={undefined}
                  ></Image>
                </div>
                <div className="col-11 mt-2">
                  <Paragraph
                    text={listText.text_5.mainText}
                    className={"fs-7 mb-0 lh-base ms-3 dark-blue-title"}
                    styleParagraph={undefined}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="footer--pin">
        <div className="wrapping-box-container">
          {biometricValidation !== "OK" && biometricValidation !== null ? (
            <>
              <Button
                text="Llamar a un Ejecutivo"
                img={ic_light_blue_phone}
                classNameImg=""
                divClassName=""
                buttonClassName={`mb-3 toggle-btn-dark-blue bg-white btn-outline-btn-dark-blue 
                  w-100 fw-semibold place-content-center align-self-center align-items-center 
                  ${biometricValidation === "MAX_ATTEMPTS_BIOMETRIC_VALIDATION" ? "d-none" : ""}`}
                spinner={false}
                onClick={() => end()}
                disabled={false}
              />
              <Button
                text={
                  biometricValidation !== "MAX_ATTEMPTS_BIOMETRIC_VALIDATION"
                    ? "Reintentar"
                    : "Llamar a un Ejecutivo"
                }
                img={
                  biometricValidation === "MAX_ATTEMPTS_BIOMETRIC_VALIDATION" ? ic_phone_white : ""
                }
                divClassName=""
                buttonClassName="btn-bg-primary text-white w-100 place-content-center align-self-center"
                spinner={false}
                onClick={
                  biometricValidation !== "MAX_ATTEMPTS_BIOMETRIC_VALIDATION"
                    ? () => next()
                    : () => end()
                }
                disabled={spinner}
                dataBsDismiss={""}
              ></Button>
            </>
          ) : (
            <div id="main-interface">
              <div id="controls">
                <div id="vocal-icon-container" className="ft-button"></div>
                <Button
                  id={"liveness-button"}
                  text={listText.text_6.mainText}
                  divClassName={""}
                  buttonClassName={
                    "btn-bg-primary text-white w-100 place-content-center align-self-center"
                  }
                  spinner={spinner}
                  onClick={() => next()}
                  disabled={spinner}
                  dataBsDismiss={""}
                ></Button>
              </div>
              <div className="d-none" id="custom-logo-container" aria-hidden="true">
                <img id="facetec-logo" src="" alt="" />
              </div>
            </div>
          )}
        </div>

        <div className="text-center mt-3">
          <Link
            text={listText.text_7.mainText}
            url={null}
            styleLink={{
              color: "#5A6172",
              lineHeight: "16px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => followLaterAction()}
            className={""}
          />
        </div>
      </div>
    </>
  );
};

export default LivenessCheck;
