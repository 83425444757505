import IOTPService from "../../domain/services/IOtp.service";

export class ValidateCodeUseCase {
  constructor(private otpSrv: IOTPService) {}
  public async sendOtp(request: any, processId: string) {
    try {
      const response = await this.otpSrv.generate(request, processId);
      if (// eslint-disable-next-line eqeqeq
response.status != 200)
        return { data: "", error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error al generar el OTP");
      return { data: "", error: `Ocurrio un error ${error}` };
    }
  }
  public async validateOtp(request: any, processId: string) {
    try {
      const response = await this.otpSrv.validate(request, processId);
      if (!response.data.valid)
        return { data: response.data, error: `Ocurrio un error, status: ${response.status}` };

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error al generar el OTP");
      return { data: null, error: `Ocurrio un error ${error}` };
    }
  }
}
