import IUtilsCommons from "../../domain/services/IUtilsContracts.service";
import { fetchMaxMinByDeposit } from "./api/apiUtilsContracts";

export default class UtilsContracts implements IUtilsCommons {
  async getMaxMinByDeposit({ processId }: { processId: string }): Promise<{
    amountMin: number;
    amountMax: number;
  }> {
    return await fetchMaxMinByDeposit(processId).then((r) => r.data);
  }
}
