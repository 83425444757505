import ICheckbox from "../../interfaces/checkbox";
import { Label } from "../atoms/label";
import * as React from "react";

const Checkbox = ({
  text,
  htmlFor,
  classNameLabel,
  classCheckbox,
  checked,
  onChange,
  disabled
}: ICheckbox) => {
  return (
    <div className={classCheckbox}>
      <input
        className="form-check-input"
        type="checkbox"
        id={htmlFor}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
/>
      <Label text={text} htmlFor={htmlFor} className={classNameLabel} />
    </div>
  );
};

export default Checkbox;
