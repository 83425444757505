import { useEffect } from "react";
import { IZipCode } from "../interfaces/api/IUtilsCommons";
import { useApi } from "./useApi";
import { useRedux } from "./useRedux";
import UtilsCommonsService from "../services/utilsCommons.service";

const { getZipCode } = new UtilsCommonsService();

export function useZipCode({ postalCode }: { postalCode: number | string }) {
  const {
    data: {
      clientPersist: { uuid },
    },
  } = useRedux();
  const {
    data: dataGetZipCode = [],
    error: errorZipCode,
    execute: executeGetZipCode,
    isLoading: isLoadingGetZipCode,
  } = useApi<IZipCode[], { zipCode: number | string; processId: string }>(getZipCode);

  useEffect(() => {
    if (postalCode.toString().length === 5) {
      executeGetZipCode({ zipCode: postalCode, processId: uuid });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  return {
    dataGetZipCode,
    isLoadingGetZipCode,
    errorZipCode,
  };
}
