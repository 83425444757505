import Image from "../../../components/atoms/image";
import Sheet from "react-modal-sheet";
import ic_xclose from "../../../../infraestructure/assets/icons/ic_xclose.svg";
import { TelmexIcon } from "./icons/telmex-Icon";
import { CfeIcon } from "./icons/cfe-Icon";
import { IContextMenuSelection } from "../../../utils/sdk-identy/sdk-config.interface";
import "./style.scss";

interface ISelectTypeFile {
  isOpen: boolean;
  onClickClose: () => void;
  onSelectTypeFile: (selectType: IContextMenuSelection, isSelectFile: boolean) => void;
  isSelectFile: boolean;
}

const CFE: IContextMenuSelection = {
  card_type: {
    name: "A4_POA_CFE",
    full_name: "POA_CFE",
    country: "UNIVERSAL",
    visible: true,
    isA4: true,
    process: true,
    capture_back: false,
    sub_ui: 0,
  },
  enableAS: false,
  useFlash: true,
  uiSelect: "BOXES",
  showCaptureTraining: true,
  displayMode: "LANDSCAPE",
  fileSelection: true,
  integrityCheck: false,
};

const TELMEX: IContextMenuSelection = {
  card_type: {
    name: "A4_POA_TELMEX",
    full_name: "POA_TELMEX",
    country: "UNIVERSAL",
    visible: true,
    isA4: true,
    process: true,
    capture_back: false,
    sub_ui: 0,
  },
  enableAS: false,
  useFlash: true,
  uiSelect: "BOXES",
  showCaptureTraining: true,
  displayMode: "LANDSCAPE",
  fileSelection: true,
  integrityCheck: false,
};
const SelectTypeFile = ({
  isOpen,
  onClickClose,
  onSelectTypeFile,
  isSelectFile,
}: ISelectTypeFile) => {
  return (
    <>
      <Sheet isOpen={isOpen} onClose={onClickClose} detent="content-height">
        <Sheet.Container>
          <Sheet.Content>
            <Sheet.Scroller>
              <div className="p-3">
                <div className="row">
                  <div className="col-10">
                    <strong className="fw-bold dark-blue-title fs-8">
                      Indica el tipo de comprobante de domicilio que vas a capturar
                    </strong>
                  </div>
                  <div className="col-2 text-end">
                    <Image
                      srcImage={ic_xclose}
                      className={""}
                      alt={""}
                      dataBsDismiss={""}
                      styleImg={{ cursor: "pointer" }}
                      onClick={onClickClose}
                    />
                  </div>
                </div>
                <div className="select-type-file__container">
                  <div
                    className="select-type-file__container--bootom"
                    onClick={() => {
                      onSelectTypeFile(TELMEX, isSelectFile);
                      onClickClose();
                    }}
                  >
                    <TelmexIcon />
                  </div>
                  <div
                    className="select-type-file__container--bootom"
                    onClick={() => {
                      onSelectTypeFile(CFE, isSelectFile);
                      onClickClose();
                    }}
                  >
                    <CfeIcon />
                  </div>
                </div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClickClose} />
      </Sheet>
    </>
  );
};

export default SelectTypeFile;
