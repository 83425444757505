import { useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import Title from "../atoms/title";
import Image from "../atoms/image";
import circle_check_sharp_solid from "../../assets/icons/circle-check-sharp-solid.svg";
import hexagon_exclamation_thin from "../../assets/icons/hexagon-exclamation-thin.svg";
import Button from "../atoms/button";

const MessagesView = (props: any) => {
  const navigate = useNavigate();
  const state = store.getState();

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        position: "relative",
        backgroundColor: "#00376F",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "40%",
          margin: "auto",
          color: "#FFFFFF",
        }}
      >
        <Image
          srcImage={props.status ? circle_check_sharp_solid : hexagon_exclamation_thin}
          className={"me-4"}
          alt={"ic_csb"}
          dataBsDismiss={undefined}
          styleImg={{}}
          onClick={undefined}
        />
        <div className="mt-4">
          <Title
            text={props.message}
            className={"fw-bold"}
            styleTitle={{ color: "#FFFFFF", fontSize: 24, fontWeight: 400 }}
          />
        </div>
        <div className="mx-3 mt-5">
          <Button
            id={""}
            text={props.textBotton}
            divClassName={""}
            buttonClassName={
              "btn-bg-primary text-white w-100 place-content-center align-self-center"
            }
            spinner={false}
            onClick={() => navigate(props.continue)}
            disabled={false}
            dataBsDismiss={""}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesView;
