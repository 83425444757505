let styles = {
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: 'var(--dark-blue-title)',
    lineHeight: '24px'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px'
  },
  groupTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: 'var(--bg-dark-blue)',
    lineHeight: '24px'
  },
}
export default styles;